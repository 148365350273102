import React from 'react';
import { Dialog, DialogContent, Button, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SignupForm from '~/components/Signup/SignupForm';
import { commonButtonsSx } from '../BottomSheetp2p';
import { useStyles } from './styles';
import { store } from '~/redux/store';
import LoginForm from '~/components/Login/LoginForm';

interface SignupDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
}

const SignupDialog: React.FC<SignupDialogProps> = ({
  open,
  onClose,
  title,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isAuthenticated = () => {
    return (
      !!store.getState().MapReducers.userID &&
      !!store.getState().MapReducers.userEmail
    );
  };

  const [signupModalOpen, setSignUpModalOpen] = React.useState(false);
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  const handleSignUpModal = () => {
    if (!isAuthenticated()) {
      setSignUpModalOpen((prev) => !prev);
    } else {
      onClose();
      setSignUpModalOpen((prev) => !prev);
    }
  };

  const handleSignInClick = () => {
    if (!isAuthenticated()) {
      setLoginModalOpen((prev) => !prev);
    } else {
      onClose();
      setLoginModalOpen((prev) => !prev);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          margin: 0,
          position: 'fixed',
          bottom: 0,
          width: '100%',
          borderRadius: '20px 20px 0 0',
          backgroundColor: '#fff',
        },
      }}
    >
      <SignupForm open={signupModalOpen} onClose={handleSignUpModal} />

      <LoginForm open={loginModalOpen} onClose={handleSignInClick} />

      <DialogContent
        sx={{
          borderRadius: '16px 16px 0 0',
          padding: theme.spacing(3),
          background: '#FFFFFF',
          width: '100%',
          textAlign: 'center',
          mb: '2rem',
        }}
      >
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontWeight: 700,
            fontSize: '24px',
            color: '#000000',
            lineHeight: '36px',
          }}
        >
          {title}
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSignUpModal}
          sx={{
            ...commonButtonsSx,
            margin: 0,
            width: '100%',
            padding: '4px 8px',
          }}
        >
          Sign up for Free
        </Button>

        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
          <Typography className={classes.login} display="inline">
            Already a member?
          </Typography>
          <Button
            className={classes.loginButton}
            variant="text"
            color="primary"
            onClick={handleSignInClick}
            sx={{ minWidth: 'auto', paddingLeft: 1, paddingRight: 1 }}
          >
            <span className={classes.loginButtonText}>Log in</span>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SignupDialog;
