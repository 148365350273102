import { ArrowBack, ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Modal, TextField } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import {
  bottomSheetp2pStateSignal,
  commonButtonsSx,
  resetAutoYesProgress,
} from './BottomSheetp2p';
import { dayCounterSignal } from '~/components/Itinerary';
import { previewMapSignal } from '.';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import './GlobalCssDrawer.css'
import { textFieldSx } from '../WhereAndWhen';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { LocalizationProvider, MultiSectionDigitalClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
const commonSx = {
  boxShadow:
    '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
  borderRadius: '18px',
  backgroundColor: 'white',
  color: '#FE7138',
  padding: 'min(8px,2vh) min(16px,4vw)',
  fontSize: '16px',
  fontWeight: 700,
};

const variantA = {
  visible: { opacity: 1, x: 0, height: 'auto', scaleY: 1 },
  hidden: { opacity: 0, x: -100, height: '0px', scaleY: 0 },
};

const variantB = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: 100 },
};

const noReasonsList = [
  {
    id: 1,
    text: 'not interested 🥱',
    reason: "This place is not a good recommendation. Find me another place that, without a question, you think I would love."
  },
  {
    id: 2,
    text: 'too expensive 🤑',
    reason: "This place is too expensive. Find me a place that is more affordable."
  },
  {
    id: 3,
    text: 'too far ↔️',
    reason: "This place is too far. Find me a place that is closer."
  },
  {
    id: 4,
    text: 'bring me to restaurant 🍽️',
    reason: "I want to go eat somewhere. Recommend me a Restaurant right now. Preferably walking distance. Try to keep it in less than 5 minute walking distance if possible."
  },
  {
    id: 5,
    text: 'find an activity 📍',
    reason: "I want to do an activity. Recommend an Activity right now. Preferably walking distance but if it's really a good one, I don't mind going a bit further as long as it makes sense."
  },
  {
    id: 6,
    text: 'find a hidden gem 💎',
    reason: "This place is already in my itinerary, make sure you don't recommend places that are already in my previous days itinerary. Give me a new place you haven't recommended yet."
  },
  {
    id: 7,
    text: 'go to hotel 🏨',
    reason: "I need to go back to my hotel to get something. Please make the arrival point for this transportation index to be my hotel."
  },
  {
    id: 8,
    text: 'already in my itinerary 📅',
    reason: "This place is already in my itinerary. Make sure you don't recommend places that are already in my previous days itinerary. Give me a new place you haven't recommended yet."
  },
]

const NoResponseItem = ({ text, reason, handleClose }: { text: string, reason: string, handleClose: (reason: string) => void }) => {
  return <div
    style={{ ...commonSx, marginBottom: 'min(16px,2vh)', textAlign: 'center', width: 'fit-content', cursor: 'pointer', marginRight: 'min(8px,1vw)' }}
    onClick={() => {
      handleClose(reason);
    }}
  >
    {text}
  </div>
}

const NoResponseP2P = ({
  handleClose,
}: {
  handleClose: (reason: string) => void;
}) => {
  const [state, setState] = useState<'initial' | 'other'>('initial');
  const [customReason, setCustomReason] = useState(''); // State for custom reason
  const isMobile = useIsMobile();
  const FixedPromptButtonsAnimation = useAnimation();
  return (
    <div
      onClick={(e) => { e.stopPropagation() }}
      style={{
        background: 'linear-gradient(180deg, #EEF3F7 0%, #FFFFFF 100%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 'min(16px,4vw)',
        borderRadius: '18px',
        boxShadow:
          '0px 1.3px 2.21px 0px #07081705 , 0px 3.13px 5.32px 0px #07081707 , 0px 5.89px 10.02px 0px #07081709 , 0px 10.5px 17.87px 0px #0708170B , 0px 19.64px 33.42px 0px #0708170D , 0px 47px 80px 0px #07081712',
        width: isMobile ? '85%' : '40%',
        margin: '0 auto',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
      }}
    >
      {state == 'initial' ? (
        <Fragment>
          <SpecificPointInputOptions handleClose={handleClose} FixedPromptButtonsAnimation={FixedPromptButtonsAnimation} />
          <motion.h3
            variants={variantA}
            initial="hidden"
            animate={FixedPromptButtonsAnimation}
            exit="hidden"

            style={{ textAlign: 'center', marginBottom: 'min(16px,2vh)', marginTop: 'min(16px,2vh)' }}
          >
          </motion.h3>

          <motion.div
            variants={variantA}
            initial="hidden"
            animate={FixedPromptButtonsAnimation}
            exit="hidden"
            className="d-flex flex-column align-items-center "

          >

            {noReasonsList.map((item) => (
              <NoResponseItem text={item.text} key={item.id} reason={item.reason} handleClose={handleClose} />
            ))}

            <div
              style={{ ...commonSx, marginBottom: 'min(16px,2vh)', textAlign: 'center', width: 'fit-content', cursor: 'pointer' }}
              onClick={() => {
                setState('other');
              }}
            >
              Other
            </div>
          </motion.div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="d-flex justify-content-between align-items-center w-100 pe-5">
            <Button
              variant="text"
              startIcon={<ArrowBack />}
              onClick={() => setState('initial')}
            ></Button>
            <div className="flex-1"></div>
            <h3 className="mx-2 my-3">
              <b>Other</b>
            </h3>
            <div className="flex-1"></div>
          </div>
          <TextField
            // 5 lines
            multiline
            rows={5}
            fullWidth
            variant="outlined"
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
            placeholder="e.g. I rather see a Museum"
            inputProps={{
              style: {
                textAlign: 'center',
                verticalAlign: 'middle',
                fontWeight: 700,
                fontSize: '16px',
              },
            }}
            style={{
              borderRadius: '18px',
              backgroundColor: 'white',
              marginBottom: '16px',
            }}
          />

          <Button
            sx={{ ...commonSx, color: 'white', backgroundColor: '#FE7138' }}
            onClick={() => {
              if (customReason.trim()) {
                setState('initial');
                setCustomReason('');

                handleClose(customReason);
              } else {
                alert('Please enter a reason');
              }
            }}
          >
            Regenerate
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default NoResponseP2P;


const SpecificPointInputOptions = ({ handleClose, FixedPromptButtonsAnimation }: { handleClose: (reason: string) => void, FixedPromptButtonsAnimation: any }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [specificTimeValue, setSpecificTimeValue] = useState('');
  const [isTimeEditing, setIsTimeEditing] = useState(false);
  const SpecificPointOptionsAnimation = useAnimation();
  useEffect(() => {
    if (inputValue === '') {
      FixedPromptButtonsAnimation.start('visible');
      SpecificPointOptionsAnimation.start('hidden');
    }
  }, [inputValue])
  return (
    <div className="d-flex flex-column text-center">

      <motion.h3
        variants={variantA}
        initial="hidden"
        animate={SpecificPointOptionsAnimation}
        exit="hidden"

        style={{ fontSize: 'min(20px,7vw)', marginBottom: 'min(16px,2vh)', marginTop: 'min(16px,2vh)' }}
      >
        <span className='p-2'
          onClick={() => { setInputValue(''); setIsEditing(false); }}
        ><ArrowBackIos /></span>
        <b>Go This Place</b></motion.h3>
      <div className="d-flex align-items-center mb-3">
        <TextField
          value={inputValue}
          placeholder='Enter a specific point'
          onChange={(e) => setInputValue(e.target.value)}
          sx={textFieldSx}
          style={{ margin: '0 auto' }}
          disabled={(inputValue !== '' && isEditing)}
        />
        {!isEditing ? (
          <Fragment>
            {inputValue !== '' && <Button
              sx={commonButtonsSx}
              onClick={() => {
                FixedPromptButtonsAnimation.start('hidden');
                SpecificPointOptionsAnimation.start('visible');
                setIsEditing(true);
                // handleClose(inputValue);
              }}
              style={{
                marginLeft: '8px',
                marginTop: '0px',
              }}
            >
              Done
            </Button>}
          </Fragment>
        ) : (
          <Fragment>
            {inputValue !== '' && <Button
              sx={{ ...commonButtonsSx }}
              style={{ background: 'white', color: '#FE7138' }}
              onClick={() => setIsEditing(false)}
            >
              Edit
            </Button>}
          </Fragment>
        )}
      </div>

      <motion.div
        variants={variantA}
        initial="hidden"
        animate={SpecificPointOptionsAnimation}
        exit="hidden"
        className="my-2"

      >

        <div>
          <h3
            style={{ fontSize: 'min(20px,7vw)', marginBottom: 'min(16px,2vh)', marginTop: 'min(16px,2vh)' }}
          ><b>At this Time</b></h3>
        </div>
        <div className="d-flex align-items-center mb-3">
          <TextField
            value={specificTimeValue}
            placeholder='No specific time'
            onChange={(e) => setSpecificTimeValue(e.target.value)}
            sx={textFieldSx}
            onClick={() => setIsTimeEditing(true)}
            style={{ marginRight: '8px' }}
            disabled={true}
          />
          {
            isTimeEditing ? (
              <Fragment>
                {
                  <Modal

                    open={isTimeEditing}
                    onClose={() => setIsTimeEditing(false)}

                  >
                    <div className='d-flex flex-column justify-content-center align-items-center p-2'
                      style={{
                        background: 'white',
                        borderRadius: '18px',
                        width: 'min(90vw, 400px)',
                        top: '50%',
                        left: '50%',
                        position: 'fixed',
                        transformOrigin: 'center',
                        transform: 'translate(-50%,-50%)',
                      }}
                    >

                      <h4>
                        <b>Arrival Time at place</b>
                      </h4>

                      <div className='flex-1' />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>

                        <MultiSectionDigitalClock
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '16px',
                            background: 'whitesmoke',
                            borderRadius: '18px'
                          }}
                          onChange={(value) => setSpecificTimeValue(dayjs(value).format('hh:mm A'))}

                        />
                      </LocalizationProvider>
                      <div className='flex-1' />

                      <div className='d-flex '>

                        <Button
                          className='me-3'
                          sx={commonButtonsSx}
                          onClick={() => setIsTimeEditing(false)}
                        >
                          Done
                        </Button>
                        <Button
                          sx={commonButtonsSx}
                          style={{ background: 'white', color: '#FE7138' }}
                          onClick={() => { setIsTimeEditing(false); setSpecificTimeValue('') }}
                        >
                          Cancel
                        </Button>
                      </div>


                    </div>



                  </Modal>
                }
              </Fragment>
            ) : (
              <Fragment>
                <Button
                  sx={commonButtonsSx}
                  style={{ background: 'white', color: '#FE7138' }}
                  onClick={() => setIsTimeEditing(true)}
                >
                  Edit
                </Button>
              </Fragment>
            )
          }
        </div>

        <Button
          sx={commonButtonsSx}
          onClick={() => {
            handleClose(`
              I want to go to ${inputValue} right away and 
              arrive there at this time ${specificTimeValue || '[The time of last point]'
              } instead of the point you gave me. 
              Make triple sure that you bring me to ${inputValue}
              at the exact time I gave you
              `);
            setIsEditing(false);
            setSpecificTimeValue('');
            setInputValue('');
            FixedPromptButtonsAnimation.start('hidden');
            SpecificPointOptionsAnimation.start('visible');
          }}
        >
          Regenerate
        </Button>

      </motion.div>

    </div>
  )
}