import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  animationIsPlaying,
  showMainHeader,
  showPlayPauseButton,
  clickedsetVizualizeTripButtonState,
} from '../../common';
import { getStartedBlinkSignal } from '~/components/signals/blinkSignals';
import { isMapLoadedSignal } from '~/map/helpers';
import ActionsCreator from '~/redux/actions';
import {
  VizualizeButtonData,
  vizualizeButtonSignal,
} from '../DaysHeader/DaysHeader';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { motion } from 'framer-motion';
import { isOverlayVisible } from '../../StatsOverlay';
import { shouldShowMakeOwnPopup } from '~/map/ViewTravel/MakeYourOwnPopup';

const MobileVizualizeButton = ({
  setStep,
  startTrip,
}: {
  setStep: (v: string) => void;
  startTrip: (v: number) => void;
}) => {
  const [selectedPlayData, setSelectedPlayData] =
    useState<VizualizeButtonData>();
  const [vizualizeTripButtonState, setVizualizeTripButtonState] =
    useState<boolean>(false);
  const dispatch = useDispatch();

  useSignalEffect(() => {
    setSelectedPlayData(vizualizeButtonSignal.value);
  });

  function handleVizualizeTripButtonClick() {
    setVizualizeTripButtonState(!vizualizeTripButtonState);
    clickedsetVizualizeTripButtonState.value = !vizualizeTripButtonState;
    dispatch(
      ActionsCreator.setVizualizeTripButtonState(
        clickedsetVizualizeTripButtonState.value,
      ),
    );
  }

  const getStarted = () => {
    if (!isMapLoadedSignal.value) return;
    handleVizualizeTripButtonClick();

    // added for blinking state
    getStartedBlinkSignal.value = 2;
    showPlayPauseButton.value = true;
    // play(!isPlaying);
    // fadingUp.play(!fadingUp.isPlaying);
    animationIsPlaying.value = true;
    // dispatch(ActionsCreator.setPlayPauseState(false));
    showMainHeader.value = false;

    setTimeout(() => {
      setStep('load');
      if (selectedPlayData?.startIndex) {
        dispatch(ActionsCreator.setPlayPauseState(false));
        setTimeout(() => startTrip?.(selectedPlayData?.startIndex), 0);
      } else {
        dispatch(ActionsCreator.setPlayPauseState(false));
      }
      if (isOverlayVisible.peek()) isOverlayVisible.value = false;
    }, 300);
  };
  return (
    <motion.div
      className="bottom-sheet-button"
      animate={{
        scale: 1.05,
      }}
      exit={{ scale: 1 }}
      initial={{ scale: 1 }}
      transition={{
        repeat: Infinity,
        repeatType: 'reverse',
        duration: 1,
        ease: 'easeInOut',
      }}
      onClick={getStarted}
    >
      {selectedPlayData === undefined
        ? 'Vizualize Whole Trip'
        : `Vizualize Day ${selectedPlayData.day}`}
    </motion.div>
  );
};

export default MobileVizualizeButton;
