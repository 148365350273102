// NotFound.tsx
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const handleReturnHome = () => {
        navigate('/home');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Typography variant="h1" component="h1">
                404
            </Typography>
            <Typography variant="h6" component="h6">
                Page Not Found
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleReturnHome}
                sx={{ mt: 2 }}
            >
                Return to Home
            </Button>
        </Box>
    );
};

export default NotFound;