import { URLConfig } from '~/utility/models';
import maplibregl from 'maplibre-gl';
import map3 from './map3';
import map4 from './map4';


const mapStyles = [
  map3,
  map4,
  {
    isURL: true,
    URL: 'https://map.vizualtravel.com/styles/positron-gl-style/style.json',
  },
  {
    isURL: true,
    URL: ' https://map.vizualtravel.com/styles/dark-matter-gl-style/style.json',
  },
  {
    version: 8,
    sources: {
      osm: {
        type: 'raster',
        tiles: [
          'https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
        ],
        tileSize: 256,
        attribution: '&copy; OpenStreetMap Contributors',
        maxzoom: 20,
      },
    },
    layers: [
      {
        id: 'osm',
        type: 'raster',
        source: 'osm',
      },
    ],
  },
  {
    version: 8,
    sources: {
      osm: {
        type: 'raster',
        tiles: [
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        ],
        tileSize: 256,
        attribution: '&copy; OpenStreetMap Contributors',
        maxzoom: 19,
      },
    },
    layers: [
      {
        id: 'osm',
        type: 'raster',
        source: 'osm',
      },
    ],
  }
] as (maplibregl.StyleSpecification | URLConfig)[];
export default mapStyles;
