import { DiscoverButtons } from '../DiscoverMenu';
import { TripPlans } from './TripPlans/TripPlans';
import { CustomContent } from './CustomContent/CustomContent';
import './GridContent.css';

export const GridContent = ({
  activeSection,
  dataToShow,
  handleShowStories,
}: {
  activeSection: DiscoverButtons;
  dataToShow: any;
  handleShowStories: (storyIdx: number) => void;
}) => {
  if (activeSection === 'trips') {
    return <TripPlans />;
  } else {
    const data = dataToShow[activeSection];
    return <CustomContent data={data} showStory={handleShowStories} />;
  }
};
