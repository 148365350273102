import React, { Fragment } from 'react';
import { showDrawerMenu } from '../DrawerMenu';
import { useNavigate } from 'react-router-dom';
import { showOverviewMenu } from '../OverviewMenu';
import { handleShare } from '~/utility/shareLink';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';

import './style.css';
import { isShareModalVisible } from '../DiscoverMenu/DiscoverMenu';

const Navbar = ({
  onBackButtonClick,
  style,
  onlyBurger = false,
  shoudShowMap = false,
  shouldShowDiscovery = false,
}: {
  onBackButtonClick?: () => void;
  style?: React.CSSProperties;
  onlyBurger?: boolean;
  shoudShowMap?: boolean;
  shouldShowDiscovery?: boolean;
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: onlyBurger ? '0px' : '12px 20px',
        height: onlyBurger ? 'auto' : 80,
        background: onlyBurger ? 'transparent' : '#ffffff',
        maxWidth: 450,
        width: onlyBurger ? 'auto' : '100%',
        alignSelf: 'center',
        ...style,
      }}
    >
      {onBackButtonClick ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onBackButtonClick?.();
          }}
          style={{
            pointerEvents: 'all',
            width: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: shouldShowDiscovery ? 'center' : 'flex-start',
          }}
        >
          <div
            className={`mobile-header-go-back-btn ${
              shouldShowDiscovery && 'home'
            }`}
          >
            <img
              src={
                shouldShowDiscovery ? './icons/homeLogo.png' : './goBackNew.png'
              }
              alt="Back Button"
              // width={40}
              // height={33}
            />
          </div>
        </div>
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
            showDrawerMenu.value = true;
          }}
          style={{
            pointerEvents: 'all',
            width: '80px',
          }}
        >
          <div
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px',
              borderRadius: '12px', // Make it more rounded for a smoother edge
              padding: '14px', // Adjust to give some spacing inside the cube
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '64px', // Ensures it's a square
              height: '64px', // Same height as width for a cube-like shape
              backgroundColor: '#fff', // White background to match
              cursor: 'pointer',
            }}
          >
            <img
              src="./icons/burger.png"
              alt="Menu BUtton"
              width={20}
              height={14}
            />
          </div>
        </div>
      )}
      {!onlyBurger && (
        <Fragment>
          <div
            onClick={() => {
              if (showDrawerMenu.value) showDrawerMenu.value = false;
              navigate('/home');
              showOverviewMenu.value = false;
              onBackButtonClick?.();
            }}
            style={{ cursor: 'pointer' }}
          >
            <img
              src="./orangeLogoVizualTravel.svg"
              alt="Logo"
              width={123.56}
              height={26}
            />
          </div>
          {showOverviewMenu.value ? (
            <div
              className="custom-badge pointer"
              onClick={() => {
                showOverviewMenu.value = false;
              }}
            >
              <img
                src="./icons/mapIcon.png"
                width={24}
                height={24}
                style={{ marginRight: '4px' }}
                alt="Map Icon"
              />
              <span>Map</span>
            </div>
          ) : shouldShowDiscovery ? (
            <div
              className="custom-badge pointer"
              style={{ padding: '10px' }}
              onClick={() => {
                // @ts-ignore
                if (isMobile && navigator.canShare) {
                  handleShare();
                } else {
                  isShareModalVisible.value = true;
                }
                // showDiscovery.value = isDiscovery ? false : true;
                // if (isDiscovery) navigate('/home');
              }}
            >
              <img
                src="./icons/share.png"
                width={24}
                height={24}
                alt="share"
                style={{ marginRight: '4px' }}
              />
              Share
            </div>
          ) : (
            <div style={{ width: '25%' }} />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Navbar;
