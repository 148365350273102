// React Imports
import React, { createContext, useContext } from 'react';

// Supabase Imports
import { supabase } from '~/supabase/supabaseClient';

// Redux Imports
import { RootState, useDispatch } from '~/redux/store';
import ActionsCreator from '~/redux/actions';
import { useSelector } from '~/redux/reducers';
import {
  AuthResponse,
  AuthTokenResponse,
  UserResponse,
} from '@supabase/supabase-js';

/**
 * Represents the props for the AuthContext component.
 * @interface AuthContextProps
 * @property {Function} signIn - Function for user sign in
 * @property {Function} signUp - Function for user sign up
 * @property {Function} verifyOtp - Function for user to verify their otp
 * @property {Function} updatePassword - Function to update password of a user
 * @property {Function} resetPassword - Function to reset password of a user
 * @property {Function} signOut - Function to sign out a logged in user
 * @property {Function} signInWithGoogle - Function to sign in with Google
 * @property {Function} updateUserData - Function to update user data
 */
interface AuthContextProps {
  signIn: (props: SignInProps) => Promise<any>;
  signUp: (props: SignUpProps) => Promise<any>;
  verifyOtp: (props: VerifyOtpProps) => Promise<any>;
  updatePassword: (props: UpdatePasswordProps) => Promise<any>;
  resetPassword: (props: ResetPasswordProps) => Promise<any>;
  signOut: () => Promise<{ message: string }>;
  signInWithGoogle: () => Promise<any>;
  updateUserData: (formData: UserData) => Promise<any>;
}

/**
 * Represents the type of parameters of signIn function
 * @interface SignInProps
 * @property {string} email - Email of a user
 * @property {string} password - Password of a user
 */
interface SignInProps {
  email: string;
  password: string;
}

/**
 * Represents the type of parameters of verifyOtp function
 * @interface VerifyOtpProps
 * @property {string} email - Email of a user
 * @property {string} otp -Otp of a user
 */
interface VerifyOtpProps {
  email: string;
  otp: string;
}

/**
 * Represents fields of UserData
 * @interface UserData
 * @property {string} fullName - Full name of a user
 * @property {string} email - Email address of a user
 * @property {string} password - Password of a user
 * @property {string} confirmPassword - Confirm Password of a user
 * @property {File | null} profilePicture - Profile Picture of a user
 */
interface UserData {
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  profilePicture: File | null;
}

/**
 * Represents the parameters required to sign up a new user.
 * @interface SignInProps
 * @property {string} email - Email of a user
 * @property {string} password - Password of a user
 * @property {string} fullName - Full name of a user
 */
interface SignUpProps {
  email: string;
  password: string;
  fullName: string;
}

/**
 * Represents the parameters required to update password of a user.
 * @interface UpdatePasswordProps
 * @property {string} newPassword - New password of a user
 */
interface UpdatePasswordProps {
  newPassword: string;
}

/**
 *Represents the parameters required to sign in a user.
 * @interface ResetPasswordProps
 * @property {string} email - Email address of a user
 */
interface ResetPasswordProps {
  email: string;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

/**
 * Represents the type of AuthProvider
 * @interface AuthProviderProps
 * @property {ReactNode} children - Children of AuthProvider
 */
interface AuthProviderProps {
  children: React.ReactNode;
}

/**
 * This is the AuthProvider component to its children
 * @returns {JSX.Element} Returns AuthContext.Provider
 */
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const userID: string = useSelector(
    (state: RootState) => state.MapReducers.userID,
  );

  const dispatch = useDispatch();

  /**
   * This function signs in a user
   * @param {SignInProps} props - Props of signIn Function
   * @returns Returns signed in user data and success message
   */
  /**
   * This function signs in a user with email and password
   * @param {SignInProps} props - Object containing email and password for signing in
   * @returns {Promise<{ message: string, data?: object, error?: object }>} - Returns an object with a success or error message and optionally includes user data or error details
   */
  const verifyOtp = async (props: VerifyOtpProps) => {
    try {
      const { email, otp } = props;

      // Sign in with email and password using the Supabase auth service
      const { data, error }: AuthResponse = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: 'signup',
      });

      // Check if there was an error during sign-in
      if (error) {
        return { message: `Error signing in: ${error.message}`, error };
      }

      // Check if user data is returned after successful sign-in
      if (data && data?.user) {
        const userData = data.user;

        // Dispatch actions to set user ID, email, name, and profile picture URL
        dispatch(ActionsCreator.setUserID(userData.id));
        dispatch(ActionsCreator.setUserEmail(userData.email as string));
        dispatch(ActionsCreator.setUserName(userData.user_metadata.full_name));

        if (userData.user_metadata?.profile_picture) {
          dispatch(
            ActionsCreator.setUserProfileImageURL(
              userData.user_metadata.profile_picture,
            ),
          );
        }

        return { message: 'The user is logged in!', data };
      } else {
        // Handle the case where the user canceled the login
        return { message: 'User login canceled.' };
      }
    } catch (error) {
      // Return a generic error message if an unexpected error occurs during sign-in
      return { message: `Error signing in` };
    }
  };

  /**
   * This function signs in a user
   * @param {SignInProps} props - Props of signIn Function
   * @returns Returns signed in user data and success message
   */
  /**
   * This function signs in a user with email and password
   * @param {SignInProps} props - Object containing email and password for signing in
   * @returns {Promise<{ message: string, data?: object, error?: object }>} - Returns an object with a success or error message and optionally includes user data or error details
   */
  const signIn = async (props: SignInProps) => {
    try {
      const { email, password } = props;

      // Sign in with email and password using the Supabase auth service
      const { data, error }: AuthTokenResponse =
        await supabase.auth.signInWithPassword({
          email,
          password,
        });

      // Check if there was an error during sign-in
      if (error) {
        return { message: `Error signing in: ${error.message}`, error };
      }

      // Check if user data is returned after successful sign-in
      if (data) {
        const userData = data.user;

        // Dispatch actions to set user ID, email, name, and profile picture URL
        dispatch(ActionsCreator.setUserID(userData.id));
        dispatch(ActionsCreator.setUserEmail(userData.email as string));
        dispatch(ActionsCreator.setUserName(userData.user_metadata.full_name));

        if (userData.user_metadata?.profile_picture) {
          dispatch(
            ActionsCreator.setUserProfileImageURL(
              userData.user_metadata.profile_picture,
            ),
          );
        }

        return { message: 'The user is logged in!', data };
      } else {
        // Handle the case where the user canceled the login
        return { message: 'User login canceled.' };
      }
    } catch (error) {
      // Return a generic error message if an unexpected error occurs during sign-in
      return { message: `Error signing in` };
    }
  };

  /**
   * Signs up a user with email and password.
   * @param {SignUpProps} props - Object containing email, password, and full name for signing up
   * @returns {Promise<{ auth: object, error: object | null }>} - Returns an object containing signed up user data and any error occurred during signup process
   */
  async function signUp(props: SignUpProps) {
    try {
      const { email, password, fullName } = props;

      const { data, error }: AuthResponse = await supabase.auth.signUp({
        email,
        password,
        options: {
          // Additional user data to be stored
          data: {
            full_name: fullName,
          },
        },
      });

      console.log({ error });

      // Sign up user using Supabase auth service
      // const { data, error }: AuthResponse = await supabase.auth.signUp({
      //   email,
      //   password,
      //   options: {
      //     // Redirect URL after successful signup
      //     emailRedirectTo: 'https://www.vizualtravel.com/login/?param1=value1',

      //     // Additional user data to be stored
      //     data: {
      //       full_name: fullName,
      //     },
      //   },
      // });

      let authError = null;

      // Check if the user exists but is a fake user
      if (
        data.user &&
        data.user.identities &&
        data.user.identities.length === 0
      ) {
        authError = {
          name: 'AuthApiError',
          message: 'User already exists',
        };
      } else if (error) {
        // Handle other signup errors
        authError = {
          name: error.name,
          message: error.message,
        };
      }

      // Return signed up user data and error information
      return { data: data, error: authError };
    } catch (error) {
      // Return a generic error message if an unexpected error occurs during signup
      return { message: `Error signing up` };
    }
  }

  /**
   * This function updates the password for a user
   * @param {UpdatePasswordProps} props - Object containing properties needed to update the password
   * @param {string} props.newPassword - The new password to set for the user
   * @returns {Promise<{ message: string, data?: object, error?: object }>} - Returns an object with a success or error message and optionally includes user data or error details
   */
  async function updatePassword(props: UpdatePasswordProps) {
    try {
      const { newPassword } = props;
      const { data, error }: UserResponse = await supabase.auth.updateUser({
        password: newPassword,
      });
      if (error) {
        return { message: `Error updating password: ${error.message}`, error };
      }
      return { message: 'Password has been updated successfully!', data };
    } catch (error) {
      return { message: `Error updating password` };
    }
  }

  /**
   * This function sends a password reset email to the user
   * @param {ResetPasswordProps} props - Object containing properties needed to reset the password
   * @param {string} props.email - The email address of the user requesting a password reset
   * @returns {Promise<{ message: string, data?: object, error?: object }>} - Returns an object with a success or error message and optionally includes user data or error details
   */
  async function resetPassword(props: ResetPasswordProps) {
    try {
      const { email } = props;
      // Send a password reset email using the Supabase auth service
      const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: 'https://www.vizualtravel.com/newpassword',
      });

      // Check if there was an error during the password reset request
      if (error) {
        return {
          message: `Error sending password reset email: ${error.message}`,
          error,
        };
      }

      // Return success message and relevant data if the email was sent successfully
      return {
        message: 'Password reset email has been sent successfully!',
        data,
      };
    } catch (error) {
      // Return a generic error message if an unexpected error occurs
      return { message: `Error sending password reset email` };
    }
  }

  /**
   * This function signs in a user with Google OAuth
   * @returns {Promise<{ message: string, data?: object, error?: object }>} - Returns an object with a success or error message and optionally includes user data or error details
   */
  async function signInWithGoogle() {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: 'https://www.vizualtravel.com/home',
        },
      });

      if (error) {
        return { message: `Error signing in: ${error.message}`, error };
      }

      if (data) {
        // The user is signed in with Google.
        return { message: 'The user is signed in with Google!', data };
      } else {
        // Handle the case where the user canceled the login.
        return { message: 'Google login canceled by the user.' };
      }
    } catch (error) {
      return { message: `Error signing in with Google` };
    }
  }

  /**
   * This function signs out the currently logged-in user
   * @returns {Promise<{ message: string }>} - Returns an object with a success or error message
   */
  async function signOut() {
    try {
      await supabase.auth.signOut();

      return { message: 'User has been successfully logged out' };
    } catch (error) {
      return { message: `Error signing up` };
    }
  }

  /**
   * This function updates user data including full name, password, and profile picture
   * @param {UserData} formData - An object containing the user's new data (fullName, password, confirmPassword, profilePicture)
   * @returns {Promise<{ success: boolean, data?: object, error?: string }>} - Returns an object indicating success or failure, and includes updated data or an error message
   */
  const updateUserData = async (formData: UserData) => {
    try {
      // Check if the password and confirm password fields match
      if (formData.password !== formData.confirmPassword) {
        return { success: false, error: 'Passwords do not match' };
      }

      // Common options object for updating user data
      const commonOptions: {
        data?: {
          full_name?: string | undefined;
          profile_picture?: string | undefined | null;
        };
        password?: string;
      } = {};

      // Add full name to the options if provided
      if (formData.fullName !== '') {
        commonOptions.data = {
          ...commonOptions.data,
          full_name: formData.fullName,
        };
      }

      // Add password to the options if provided
      if (formData.password !== '') {
        commonOptions.password = formData.password;
      }

      // Check if a profile picture is provided
      if (formData.profilePicture) {
        // Upload the profile picture and get the URL
        const {
          success: uploadSuccess,
          imageUrl,
          error: uploadError,
        } = await uploadProfilePicture(userID, formData.profilePicture);

        // Handle error during profile picture upload
        if (!uploadSuccess) {
          console.error('Error uploading profile picture:', uploadError);
          return { success: false, error: uploadError };
        }

        // Add the profile picture URL to the options
        commonOptions.data = {
          ...(commonOptions.data || {}),
          profile_picture: imageUrl?.data.publicUrl,
        };
      }

      // Update user data in the database using Supabase auth service
      const { data, error } = await supabase.auth.updateUser(commonOptions);

      // Handle error during user data update
      if (error) {
        console.error('Error updating user data:', error);
        return { success: false, error: error.message };
      } else {
        return { success: true, data };
      }
    } catch (error) {
      // Handle unexpected errors
      console.error('Error updating user data:', error);
      return { success: false, error: error };
    }
  };

  /**
   * This function uploads a profile picture to the user's folder in the storage
   * @param {string} userId - The ID of the user uploading the profile picture
   * @param {File} file - The profile picture file to upload
   * @returns {Promise<{ success: boolean, isNew?: boolean, imageUrl?: object, error?: string }>} - Returns an object indicating success or failure, whether the image is new or existing, and includes the image URL or an error message
   */
  const uploadProfilePicture = async (userId: string, file: File) => {
    try {
      // Define the user's folder path in the storage
      const userFolder = `users/${userId}/`;

      // Get the image name from the file
      const imageName = file.name;

      // Check if an image with the same name already exists in the user's folder
      const { data: filesInFolder, error: filesError } = await supabase.storage
        .from('profile-images')
        .list(`${userFolder}`);

      // Handle error when checking for existing files in the folder
      if (filesError) {
        console.error('Error checking files in folder:', filesError);
        return { success: false, error: filesError.message };
      }

      // Check if the image is already uploaded
      const isImageAlreadyUploaded = filesInFolder.some(
        (file) => file.name === imageName,
      );

      // If the image is not already uploaded, upload it
      if (!isImageAlreadyUploaded) {
        const { data: uploadData, error } = await supabase.storage
          .from('profile-images')
          .upload(`${userFolder}${file.name}`, file);

        // Handle error during image upload
        if (error) {
          console.error('Error uploading image:', error);
          return { success: false, error: error.message };
        }

        // Get the public URL of the newly uploaded image
        const newImageUrl = await supabase.storage
          .from('profile-images')
          .getPublicUrl(uploadData?.path);

        return { success: true, isNew: true, imageUrl: newImageUrl };
      } else {
        // Find the existing image in the folder
        const existingImage = filesInFolder.find(
          (file) => file.name === imageName,
        );

        if (existingImage) {
          // Get the public URL of the existing image
          const existingImageUrl = await supabase.storage
            .from('profile-images')
            .getPublicUrl(`${userFolder}${existingImage.name}`);

          return { success: true, isNew: false, imageUrl: existingImageUrl };
        } else {
          // Handle case where the existing image is not found
          return { success: false, error: 'Existing image not found.' };
        }
      }
    } catch (error) {
      // Handle unexpected errors
      return { success: false, error: error };
    }
  };

  const value: AuthContextProps = {
    signIn,
    signUp,
    signOut,
    verifyOtp,
    updatePassword,
    resetPassword,
    signInWithGoogle,
    updateUserData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

/**
 * Custom hook to access the authentication context
 * @returns {object} - The authentication context object
 * @throws {Error} - Throws an error if the hook is used outside of an AuthProvider
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
