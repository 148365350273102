import React from 'react';

interface ItemProps {
  name: string;
  price: number;
  days: string;
  srcUrl: string;
}

export const TripPlansItem = ({ item }: { item: ItemProps }) => {
  return (
    <a
      className="discover-menu-grid--item"
      href="https://www.vizualtravel.com/viewtravel?tourID=139718"
      target="_blank"
      rel="noreferrer"
    >
      <img src={item.srcUrl} alt="" />
      <div className="discover-menu-grid--item-content">
        <span className="discover-menu-grid--item-text">{item.name}</span>
        <span className="discover-menu-grid--item-text">
          {item.price}¥ - {item.days}
        </span>
      </div>
    </a>
  );
};
