import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import AccentText from './AccentText';
import {
  animationIsPlaying,
  openedCalendarOverlaySignal,
  currentTravelTypeSignal,
  durationString,
  endStateSignal,
  getActionBySelectedTransport,
  PauseAnimation,
  showMainHeader,
  showPlayPauseButton,
  openedHotelsFetchAPIFlowSignal,
  tappedOnMapSignal,
} from '../common';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { AnimatePresence, motion } from 'framer-motion';
import { PublishableTravelData } from '~/utility/models';
import HeaderText from './HeaderText';

import moment from 'moment-timezone';
import useTitleState from './useTitleState';
import {
  bottomSheetOverviewData,
  resetTrip,
} from '../MobileFooter/BottomSheet';
import { restartDaySignal } from '~/map/ViewTravel';
import { isOverlayVisible, isShareModalVisible } from '../StatsOverlay';
import { vizualizeButtonSignal } from '../MobileFooter/DaysHeader/DaysHeader';
import { showDrawerMenu } from '~/components/DrawerMenu';
import { showOverviewMenu } from '~/components/OverviewMenu';
import { useIsMobile } from '../counter/hooks/useMobile';
import { handleShare } from '~/utility/shareLink';
import { useDispatch } from '~/redux/store';
import ActionsCreator from '~/redux/actions';
import { RootState, useSelector } from '~/redux/reducers';
import dayjs, { Dayjs } from 'dayjs';
import { mapSignal } from '~/map/ViewTravel';
import { useMarkerContext } from '~/context/HotelMarkerContext';

const MobileHeader = React.memo(
  ({ travelPoints }: { travelPoints: PublishableTravelData[] }) => {
    const [isAnimationStarted, setIsAnimationStarted] = useState(false);
    const [isOverlay, setIsOverlay] = useState(isOverlayVisible.peek());
    const [isVisible, setIsVisible] = useState(true);
    const duration = useRef('');
    const isMobile = useIsMobile();
    const [padding, setPadding] = useState(0);
    const { clearMarkers } = useMarkerContext();
    const dispatch = useDispatch();

    const departureDate = useSelector(
      (state: RootState) => state.CalendarOverlayReducers.departureDate,
    );

    const returnDate = useSelector(
      (state: RootState) => state.CalendarOverlayReducers.returnDate,
    );

    const travelData = useSelector(
      (state: RootState) => state.TripInfoReducers.travelData,
    );

    const medianPrice = useSelector(
      (state: RootState) => state.HotelSwiperReducers.medianPrice,
    );

    const vizualizeButtonState = useSelector(
      (state: RootState) => state.AnimationReducers.vizualizeTripButtonState,
    );

    const isCalendarOverlayOpened = useSelector(
      (state: RootState) =>
        state.CalendarOverlayReducers.isCalendarOverlayOpened,
    );

    const isFetchHotelsAPIFlowOPened = useSelector(
      (state: RootState) =>
        state.CalendarOverlayReducers.isFetchHotelsAPIFlowOPened,
    );

    const isMapTapped = useSelector(
      (state: RootState) => state.CalendarOverlayReducers.isMapTapped,
    );

    const isAPIResponseEmpty = useSelector(
      (state: RootState) => state.CalendarOverlayReducers.isAPIResponseEmpty,
    );

    const isHotelButtonClicked = useSelector(
      (state: RootState) => state.HotelSwiperReducers.isHotelButtonClicked,
    );

    //hotel button click
    function handleHotelClick() {
      if (!isFetchHotelsAPIFlowOPened && !isCalendarOverlayOpened) {
        openedHotelsFetchAPIFlowSignal.value = true;
        openedCalendarOverlaySignal.value = true;

        dispatch(
          ActionsCreator.setIsFetchHotelsAPIFlowOPened(
            openedHotelsFetchAPIFlowSignal.value,
          ),
        );
        dispatch(
          ActionsCreator.setIsCalendarOverlayOpened(
            openedCalendarOverlaySignal.value,
          ),
        );
        dispatch(ActionsCreator.setIsAPIResponseEmpty(0));
      } else {
        if (isMapTapped) dispatch(ActionsCreator.setIsHotelButtonClicked(true));
        dispatch(ActionsCreator.setIsMapTapped(false));
        dispatch(ActionsCreator.setIsAPIResponseEmpty(0));
        clearMarkers();
      }
    }

    //go back arrow click
    function handleHotelGoBackArrowClick() {
      dispatch(ActionsCreator.setVizualizeTripButtonState(false));
    }

    //go back button click
    function handleHotelGoBackButtonClick() {
      mapSignal.value!.flyTo({
        zoom: 9,
      });

      openedCalendarOverlaySignal.value = false;
      openedHotelsFetchAPIFlowSignal.value = false;
      tappedOnMapSignal.value = false;
      clearMarkers();

      dispatch(
        ActionsCreator.setIsCalendarOverlayOpened(
          openedCalendarOverlaySignal.value,
        ),
      );
      dispatch(
        ActionsCreator.setIsFetchHotelsAPIFlowOPened(
          openedHotelsFetchAPIFlowSignal.value,
        ),
      );
      dispatch(ActionsCreator.setIsMapTapped(tappedOnMapSignal.value));
      dispatch(ActionsCreator.setIsAPIResponseEmpty(0));
    }

    // Overview Button
    function handleOverviewClick() {
      openedCalendarOverlaySignal.value = true;

      dispatch(
        ActionsCreator.setIsCalendarOverlayOpened(
          openedCalendarOverlaySignal.value,
        ),
      );
      dispatch(ActionsCreator.setOverviewButtonClicked(true));
    }

    let depart_date: string | Date | Dayjs =
      travelData[0]?.arrival?.dateTime ?? '';
    let return_date: string | Date | Dayjs =
      travelData[travelData.length - 1]?.departure?.dateTime ?? '';

    depart_date = dayjs(depart_date).format('MMM DD');
    return_date = dayjs(return_date).format('MMM DD');

    useSignalEffect(() => {
      setIsAnimationStarted(showPlayPauseButton.value);
    });

    useSignalEffect(() => {
      setIsOverlay(isOverlayVisible.value);
    });

    useSignalEffect(() => {
      if (!isFetchHotelsAPIFlowOPened) {
        setIsVisible(!bottomSheetOverviewData.value);
      }
    });

    const { step, headerState, day } = useTitleState(travelPoints);

    const currentPoint = travelPoints[step];
    const nextPoint = travelPoints[step + 1];

    const staypointLabel = useRef<string>(
      travelPoints[0].departure.location?.label || '',
    );
    const isLastDayPoint = useRef(false);

    useSignalEffect(() => {
      if (vizualizeButtonSignal.value) {
        staypointLabel.current =
          travelPoints[vizualizeButtonSignal.value.startIndex || 0].departure
            .location?.label || '';
      }
    });

    useSignalEffect(() => {
      if (resetTrip.value || endStateSignal.value || restartDaySignal.value) {
      }
      duration.current = '';
      staypointLabel.current = travelPoints[0].departure.location?.label || '';
      isLastDayPoint.current = false;
    });

    useEffect(() => {
      if (headerState === 'depart') {
        staypointLabel.current = currentPoint?.arrival?.location?.label || '';
        isLastDayPoint.current = !!nextPoint
          ? !(
              moment(String(currentPoint.departure.dateTime))
                .tz(currentPoint.departure.timezone)
                .format('DD-MM-YYYY') ===
              moment(String(nextPoint?.departure?.dateTime))
                .tz(nextPoint?.departure?.timezone)
                .format('DD-MM-YYYY')
            )
          : false;
        duration.current = durationString(
          moment(String(nextPoint?.departure?.dateTime)),
          moment(String(currentPoint.arrival.dateTime)),
        );
      }
    }, [headerState, currentPoint, nextPoint]);

    useEffect(() => {
      const handleWindowResize = () => {
        const width = window.innerWidth;
        if (width <= 320) {
          setPadding(10);
        } else if (width <= 350) {
          setPadding(12);
        } else if (width <= 430) {
          setPadding(16);
        } else {
          setPadding(22);
        }
      };

      handleWindowResize();

      window.addEventListener('resize', handleWindowResize);
      return () => window.removeEventListener('resize', handleWindowResize);
    });

    return (
      <AnimatePresence>
        {isVisible && (
          <motion.div
            exit={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: -200 }}
            style={{
              position: 'fixed',
              top: 0,
              width: '100%',
              pointerEvents: 'none',
            }}
          >
            {isAnimationStarted && (
              <motion.div
                exit={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -100 }}
                transition={{ type: 'tween' }}
              >
                <div
                  className={`mobileHeader-container ${
                    headerState === 'showDay'
                      ? 'mobileHeader-dark-bg'
                      : 'mobileHeader-light-bg'
                  }`}
                >
                  {headerState === 'showDay' && (
                    <HeaderText color="white">
                      Your trip on <AccentText>{`Day ${day}`}</AccentText>
                    </HeaderText>
                  )}
                  {(headerState === 'firstPoint' ||
                    (headerState === 'stayPoint' && !duration.current)) && (
                    <HeaderText>{staypointLabel.current}</HeaderText>
                  )}
                  {headerState === 'depart' && (
                    <HeaderText>
                      Depart at{' '}
                      <AccentText>
                        {moment(currentPoint?.departure?.dateTime as string)
                          .tz(currentPoint?.departure?.timezone)
                          .format('hh:mma')}
                      </AccentText>
                    </HeaderText>
                  )}
                  {headerState === 'flyfor' ? (
                    <HeaderText>
                      {getActionBySelectedTransport(
                        currentTravelTypeSignal.value,
                      )}
                      {currentPoint?.selectedTransport === 'Transit'
                        ? ''
                        : ' for '}
                      {currentPoint?.selectedTransport !== 'Transit' && (
                        <AccentText>
                          {durationString(
                            moment(String(currentPoint?.arrival?.dateTime)),
                            moment(String(currentPoint?.departure?.dateTime)),
                          )}
                        </AccentText>
                      )}
                    </HeaderText>
                  ) : null}
                  {headerState === 'arrival' && (
                    <HeaderText>
                      Arrive at{' '}
                      <AccentText>
                        {moment(currentPoint?.arrival?.dateTime as string)
                          .tz(currentPoint?.arrival?.timezone)
                          .format('hh:mma')}
                      </AccentText>
                    </HeaderText>
                  )}

                  {!isLastDayPoint.current &&
                    headerState === 'stayPoint' &&
                    !!duration.current && (
                      <HeaderText>
                        Spend <AccentText>{duration.current}</AccentText>
                        {` at ${staypointLabel.current}`}
                      </HeaderText>
                    )}
                  {headerState === 'stayPoint' && isLastDayPoint.current && (
                    <HeaderText>{staypointLabel.current}</HeaderText>
                  )}
                </div>
              </motion.div>
            )}
            <AnimatePresence>
              {/* go back & burger icons */}
              <motion.div
                animate={{
                  opacity: isOverlay ? 1 : 0.4,
                  y: 0,
                  zIndex: 0,
                }}
                style={{
                  position: 'relative',
                  paddingLeft: padding,
                  paddingRight: padding,
                  top: 24,
                  display: showMainHeader.peek() ? 'flex' : 'none',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  pointerEvents: 'none',
                }}
              >
                {isFetchHotelsAPIFlowOPened &&
                !isCalendarOverlayOpened &&
                !isAnimationStarted ? (
                  <div
                    className="mobile-header-burger-menu burger-menu-hotel-swiper"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleHotelGoBackButtonClick();
                    }}
                    style={{
                      pointerEvents: 'all',
                      cursor: 'pointer',
                    }}
                  >
                    <img
                      className="back-arrow-img"
                      src="icons/go-back-arrow.png"
                      width={50}
                      height={35}
                      alt="go-back"
                    />
                  </div>
                ) : !isFetchHotelsAPIFlowOPened &&
                  !isCalendarOverlayOpened &&
                  isAnimationStarted ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleHotelGoBackArrowClick();
                      resetTrip.value = true;
                      restartDaySignal.value = true;
                      isOverlayVisible.value = true;
                    }}
                    style={{ pointerEvents: 'all', cursor: 'pointer' }}
                  >
                    <img
                      className="burger-menu-img"
                      src="./goBack.png"
                      width={50}
                      height={35}
                      alt="go-back"
                    />
                  </div>
                ) : isFetchHotelsAPIFlowOPened === isCalendarOverlayOpened &&
                  !isAnimationStarted ? (
                  <div
                    className={
                      'mobile-header-burger-menu burger-menu-hotel-swiper'
                    }
                    onTouchStart={(e) => {
                      e.stopPropagation();
                      isOverlayVisible.value = true;
                      showDrawerMenu.value = true;
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      isOverlayVisible.value = true;
                      showDrawerMenu.value = true;
                    }}
                    style={{ pointerEvents: 'all' }}
                  >
                    <img
                      src="./icons/burger.png"
                      width={20}
                      height={14}
                      alt="menu"
                    />
                  </div>
                ) : null}

                {/* Overview Button */}
                <div
                  className="custom-badge text-black pointer overview-btn overview-btn-hotel-swiper"
                  onClick={(e) => {
                    e.stopPropagation();

                    if (
                      !isFetchHotelsAPIFlowOPened &&
                      !isCalendarOverlayOpened &&
                      !isMapTapped
                    ) {
                      isOverlayVisible.value = true;
                      showOverviewMenu.value = true;
                      PauseAnimation();
                    } else {
                      handleOverviewClick();
                    }
                  }}
                  style={{ pointerEvents: 'all' }}
                >
                  {/* Three cases: 1.)dates selected with no API call, 2.) dates selected with API call 3.)no date selected */}
                  {isFetchHotelsAPIFlowOPened &&
                  !isCalendarOverlayOpened &&
                  !isMapTapped &&
                  (isAPIResponseEmpty !== -1 || isHotelButtonClicked) ? (
                    <span>{`${dayjs(departureDate).format('MMM D')} - ${
                      returnDate ? dayjs(returnDate).format('MMM D') : '...'
                    }`}</span>
                  ) : isFetchHotelsAPIFlowOPened &&
                    (isAPIResponseEmpty === 1 || isAPIResponseEmpty === -1) ? (
                    <span>
                      {`${dayjs(departureDate).format('MMM D')} - ${
                        returnDate ? dayjs(returnDate).format('MMM D') : '...'
                      }`}
                      <br />
                      <span className="overview-btn-average-price">
                        {`(avg ${
                          medianPrice !== 0 ? `${medianPrice}$` : '...'
                        }/ night in the area)`}
                      </span>
                    </span>
                  ) : isFetchHotelsAPIFlowOPened === isCalendarOverlayOpened ? (
                    <span
                      style={{
                        fontSize: '22px',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                      }}
                    >
                      Overview
                    </span>
                  ) : null}
                </div>

                <div className="mobile-header-buttons-container">
                  {/* Share button */}
                  <div
                    className={`mobile-header-share-button share-button-hotel-swiper ${
                      isMobile && 'mobile'
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      isOverlayVisible.value = true;
                      animationIsPlaying.value && PauseAnimation();
                      // @ts-ignore - mozilla doesn't have this prop
                      if (isMobile && navigator.canShare) {
                        handleShare();
                      } else {
                        isOverlayVisible.value = true;
                        isShareModalVisible.value = true;
                      }
                    }}
                    style={{ pointerEvents: 'all' }}
                  >
                    <img
                      src="./icons/share.png"
                      width={24}
                      height={24}
                      alt="share"
                    />
                    <span>Share</span>
                  </div>

                  {/* Hotel button */}
                  {!vizualizeButtonState && (
                    <div
                      className={`mobile-header-share-button hotel-button-hotel-swiper ${
                        isMobile && 'mobile'
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleHotelClick();
                      }}
                      style={{ pointerEvents: 'all' }}
                    >
                      <img
                        src="./icons/hotelIcon.svg"
                        width={40}
                        height={35}
                        alt="hotel"
                        style={{
                          marginTop: '4px',
                          marginRight: '-4px',
                          marginLeft: '-6px',
                        }}
                      />

                      <span style={{ color: '#a87bf2' }}>Hotel</span>
                    </div>
                  )}
                </div>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    );
  },
  (prev, next) => prev.travelPoints === next.travelPoints,
);

export default MobileHeader;
