import * as Types from "../actionTypes";

export type CalendarOverlayProps = {
    isFetchHotelsAPIFlowOPened: boolean;
    isCalendarOverlayOpened: boolean;
    isMapTapped: boolean;
    coordinateLatitude: number | null;
    coordinateLongitude: number | null;
    isAPIResponseEmpty: number;
    isOverViewButtonClicked: boolean;
    departureDate: string | null;
    returnDate: string | null;
};

type ActionProps = CalendarOverlayProps & {
    type: string;
};

const initialState: CalendarOverlayProps = {
    isCalendarOverlayOpened: false,
    isFetchHotelsAPIFlowOPened: false,
    isMapTapped: false,
    coordinateLatitude: null,
    coordinateLongitude: null,
    isAPIResponseEmpty: 0,
    isOverViewButtonClicked: false,
    departureDate: null,
    returnDate: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: ActionProps) => {
    switch (action.type) {
        case Types.SET_IS_CALENDAR_OVERLAY_OPENED:
            return {
                ...state,
                isCalendarOverlayOpened: action.isCalendarOverlayOpened,
            };

        case Types.SET_IS_FETCH_API_FLOW_OPENED:
            return {
                ...state,
                isFetchHotelsAPIFlowOPened: action.isFetchHotelsAPIFlowOPened,
            };

        case Types.SET_IS_MAP_TAPPED:
            return {
                ...state,
                isMapTapped: action.isMapTapped,
            };

        case Types.GET_MAP_COORDINATE_LATITUDE:
            return {
                ...state,
                coordinateLatitude: action.coordinateLatitude,
            };

        case Types.GET_MAP_COORDINATE_LONGITUDE:
            return {
                ...state,
                coordinateLongitude: action.coordinateLongitude,
            };

        case Types.SET_IS_API_RESPONSE_EMPTY:
            return {
                ...state,
                isAPIResponseEmpty: action.isAPIResponseEmpty,
            };

             case Types.SET_IS_OVERVIEW_BUTTON_CLICKED:
            return {
                ...state,
                isOverViewButtonClicked: action.isOverViewButtonClicked,
            };

            case Types.SET_DEPARTURE_DATE:
            return {
                ...state,
                departureDate: action.departureDate,
            };

            case Types.SET_RETURN_DATE:
            return {
                ...state,
                returnDate: action.returnDate,
            };

        default:
            return state;
    }
};
