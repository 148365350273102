import * as Types from "../actionTypes";

export type HotelSwiperProps = {
    isHotelButtonClicked: boolean;
    isHotelButtonTouched: boolean;
    medianPrice: number;
};

type ActionProps = HotelSwiperProps & {
    type: string;
};

const initialState: HotelSwiperProps = {
    isHotelButtonClicked: false,
    isHotelButtonTouched: false,
    medianPrice: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: ActionProps) => {
    switch (action.type) {
        case Types.SET_IS_HOTEL_BUTTON_CLICKED:
            return {
                ...state,
                isHotelButtonClicked: action.isHotelButtonClicked,
            };

        case Types.SET_IS_HOTEL_BUTTON_TOUCHED:
            return {
                ...state,
                isHotelButtonTouched: action.isHotelButtonTouched,
            };

        case Types.SET_MEDIAN_PRICE:
            return {
                ...state,
                medianPrice: action.medianPrice,
            };

        default:
            return state;
    }
};
