import { Dayjs } from 'dayjs';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import { IStreamAction } from '~/hooks/use_websocket';
import { Options } from '~/utility/models';
import { TripOptions } from '~/utility/utils';

// Define WebSocket response message types
export enum WebsocketResponseMessageType {
  Error = 'error',
  Processing = 'processing',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Paused = 'paused',
  DataReady = 'dataReady',
  NoData = 'noData',
  titleDescription = 'titleDescription',
  Cancellation = 'cancellation',
}

// Structure of the WebSocket message
export interface WebSocketMessage {
  error?: string;
  action?: IStreamAction;
  payload: {
    id: string;
    prompt?: string;
    placeIndex?: number;
    transportationIndex?: number;
    dayIndex?: number;
    reason?: string;
  };
}

// Form data structure for travel segments
export interface IFormData {
  location: Options | null; // The location information, which is of type Options or null if not set
  dateTime: Date | Dayjs | null | string; // The date and time for the travel segment, or null if not set
  category: string; // Category or type of travel segment
  timezone: string; // Timezone for the travel segment
  images: string[]; // Images for the travel (use an empty array instead of [])
  cost: number; // Cost of the travel segment
  description: string; // Description of the travel segment
  review?: number;
}

// Trip details structure
export interface ITripDetails {
  options: TripOptions; // Trip options
  photos: string[]; // Array of photo URLs
  cost: number; // Total cost of the trip
  description: string; // Description of the trip
}

// Completed place structure
export interface ICompletedPlace {
  arrivals: IFormData[]; // List of arrival segments
  departures: IFormData[]; // List of departure segments
  selectedTransports: TravelMode[]; // Selected transportation modes
  selectedTransportImages: string[]; // Images for selected transports
  transportationCosts: number[]; // Costs for the transportation
  dayIndexes: number[];
  title: string; // Trip title
  description?: string; // Trip description
  quickTips?: string; // Quick tips for itinerary
}

// Processed place structure
export interface IProcessedPlace {
  placeIndex: number; // Index of the place
  place: IFormData; // Travel segment data for the place
  transportationIndex: number; // Index of the selected transportation
  completedTransportations: ICompletedPlace[]; // Completed transportation details
  generatedPlace: IGeneratedPlace;
  dayIndex: number;
}

// WebSocket response message structure
export interface WebSocketResponseMessage {
  status: WebsocketResponseMessageType; // Status of the WebSocket response
  processed: IProcessedPlace; // Processed place data
  completed: ICompletedPlace; // Completed place data
  itineraries: ICompletedPlace[];
  title: string;
  description: string;
  quickTips?: string;
  error?: string; // Optional error message
  data?: any; // Optional data
}

// Structure for removing a transportation point
export interface IRemovePoint {
  placeIndex: number; // Index of the place to remove
  transportationIndex: number; // Index of the transportation to remove
  reason?: string; // Optional reason for removal
}

export interface IGeneratedPlace {
  transportationIndex: string;
  transportationType: 'Flight' | 'Drive' | 'Walk' | 'Ferry' | 'Transit';
  transportationCost: string;
  dayIndex: string;
  departurePointFullName: string;
  departurePointFullAddress: string;
  departurePointType:
    | 'Airport'
    | 'Home'
    | 'Hotel'
    | 'Restaurant'
    | 'Point of Attraction'
    | 'Other';
  departureDate: string;
  departureCountry: string;
  departureCity: string;
  arrivalPointFullName: string;
  arrivalPointFullAddress: string;
  arrivalPointType:
    | 'Airport'
    | 'Home'
    | 'Hotel'
    | 'Restaurant'
    | 'Point of Attraction'
    | 'Other';
  arrivalDate: string;
  arrivalCountry: string;
  arrivalCity: string;
  arrivalPointCost: string;
  arrivalPlaceDescription: string;
}
