import React, { useState, useEffect } from 'react';
import {
  tappedCoordinateLatitude,
  tappedCoordinateLongitude,
  tappedOnMapSignal,
} from '../../common';
import ActionsCreator from '~/redux/actions';
import { useDispatch } from '~/redux/store';
import { RootState, useSelector } from '~/redux/reducers';
import { mapSignal } from '~/map/ViewTravel';
import { MapTouchEvent, MapMouseEvent } from 'maplibre-gl';
import { useSignalEffect } from '@preact/signals-react/runtime';
import {Typography} from '@mui/material';
import './MapTapButton.css';

type mapTapButtonInterface = {
  text: string;
};

export const MapTapButton: React.FC<mapTapButtonInterface> = ({ text }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!mapSignal.value) {
      console.error('Map instance is not initialized yet.');
      return;
    }

    //map tap coordinates fetching
    const handleTouchMap = (event: MapMouseEvent) => {
      const coordinates = event.lngLat;

      tappedCoordinateLatitude.value = coordinates.lat;
      tappedCoordinateLongitude.value = coordinates.lng;
      tappedOnMapSignal.value = true;

      dispatch(ActionsCreator.setIsHotelButtonClicked(false));
      dispatch(ActionsCreator.setMedianPrice(0));
      dispatch(
        ActionsCreator.setCoordinateLatitude(tappedCoordinateLatitude.value),
      );
      dispatch(
        ActionsCreator.setCoordinateLongitude(tappedCoordinateLongitude.value),
      );
      dispatch(ActionsCreator.setIsMapTapped(tappedOnMapSignal.value));
    };

    mapSignal.value.on('click', handleTouchMap); // adding map tap event listener

    return () => {
      mapSignal.value!.off('click', handleTouchMap); // removing map tap event listener
    };
  }, []);

  return (
    <Typography
      className="map-tap-button"
      style={{ color: text[0] === 'C' ? 'red' : 'black' }}
    >
      {text}
    </Typography>
  );
};
