import { createTheme } from "@mui/material/styles";

const MuiTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#FE7138",
      contrastText: "white",
    },
    secondary: {
      main: "#D3D3D3",
      contrastText: "000000",
    },
  },
  breakpoints: {
    values: {
      xs: 415,
      sm: 500,
      md: 601, // Customize your breakpoints as needed
      lg: 992,
      xl: 1500,
    },
  },
  // Define other theme properties here
});

export default MuiTheme;
