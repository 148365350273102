import React from 'react';
import { GridItem, GridItemProps } from '../GridItem/GridItem';

export const CustomContent = ({
  data,
  showStory,
}: {
  data: any;
  showStory: (idx: number) => void;
}) => {
  return (
    <div className="discover-menu-grid">
      {data.map((item: GridItemProps, index: number) => (
        <GridItem item={item} key={index.toString()} showStory={showStory} />
      ))}
    </div>
  );
};
