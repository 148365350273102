import './style.css';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { Animator, AnimatorChild } from '../DesktopFooter';
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  DateFormatType,
  TravelPoint,
  formatDate,
  isOverlayVisible,
} from '../StatsOverlay';
import { AnimatePresence, motion } from 'framer-motion';
import { calculateDuration, DatesBar } from '../DesktopHeader/CalenderView';
import {
  TextTicker,
  TravelDataSignal,
  animationStateSignal,
  categoryViewSignal,
  clickedIndexSignal,
  computeHeading,
  destinationStateSignal,
  durationSignal,
  emptyStateSignal,
  endStateSignal,
  formatCategory,
  generateDates,
  getActionBySelectedTransport,
  getIconBySelectedTransport,
  icons,
  loadStateProvokeSignal,
  resetAllSignals,
  showPlayPauseButton,
} from '../common';
import { calendarTransitionResolverCurrentDate } from '../DesktopFooter/CalendarLine';
import { signal } from '@preact/signals-core';
import Carousel from '../DesktopHeader/CalenderView/Carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { DAYJS, mapSignal, restartDaySignal } from '~/map/ViewTravel';
import { setImagesBySignal } from '../common';
import BottomSheet, { collapseBottomSheet } from './BottomSheet';
import StayPointLabelMobile from './Step3/StayPointLabelMobile';
import { useDispatch } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { MapTapButton } from './MobileFooterButtons/MapTapButton';
import HotelSwiper from './HotelCardsSwiper/HotelSwiper';
import { RootState, useSelector } from '~/redux/reducers';

export const loadStepSignal = signal(false);
const DELAY_TIME = 600;
let firstTime = false;
let firstTimeDestination = false;

export default memo(
  (props: any) => {
    const [step, setStep] = useState('idle');
    const [opened, setOpened] = useState(false);

    const isHotelButtonClicked = useSelector(
      (state: RootState) => state.HotelSwiperReducers.isHotelButtonClicked,
    );

    const isCalendarOverlayOpened = useSelector(
      (state: RootState) =>
        state.CalendarOverlayReducers.isCalendarOverlayOpened,
    );

    const isFetchHotelsAPIFlowOPened = useSelector(
      (state: RootState) =>
        state.CalendarOverlayReducers.isFetchHotelsAPIFlowOPened,
    );

    const isMapTapped = useSelector(
      (state: RootState) => state.CalendarOverlayReducers.isMapTapped,
    );

    const isAPIResponseEmpty = useSelector(
      (state: RootState) => state.CalendarOverlayReducers.isAPIResponseEmpty,
    );

    const steps = useMemo(
      (): any => ({
        idle: (
          <Step1
            setStep={setStep}
            startDate={props?.startDate}
            endDate={props?.endDate}
            to={props?.to}
            restart={props?.handleCardSelect}
          />
        ),
        load: (
          <Step2
            startDate={props?.startDate}
            endDate={props?.endDate}
            currentDate={props?.currentDate}
          />
        ),
        depart: (
          <Step3
            line1={
              <b>
                Depart <span>at</span>{' '}
                {props?.fromTime?.[props?.step?.calendarStep || 0]}
              </b>
            }
            line2={
              <b>
                <span>from </span>{' '}
                {props?.from?.[props?.step?.calendarStep || 0] || ''}
              </b>
            }
          />
        ),
        flyfor: (
          <Step3
            line1={
              <b>
                {getActionBySelectedTransport(
                  props?.selectedTransports?.[props?.step?.calendarStep || 0],
                )}{' '}
                <span>for</span>{' '}
                {props?.duration?.[props?.step?.calendarStep || 0]}
              </b>
            }
          />
        ),
        arrival: (
          <Step3
            line1={
              <b>
                Arrival <span>at</span>{' '}
                {props?.destinationTime?.[props?.step?.calendarStep || 0]}{' '}
              </b>
            }
            line2={
              <b>
                to {props?.destination?.[props?.step?.calendarStep || 0] || ''}
              </b>
            }
          />
        ),
        end: (
          // <div style={{ background: 'blue' }}>
          <Step1
            reserve
            setStep={setStep}
            startDate={props?.startDate}
            endDate={props?.endDate}
            to={props?.to}
            restart={props?.handleCardSelect}
          />
          // </div>
        ),
        staypoint: <StayPointLabelMobile />,
      }),
      [
        props?.currentDate,
        props?.destinationTime,
        props?.endDate,
        props?.from,
        props?.fromTime,
        props?.handleCardSelect,
        props?.selectedTransports,
        props?.startDate,
        props?.step,
        props?.to,
      ],
    );

    const [timeOut, setTimeOut] = useState<any>(null);
    const dispatch = useDispatch();

    useSignalEffect(() => {
      if (restartDaySignal.value) {
        setStep('idle');
        firstTime = false;
        firstTimeDestination = false;
        restartDaySignal.value = false;
        showPlayPauseButton.value = false;
        isOverlayVisible.value = true;
        resetAllSignals(false);
        dispatch(ActionsCreator.setPlayPauseState(true));
      }
    });
    useLayoutEffect(() => {
      calendarTransitionResolverCurrentDate.value = props?.currentDate;
      const unsub = loadStateProvokeSignal.subscribe(() => {
        if (firstTime === false) {
          firstTime = true;
          return;
        }
        setStep('load');
      });
      const endStateunsub = endStateSignal.subscribe((e) => {
        if (e) {
          setStep('end');
          setImagesBySignal([]);
        }
      });
      const emptyStateunsub = emptyStateSignal.subscribe((e) => {
        if (e) {
          setStep('');
        }
      });
      const destinationStateunsub = destinationStateSignal.subscribe(
        (e: any) => {
          if (firstTimeDestination === false) {
            firstTimeDestination = true;
            return;
          }

          if (e?.label) {
            setStep('staypoint');
          } else {
            setStep('depart');
          }
        },
      );
      return () => {
        unsub();
        endStateunsub();
        emptyStateunsub();
        destinationStateunsub();
        clearTimeout(timeOut);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      (async () => {
        if (props?.step?.state === 'empty') {
          setStep('');
          setTimeOut(
            setTimeout(() => {
              setStep('');
            }, DELAY_TIME),
          );
          return;
        }

        if (
          props?.step?.state === 'depart' &&
          (step === 'load' || step === '') &&
          props?.selectedTransports?.[props?.step?.calendarStep].length > 0
        ) {
          setStep('staypoint');

          return;
        }

        if (props?.step?.state === 'flyfor' && step === 'depart') {
          setTimeOut(
            setTimeout(() => {
              setStep('flyfor');
            }, DELAY_TIME),
          );
          return;
        }

        if (props?.step?.state === 'arrival' && step === 'flyfor') {
          setStep('arrival');
          const currentIndex = animationStateSignal.peek()?.calendarStep;
          const travelDate = TravelDataSignal.peek();
          const images = travelDate?.[currentIndex]?.arrival
            ?.images as string[];
          setImagesBySignal(
            images,
            travelDate?.[currentIndex]?.arrival?.location?.label,
            travelDate?.[currentIndex]?.arrival?.category,
          );

          return;
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.step]);

    const AnimatorChilds = useMemo(() => {
      return (
        <Animator
          step={step}
          opened={false}
          style={{ justifyContent: 'flex-end' }}
        >
          <Fragment>
            {Object.keys(steps).map((key: any, index) => {
              return (
                <AnimatorChild isMobile key={index} state={key}>
                  {steps[key]}
                </AnimatorChild>
              );
            })}
          </Fragment>
        </Animator>
      );
    }, [step]);

    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
      >
        {/* Components for Hotel Swiper and Calendar Overlay */}
        <Fragment>
          <AnimatePresence mode="sync">
            <div style={{ marginTop: 'auto' }}>
              {isFetchHotelsAPIFlowOPened &&
              !isCalendarOverlayOpened &&
              !isMapTapped &&
              (isAPIResponseEmpty === 0 || isHotelButtonClicked) ? (
                <MapTapButton text="Tap on Map to Search for a place" />
              ) : isFetchHotelsAPIFlowOPened &&
                !isMapTapped &&
                isAPIResponseEmpty === -1 &&
                !isHotelButtonClicked ? (
                <MapTapButton text="Could not Find any Hotels in 1 km radius with current filters applied. Tap again!" />
              ) : isFetchHotelsAPIFlowOPened && isMapTapped ? (
                <HotelSwiper />
              ) : (
                <BottomSheet.Wrapper
                  travelData={props?.data}
                  draggable={!['idle', 'end'].includes(step)}
                  setOpened={setOpened}
                  isAnimationIdle={step === 'idle'}
                  setStep={setStep}
                  startTrip={props?.handleCardSelect}
                />
              )}
            </div>
          </AnimatePresence>
        </Fragment>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.step.state === nextProps.step.state &&
    prevProps.isVisible === nextProps.isVisible,
);

const MobileSheetHeader = memo(
  ({ selectedTransports, step, toObj, currentDate }: any) => {
    const [clickedIndex, setClickedIndex] = useState<any>(NaN);
    const [destinationState, setDestinationState] = useState<any>(null);
    useEffect(() => {
      const unsub = clickedIndexSignal.subscribe(setClickedIndex);
      const destinationStateunsub = destinationStateSignal.subscribe(
        (e: any) => {
          setDestinationState(e);
        },
      );
      return () => {
        unsub();
        destinationStateunsub();
      };
    }, []);
    let currentIndex = animationStateSignal.peek()?.calendarStep;
    let type = selectedTransports?.[clickedIndex ?? currentIndex ?? 0];

    const hasLabel = toObj?.[clickedIndex ?? currentIndex ?? 0]?.label;
    const validStep = step !== '' && step !== 'load';
    return (
      <motion.div className="d-flex w-full mobile-calendar-wrapper-header flex-1">
        <motion.div className="calendar-header-date-wrapper">
          <motion.div
            exit={{ y: 0 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
            className="d-flex  align-items-center calendar-header-date-closed"
          >
            {hasLabel &&
              step !== '' &&
              step !== 'load' &&
              (step !== 'staypoint' ? (
                <motion.span>{getIconBySelectedTransport(type)}</motion.span>
              ) : (
                <motion.span>{categoryViewSignal.peek().icon}</motion.span>
              ))}
            <div className="d-flex flex-column ps-3">
              {hasLabel && validStep ? (
                step === 'staypoint' ? (
                  <span className="toHeading-mobile">
                    {destinationState?.label ? (
                      <TextTicker>{` ${destinationState?.label}`}</TextTicker>
                    ) : (
                      <>&nbsp;</>
                    )}
                  </span>
                ) : (
                  <span className="toHeading-mobile">
                    {hasLabel &&
                      `${getActionBySelectedTransport(
                        clickedIndex ?? selectedTransports?.[currentIndex ?? 0],
                      )} to ${
                        toObj?.[clickedIndex ?? currentIndex ?? 0]?.label
                      }`}
                  </span>
                )
              ) : (
                <span className="toHeading-mobile">&nbsp;</span>
              )}
              {hasLabel && validStep ? (
                <motion.span
                  // layoutId="date-bottom-calendar"
                  className="dateHeading-mobile"
                >
                  {formatDate(
                    currentDate,
                    DateFormatType.FullDayMonthDateCommaYear,
                  )}
                </motion.span>
              ) : (
                <>&nbsp;</>
              )}
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.currentDate === nextProps.currentDate &&
      prevProps.step === nextProps.step
    );
  },
);

export const CalendarMenu = memo(
  ({
    currentdate,
    handleCardSelect,

    departureDates,
    startDate,
    endDate,
    data,
    calendarStep,
  }: {
    currentdate: string;
    opened: boolean;
    setOpened: (opened: boolean) => void;
    data: TravelPoint[];
    type: string;
    toHeading: string;
    date: string;
    step: any;

    departureDates: Date[];
    startDate: Date | null;
    endDate: Date | null;
    handleCardSelect: (index: number) => void;
    calendarStep: any;
  }) => {
    const [tempCurrentDate, setTempCurrentDate] = useState<
      string | undefined
    >();
    const dates = useMemo(
      () => generateDates(startDate, endDate),
      [endDate, startDate],
    );

    const goToDepartureDate = (date: Date) => {
      setTempCurrentDate(date.toISOString());
    };
    // useEffect(() => {
    //   const timeout = setTimeout(() => {
    //     setTempCurrentDate(undefined);
    //   }, 2000);
    //   return () => {
    //     clearTimeout(timeout);
    //   };
    // }, [tempCurrentDate]);

    const currentDateFilter = useCallback(
      (d: TravelPoint) => {
        const date = DAYJS(d?.departure?.dateTime);
        return (
          DAYJS(date).format('DD MMM YYYY') ===
          DAYJS(tempCurrentDate || currentdate).format('DD MMM YYYY')
        );
      },
      [tempCurrentDate, currentdate],
    );

    const items = useMemo(
      () =>
        data
          ?.sort(
            (a, b) =>
              DAYJS(a?.departure?.dateTime).unix() -
              DAYJS(b?.departure?.dateTime).unix(),
          )
          .map((d, i) => ({ ...d, index: i }))
          .filter(currentDateFilter)
          .map((d) => {
            return {
              type: d?.selectedTransport,
              title: d?.arrival?.location?.label,
              from: formatCategory(d?.departure?.category),
              to: formatCategory(d?.arrival?.category),
              departure: DAYJS(d?.departure?.dateTime)
                .tz(d?.departure?.timezone)
                .format('h:mm a'),
              arrival: DAYJS(d?.arrival?.dateTime)
                .tz(d?.arrival?.timezone)
                .format('h:mm a'),
              duration: calculateDuration(
                d?.departure?.dateTime,
                d?.arrival?.dateTime,
              ),
              departureDate: d?.departure.dateTime,
              index: d?.index,
            };
          }) || [],
      [data, currentDateFilter],
    );

    const [currentCalendarDate, setCurrentCalendarDate] = useState<
      typeof currentdate
    >(tempCurrentDate || currentdate);

    useEffect(() => {
      setCurrentCalendarDate(tempCurrentDate || currentdate);
    }, [tempCurrentDate, currentdate]);

    return (
      <>
        <AnimatePresence mode="wait">
          <motion.div className="mobile-calendar-wrapper">
            <motion.div
              key={'mobile-calendar-wrapper-content'}
              initial={{ opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.35,
                ease: 'easeInOut',
                delay: 0.05,
                type: 'tween',
              }}
              exit={{
                opacity: 0,
                transition: {
                  delay: 0,
                },
              }}
              style={{
                width: '100%',

                transformOrigin: 'bottom',
              }}
              className="mobile-calendar-container"
            >
              <div className="d-flex justify-content-evenly">
                <DatesBar
                  mobile
                  dates={dates}
                  currentDate={tempCurrentDate || currentCalendarDate}
                  departureDates={departureDates}
                  goToDepartureDate={goToDepartureDate}
                />
              </div>

              <Timeline
                items={items}
                step={calendarStep}
                dates={dates}
                currentDate={tempCurrentDate || currentdate}
                handleCardSelect={handleCardSelect}
                // dates={dates}
                departureDates={departureDates}
              />
            </motion.div>
          </motion.div>
        </AnimatePresence>
      </>
    );
  },
);

export const Icon = ({ type }: { type: string }) => {
  return icons.hasOwnProperty(type) ? icons[type] : null;
};

const Line = () => {
  return <div className="timeline-line-mobile" />;
};

const TempTimelineSelectionSignal = signal(0);

const Timeline = memo(
  ({
    items,
    step,
    handleCardSelect,
  }: {
    departureDates: Date[];
    items: any;
    step: any;
    dates: { date: Date; day: string }[];
    currentDate: string;
    handleCardSelect: (index: number) => void;
  }) => {
    const [temp, setTemp] = useState(TempTimelineSelectionSignal.peek());
    const dispatch = useDispatch();
    const [emblaRef, emblaApi] = useEmblaCarousel({
      axis: 'y',
      containScroll: 'trimSnaps',
    });
    useEffect(() => {
      TempTimelineSelectionSignal.value =
        clickedIndexSignal.peek() || step?.calendarStep;
    }, [step?.calendarStep]);

    useEffect(() => {
      const unsub = TempTimelineSelectionSignal.subscribe((value) => {
        setTemp(value);
      });
      return () => {
        unsub();
      };
    }, []);

    return (
      <Carousel
        vertical={true}
        embla={[emblaRef, emblaApi]}
        slideStyle={{
          flex: '0 0 50%',
        }}
      >
        {items.map((item: any, i: number) => {
          return (
            <div
              key={item?.index + '' + i + 'asdasd'}
              className="timeline-dot-mobile d-flex "
              style={{ width: '100%' }}
            >
              <div className="timeline-left-mobile">
                <motion.span className="iconWrapper-mobile">
                  {getIconBySelectedTransport(item?.type)}
                </motion.span>
                <Line />
              </div>
              <CardComponent
                item={item}
                opened={temp}
                current={item?.index}
                type={`${getActionBySelectedTransport(item.type)} to`}
                handleCardSelect={() => {
                  TempTimelineSelectionSignal.value = item?.index;
                  setTimeout(() => {
                    handleCardSelect(item?.index);
                  }, 100);
                  if (animationStateSignal.peek()?.state === 'idle') {
                    dispatch(ActionsCreator.setPlayPauseState(false));
                  }
                  setTimeout(() => {
                    collapseBottomSheet();
                  }, 500);
                }}
                i={i}
              />
            </div>
          );
        })}
      </Carousel>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.currentDate === nextProps.currentDate &&
      prevProps.step === nextProps.step
    );
  },
);

const CardComponent = memo(
  ({
    opened,
    current,
    item,
    handleCardSelect,
  }: {
    opened: number;
    current: number;
    item: any;
    type: any;
    handleCardSelect: () => void;
    i: number;
  }) => {
    const isOpen = opened === current;
    return (
      <AnimatePresence mode="sync">
        <motion.div
          layout
          // layoutId={`card${i}`}
          className={`card-mobile ${isOpen && 'card-mobile-active'}`}
          whileHover={{ scaleX: 1.05, cursor: 'pointer' }}
          onClick={() => handleCardSelect()}
        >
          <div className="card-header-mobile">
            <div className="d-flex w-full flex-column">
              <motion.span
                initial={{ fontSize: '0vw', opacity: 0, marginBottom: -2 }}
                animate={
                  !isOpen
                    ? { fontSize: '2.8vw', opacity: 0.7, marginBottom: -4 }
                    : { fontSize: '0vw', opacity: 0, marginBottom: 0 }
                }
                exit={{ maxHeight: 0, opacity: 0 }}
                transition={{ duration: 0.4, ease: 'easeInOut' }}
                className="typemainlabel-mobile"
              >
                {`${getActionBySelectedTransport(item?.type)} to `}
              </motion.span>

              <div className="d-flex">
                <div style={{ maxWidth: '70%' }}>
                  <b className="titlelabel-mobile">
                    <TextTicker>
                      {computeHeading(item?.type, item?.title)}
                    </TextTicker>
                  </b>
                </div>

                <span className="flex-1" />

                {item?.duration && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={
                      opened !== current
                        ? { height: 'auto', opacity: 0.7 }
                        : { height: 0, opacity: 0 }
                    }
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.4, ease: 'easeInOut' }}
                    className="d-flex flex-grow durationlabel-mobile justify-content-center align-items-center"
                  >
                    {item?.duration}
                  </motion.div>
                )}
              </div>
            </div>
          </div>
          {
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={
                opened === current
                  ? { height: 'auto', opacity: 1 }
                  : { height: 0, opacity: 0 }
              }
              exit={{ y: -100, height: 0, translateY: -100, opacity: 0 }}
              transition={{ duration: 0.4, ease: 'easeInOut' }}
              className="card-body-mobile"
            >
              <div className="card-info-mobile">
                <div className="flex-1 d-flex flex-column">
                  <b className="placelabel-mobile">{item.from}</b>
                  <span className="timelabel-mobile">{item.departure}</span>
                </div>
                <div className="flex-1 d-flex flex-column justify-content-center align-items-center px-2">
                  {opened === current && <AnimatedProgress />}

                  <span className="timelabel-mobile mb-2">{item.duration}</span>
                </div>
                <div className="flex-1 d-flex flex-column">
                  <b className="placelabel-mobile">{item.to}</b>
                  <span className="timelabel-mobile">{item.arrival}</span>
                </div>
              </div>
            </motion.div>
          }
        </motion.div>
      </AnimatePresence>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.opened === nextProps.opened;
  },
);

export const AnimatedProgress = memo(() => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);

    const unsubscribe = durationSignal.subscribe(setProgress);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <motion.progress
      className="progress mb-2 mt-4"
      value={progress}
      max="1"
    ></motion.progress>
  );
});
