import { useSignalEffect } from '@preact/signals-react/runtime';
import { StaticTravelVisualizer } from './StaticTravelVisualizer';
import { bottomSheetOverviewData } from '~/components/ViewTravel/MobileFooter/BottomSheet';
import { animate, AnimationPlaybackControls } from 'framer-motion';
import { useEffect, useState } from 'react';
import { vizualizeButtonSignal } from '~/components/ViewTravel/MobileFooter/DaysHeader/DaysHeader';
import { isDiscoverModalVisible } from '~/components/ViewTravel/StatsOverlay';
import { RootState, useSelector } from '~/redux/reducers';
import { pointsSignal } from '~/components/ViewTravel/MobileFooter/PointsHeader';

export const useMarkersAnimationController = (
  travelVisualizer: React.MutableRefObject<
    StaticTravelVisualizer | null | undefined
  >,
) => {
  const [initiated, setInitiated] = useState(false);
  const [isInfiniteSequenceAnimationOn, setInfiniteSequenceAnimationOn] =
    useState(true);
  const [singleMarkerAnimatedIndex, setSingleMarkerAnimatedIndex] = useState<
    number | null
  >(null);
  const isFetchHotelsAPIFlowOPened = useSelector(
      (state: RootState) =>
        state.CalendarOverlayReducers.isFetchHotelsAPIFlowOPened,
    );

  // Init the animation controller. It is guaranteed that the markers are loaded when the discover modal is closed.
  useSignalEffect(() => {
    if (!isDiscoverModalVisible.value && !initiated) {
      const map = travelVisualizer.current?.map;
      map?.isMoving()
        ? map?.once('moveend', () => setInitiated(true))
        : setInitiated(true);
    }
  });

  // Listen to the selected travel day signal and animate the marker shaking accordingly.
  useSignalEffect(() => {
    const selectedDayInfo = vizualizeButtonSignal.value;
    const selectedMarkerInfo = bottomSheetOverviewData.value;

    // Stop the shaking animation when
    // - select a day
    if (!!selectedDayInfo) {
      setTimeout(() => setInfiniteSequenceAnimationOn(false), 1000); // Wait for the camera move animation to finish.
    }

    // Launch the shaking animation when
    // - unselect a day (go to the general view)
    // - unselect a marker returning to the general view (not the day view)
    if (!selectedDayInfo && !selectedMarkerInfo) {
      setTimeout(() => setInfiniteSequenceAnimationOn(true), 2000); // Wait for the camera move animation to finish.
    }

    // Stop the shaking animation AND launch the bubbling animation when
    // - select a marker
    if (!!selectedMarkerInfo) {
      setSingleMarkerAnimatedIndex(selectedMarkerInfo.index);
      setTimeout(() => setInfiniteSequenceAnimationOn(false), 1000); // Wait for the camera move animation to finish.
    }

    // Cancel the bubbling animation when
    // - unselect a marker returning to the general view OR the day view.
    if (!selectedMarkerInfo) {
      setSingleMarkerAnimatedIndex(null);
    }
  });

  // Animation 1. Infinite markers shaking.
  useEffect(() => {
    if (isInfiniteSequenceAnimationOn && initiated) {
      console.log('Animation 1: Starting infinite markers shaking animations');

      const animations: AnimationPlaybackControls[] = [];
      const markers = travelVisualizer.current?.markers || [];

      markers.forEach((marker) => {
        const markerElementChild =
          marker.getElement().firstElementChild!.firstElementChild!; // The marker icon.

        const animation = animate(
          markerElementChild,
          {
            rotate: [0, 5, 0, -5, 0],
            transformOrigin: 'center bottom',
          },
          {
            delay: Math.random() * 2,
            duration: 0.5,
            ease: 'easeInOut',
            repeat: Infinity,
            repeatDelay: 3,
          },
        );

        animations.push(animation);
      });

      return () => {
        animations.forEach((animation) => animation.cancel());
      };
    }
  }, [isInfiniteSequenceAnimationOn, initiated]);

  // Animation 2. Selected marker bubbling.
  // Practically, we have a scenario when there are multiple markers on the same location.
  // When we select one of them, the last one should bubble over the others.
  useEffect(() => {
    if(!isFetchHotelsAPIFlowOPened){
    if (Number.isInteger(singleMarkerAnimatedIndex) && initiated) {
      const markers = travelVisualizer.current?.markers || [];
      const filteredMarkers = markers.filter(
        (item) => !item._element.classList.contains('middleMarker'), // There may be additional markers in Day mode.
      );
      const selectedMarkerLngLat =
        pointsSignal.value?.[singleMarkerAnimatedIndex!].location;
      const duplicateMarkerIndexes = filteredMarkers.reduce(
        (acc, marker, index) =>
          marker?._lngLat.lng === selectedMarkerLngLat[0] &&
          marker?._lngLat.lat === selectedMarkerLngLat[1]
            ? [...acc, index]
            : acc,
        [] as number[],
      );

      const allDuplicateMarkerIndexes = duplicateMarkerIndexes.slice(0, -1);
      const [lastDuplicateMarkerIndex] = duplicateMarkerIndexes.slice(-1);

      const lastDuplicateMarkerElement =
        filteredMarkers[lastDuplicateMarkerIndex]?.getElement(); // The marker container.

      if (lastDuplicateMarkerElement) {
        const animations: AnimationPlaybackControls[] = [];

        const hideAllDuplicateMarkerAnimations = allDuplicateMarkerIndexes.map(
          (index) =>
            animate(
              filteredMarkers[index].getElement().firstElementChild!
                .firstElementChild!, // The marker icon.
              { opacity: 0 },
              { duration: 0 },
            ),
        );

        const scaleLastDuplicateMarkerAnimation = animate(
          lastDuplicateMarkerElement.firstElementChild!.firstElementChild!, // The marker icon.
          { scale: 2, transformOrigin: 'center bottom' },
          { duration: 0.5, ease: 'anticipate' },
        );

        // ...This is a workaround how to make the marker bubble over the other markers.
        const moveLastDuplicateMarkerOnTopAnimation = animate(
          document.createElement('div'),
          { '--animatable-custom-value': [0, 1, 1] },
          {
            duration: 2,
            ease: 'easeOut',
            onUpdate: (latest) =>
              (lastDuplicateMarkerElement.style.zIndex =
                Math.round(latest).toString()),
          },
        );

        animations.push(
          ...hideAllDuplicateMarkerAnimations,
          scaleLastDuplicateMarkerAnimation,
          moveLastDuplicateMarkerOnTopAnimation,
        );

        return () => {
          // Reset the scale to default.
          duplicateMarkerIndexes.forEach((index) => {
            animate(
              filteredMarkers[index].getElement().firstElementChild!
                .firstElementChild!,
              { scale: 1 },
              { duration: 0.5 },
            );
          });

          // Reset the opacity to default.
          duplicateMarkerIndexes.forEach((index) => {
            animate(
              filteredMarkers[index].getElement().firstElementChild!,
              { opacity: 1 },
              { duration: 0 },
            );
          });

          // Cancel all animations.
          animations.forEach((animation) => animation.cancel());

          // Reset the z-index.
          lastDuplicateMarkerElement.style.zIndex = '';
        };
      }
      }  
    }
  }, [singleMarkerAnimatedIndex, initiated]);
};
