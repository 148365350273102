import React from 'react';
import './ImageWrapper.css';

export const ImageWrapper = ({
  src,
  className = '',
}: {
  src: string;
  className?: string;
}) => {
  return (
    <div className="image-wrapper">
      <img src={src} alt="" className={`image-wrapper-image ${className}`} />
    </div>
  );
};
