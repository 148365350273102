import rawData from '../data/tokyoData.json';
import { DiscoverButtons } from '../DiscoverMenu';

export const useGridData = (activeSection?: DiscoverButtons) => {
  if (!activeSection) {
    return rawData;
  }
  // @ts-ignore
  return rawData[activeSection];
};
