import { DiscoverButtons } from '../DiscoverMenu';
import { GridContent } from '../GridContent/GridContent';

export const GridContainer = ({
  activeButton,
  setActiveButton,
  dataToShow,
  handleShowStories,
}: {
  activeButton: DiscoverButtons;
  setActiveButton: (button: DiscoverButtons) => void;
  dataToShow: any;
  handleShowStories: (idx: number) => void;
}) => {
  return (
    <div className="discover-menu-content">
      {/* <div className="discover-menu-content--header">
        {activeButton === 'trips' ? (
          <div className="discover-menu-content--title active">Trip Plans</div>
        ) : (
          <>
            <div
              className={`discover-menu-content--title ${
                activeButton === 'activities' && 'active'
              }`}
              onClick={() => setActiveButton('activities')}
            >
              Activity
            </div>
            <div
              className={`discover-menu-content--title ${
                activeButton === 'restaurants' && 'active'
              }`}
              onClick={() => setActiveButton('restaurants')}
            >
              Restaurant
            </div>
          </>
        )}
      </div> */}
      <GridContent
        activeSection={activeButton}
        dataToShow={dataToShow}
        handleShowStories={handleShowStories}
      />
    </div>
  );
};
