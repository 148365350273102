import { TextField, Box } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { ClipboardEvent, useRef, useState } from 'react';
import { OTPType } from '~/types/signup';

interface OtpInputProps {
  control: Control<OTPType, any>;
  boxLength: number;
  submit: (otp: string) => void;
  loading: boolean;
}

const OtpInput: React.FC<OtpInputProps> = ({
  control,
  boxLength,
  submit,
  loading,
}) => {
  const [boxes, setBoxes] = useState<string[]>(new Array(boxLength).fill(''));
  const boxRefs = useRef<(HTMLInputElement | null)[]>(
    new Array(boxLength).fill(null),
  );

  const handleInput = (text: string, index: number): void => {
    if (loading) return; // Prevent input changes when loading

    if (/^\d{0,1}$/.test(text)) {
      const newBoxes = [...boxes];
      newBoxes[index] = text;
      setBoxes(newBoxes);
      const currentCode = newBoxes.join('');

      if (newBoxes.every((box) => box !== '')) {
        submit(currentCode);
      }

      const allBoxesCleared = newBoxes.every((box) => box === '');

      if (text === '' && index > 0) {
        boxRefs.current[index - 1]?.focus();
      } else if (index < boxLength - 1 && !allBoxesCleared) {
        boxRefs.current[index + 1]?.focus();
      } else if (allBoxesCleared) {
        boxRefs.current[0]?.focus();
      }
    }
  };

  const handleKeyPress = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (loading) return; // Prevent key press actions when loading

    if (event.key === 'Backspace' && index > 0) {
      const newBoxes = [...boxes];
      if (newBoxes[index] !== '') {
        newBoxes[index] = '';
        setBoxes(newBoxes);
      } else if (index > 0) {
        newBoxes[index - 1] = '';
        setBoxes(newBoxes);
        boxRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>): void => {
    if (loading) return; // Prevent paste actions when loading

    const pastedData = event.clipboardData
      .getData('text')
      .replace(/\D/g, '')
      .trim();
    event.preventDefault();

    if (pastedData) {
      const newBoxes = [...boxes];
      const chars = pastedData.split('');

      for (let i = 0; i < Math.min(chars.length, boxLength); i++) {
        newBoxes[i] = chars[i];
      }

      setBoxes(newBoxes);
      const otp = newBoxes.join('');

      if (newBoxes.every((box) => box !== '')) {
        submit(otp);
      }

      boxRefs.current[Math.min(chars.length, boxLength) - 1]?.focus();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        opacity: loading ? 0.6 : 1, // Dim the component when loading
        pointerEvents: loading ? 'none' : 'auto', // Disable interactions when loading
      }}
    >
      <Box display="flex" gap={1} justifyContent="center">
        {Array.from({ length: boxLength }).map((_, index) => (
          <Controller
            key={index}
            name={`otp.${index}` as const}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                inputRef={(ref) => (boxRefs.current[index] = ref)}
                variant="outlined"
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: 'center',
                    fontSize: '2rem',
                    padding: '0px ',
                  },
                }}
                sx={{
                  width: '40px',
                  height: '56px',
                  '& .MuiOutlinedInput-root': {
                    height: '100%',
                  },
                }}
                type="text"
                value={boxes[index]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInput(e.target.value, index)
                }
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyPress(index, event)
                }
                onPaste={handlePaste}
                disabled={loading} // Disable each TextField when loading
              />
            )}
          />
        ))}
      </Box>
    </Box>
  );
};

export default OtpInput;
