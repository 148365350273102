import { store } from "../store";
import * as Types from "../actionTypes";

export const setIsHotelButtonClicked = (value: boolean) =>
    store.dispatch({
        ...store.getState().HotelSwiperReducers,
        type: Types.SET_IS_HOTEL_BUTTON_CLICKED,
        isHotelButtonClicked: value,
    });

export const setIsHotelButtonTouched = (value: boolean) =>
    store.dispatch({
        ...store.getState().HotelSwiperReducers,
        type: Types.SET_IS_HOTEL_BUTTON_TOUCHED,
        isHotelButtonTouched: value,
    });

export const setMedianPrice = (value: number) =>
    store.dispatch({
        ...store.getState().HotelSwiperReducers,
        type: Types.SET_MEDIAN_PRICE,
        medianPrice: value,
    });

const HotelSwiperActions = {
    setIsHotelButtonClicked,
    setIsHotelButtonTouched,
    setMedianPrice,
};

export default HotelSwiperActions;
