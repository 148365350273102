import { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { EmblaCarouselType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { EmblaOptionsType } from 'embla-carousel'
import './TimePickerSlider.css'
import { RefreshTwoTone, SwapCallsTwoTone, SwapHorizontalCircle } from '@mui/icons-material'

type PropType1 = {
    loop?: EmblaOptionsType['loop']
    onChange: (value: string) => void
}

function generateTimeSlots(intervalMinutes = 5) {
    const times = [];
    let hour = 0;
    let minute = 0;

    for (let i = 0; i < (12 * 60) / intervalMinutes; i++) { // Only 12 hours (no AM/PM switch here)
        const formattedHour = hour;
        const formattedMinute = minute.toString().padStart(2, '0');

        times.push(`${formattedHour}:${formattedMinute}`);

        // Increment the time
        minute += intervalMinutes;
        if (minute >= 60) {
            minute = 0;
            hour += 1;
            if (hour > 12) hour = 1;  // Reset hour after 12 for 12-hour format
        }
    }

    return times;
}



const TimePickerSlider: React.FC<PropType1> = (props) => {
    const { loop } = props
    const [selectedTime, setSelectedTime] = useState('8:00')
    const [ampmstate, setampmstate] = useState('AM')
    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            perspective: '1000px',
            maxWidth: '30rem',
            margin: 'auto',
            flexDirection: 'column',
            background: '#333333',
        }}>
            <h3 style={{
                color: 'white',
                position: 'absolute',
                backgroundColor: 'rgba(40,40,40,0.5)',
                padding: '10px 30px',
                top: '0px',
                fontSize: 'min(1.5rem, 8vw)',
                zIndex: 99999,
            }}>
                <b>

                    Pick a Start Time

                </b>
            </h3>
            <div style={{ flex: 1, flexGrow: 1 }} className="embla_p2pai_time_picker_slider">
                <IosPickerItem
                    slideCount={224}
                    perspective="left"
                    loop={false}
                    onChange={(value: string) => { setSelectedTime(value) }}
                    label={<span >{ampmstate} <SwapHorizontalCircle

                        fontSize={'inherit'} color='primary' onClick={() => { setampmstate(ampmstate === 'AM' ? 'PM' : 'AM') }} /></span>}
                />
            </div>

            <div
                style={{
                    textAlign: 'center',
                    width: '100%',
                    padding: '20px 40px',
                    background: '#FE7138',
                    color: 'white', cursor: 'pointer'
                }}
                onClick={() => { props.onChange(`${selectedTime} ${ampmstate}`) }}
            >
                Done
            </div>




        </div>
    )
}

export default TimePickerSlider

const CIRCLE_DEGREES = 360
const WHEEL_ITEM_SIZE = 64
const WHEEL_ITEM_COUNT = 18
const WHEEL_ITEMS_IN_VIEW = 4

export const WHEEL_ITEM_RADIUS = CIRCLE_DEGREES / WHEEL_ITEM_COUNT
export const IN_VIEW_DEGREES = WHEEL_ITEM_RADIUS * WHEEL_ITEMS_IN_VIEW
export const WHEEL_RADIUS = Math.round(
    WHEEL_ITEM_SIZE / 2 / Math.tan(Math.PI / WHEEL_ITEM_COUNT)
)

const isInView = (wheelLocation: number, slidePosition: number): boolean =>
    Math.abs(wheelLocation - slidePosition) < IN_VIEW_DEGREES

const setSlideStyles = (
    emblaApi: EmblaCarouselType,
    index: number,
    loop: boolean,
    slideCount: number,
    totalRadius: number
): void => {
    const slideNode = emblaApi.slideNodes()[index]
    const wheelLocation = emblaApi.scrollProgress() * totalRadius
    const positionDefault = emblaApi.scrollSnapList()[index] * totalRadius
    const positionLoopStart = positionDefault + totalRadius
    const positionLoopEnd = positionDefault - totalRadius

    let inView = false
    let angle = index * -WHEEL_ITEM_RADIUS

    if (isInView(wheelLocation, positionDefault)) {
        inView = true
    }

    if (loop && isInView(wheelLocation, positionLoopEnd)) {
        inView = true
        angle = -CIRCLE_DEGREES + (slideCount - index) * WHEEL_ITEM_RADIUS
    }

    if (loop && isInView(wheelLocation, positionLoopStart)) {
        inView = true
        angle = -(totalRadius % CIRCLE_DEGREES) - index * WHEEL_ITEM_RADIUS
    }

    if (inView) {

        slideNode.style.opacity = '1'
        slideNode.style.transform = `translateY(-${index * 100
            }%) rotateX(${angle}deg) translateZ(${WHEEL_RADIUS}px)`
    } else {
        slideNode.style.opacity = '0'
        slideNode.style.transform = 'none'
    }
}

export const setContainerStyles = (
    emblaApi: EmblaCarouselType,
    wheelRotation: number
): void => {
    emblaApi.containerNode().style.transform = `translateZ(${WHEEL_RADIUS}px) rotateX(${wheelRotation}deg)`
}

type PropType = {
    loop?: boolean
    label: JSX.Element
    slideCount: number
    perspective: 'left' | 'right',
    onChange: (value: string) => void
}

export const IosPickerItem: React.FC<PropType> = (props) => {
    const timeSlots = useMemo(() => generateTimeSlots(15), [])
    const slideCount = timeSlots.length

    const { perspective, label, loop = false } = props
    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop,
        axis: 'y',
        dragFree: true,
        containScroll: false,
        watchSlides: false,
        startIndex: timeSlots.indexOf('8:00'),
    })
    const rootNodeRef = useRef<HTMLDivElement>(null)
    const totalRadius = slideCount * WHEEL_ITEM_RADIUS
    const rotationOffset = loop ? 0 : WHEEL_ITEM_RADIUS
    const slides = Array.from(Array(slideCount).keys())
    const inactivateEmblaTransform = useCallback(
        (emblaApi: EmblaCarouselType) => {
            if (!emblaApi) return
            const { translate, slideLooper } = emblaApi.internalEngine()
            translate.clear()
            translate.toggleActive(false)
            slideLooper.loopPoints.forEach(({ translate }) => {
                translate.clear()
                translate.toggleActive(false)
            })
        },
        []
    )

    const rotateWheel = useCallback(
        (emblaApi: EmblaCarouselType) => {
            const rotation = slideCount * WHEEL_ITEM_RADIUS - rotationOffset
            const wheelRotation = rotation * emblaApi.scrollProgress()
            setContainerStyles(emblaApi, wheelRotation)
            emblaApi.slideNodes().forEach((_, index) => {
                _.style.color = 'white'
                setSlideStyles(emblaApi, index, loop, slideCount, totalRadius)
            })
            // const slidesInVieeLength = emblaApi.slidesInView().length
            // const centerSlideIndex = emblaApi.slidesInView()[Math.floor(slidesInVieeLength / 2)]
            emblaApi.slideNodes()[emblaApi.selectedScrollSnap()].style.color = '#FE7138'
            props.onChange(timeSlots[emblaApi.selectedScrollSnap()])
        },
        [slideCount, rotationOffset, totalRadius]
    )

    useEffect(() => {
        if (!emblaApi) return

        emblaApi.on('pointerUp', (emblaApi) => {
            const { scrollTo, target, location } = emblaApi.internalEngine()
            const diffToTarget = target.get() - location.get()
            const factor = Math.abs(diffToTarget) < WHEEL_ITEM_SIZE / 2.5 ? 10 : 0.1
            const distance = diffToTarget * factor
            scrollTo.distance(distance, true)
        })

        emblaApi.on('scroll', rotateWheel)

        emblaApi.on('reInit', (emblaApi) => {
            inactivateEmblaTransform(emblaApi)
            rotateWheel(emblaApi)
        })

        inactivateEmblaTransform(emblaApi)
        rotateWheel(emblaApi)
    }, [emblaApi, inactivateEmblaTransform, rotateWheel])

    return (
        <div className="embla__ios-picker_p2pai_time_picker_slider">
            <div className="embla__ios-picker__scene_p2pai_time_picker_slider" ref={rootNodeRef}>
                <div
                    className={`embla__ios-picker__viewport_p2pai_time_picker_slider embla__ios-picker__viewport--perspective-${perspective}_p2pai_time_picker_slider`}
                    ref={emblaRef}
                >
                    <div className="embla__ios-picker__container_p2pai_time_picker_slider">
                        {timeSlots.map((_, index) => (
                            <div

                                className="embla__ios-picker__slide_p2pai_time_picker_slider" key={index}>
                                <span className="embla__ios-picker__slide__hour_p2pai_time_picker_slider">
                                    {_.split(':')[0]}
                                </span>
                                <span className='embla__ios-picker__slide__colon_p2pai_time_picker_slider'>
                                    :
                                </span>
                                <span className="embla__ios-picker__slide__minute_p2pai_time_picker_slider">{_.split(':')[1]}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="embla__ios-picker__label_p2pai_time_picker_slider">
                {label}
            </div>
        </div>
    )
}
