import { store } from "../store";
import * as Types from "../actionTypes";

export const setIsCalendarOverlayOpened = (value: boolean) =>
    store.dispatch({
        ...store.getState().CalendarOverlayReducers,
        type: Types.SET_IS_CALENDAR_OVERLAY_OPENED,
        isCalendarOverlayOpened: value,
    });

export const setIsFetchHotelsAPIFlowOPened = (value: boolean) =>
    store.dispatch({
        ...store.getState().CalendarOverlayReducers,
        type: Types.SET_IS_FETCH_API_FLOW_OPENED,
        isFetchHotelsAPIFlowOPened: value,
    });

export const setIsMapTapped = (value: boolean) =>
    store.dispatch({
        ...store.getState().CalendarOverlayReducers,
        type: Types.SET_IS_MAP_TAPPED,
        isMapTapped: value,
    });

export const setCoordinateLatitude = (value: number | null) =>
    store.dispatch({
        ...store.getState().CalendarOverlayReducers,
        type: Types.GET_MAP_COORDINATE_LATITUDE,
        coordinateLatitude: value,
    });

export const setCoordinateLongitude = (value: number | null) =>
    store.dispatch({
        ...store.getState().CalendarOverlayReducers,
        type: Types.GET_MAP_COORDINATE_LONGITUDE,
        coordinateLongitude: value,
    });

export const setIsAPIResponseEmpty = (value: number) =>
    store.dispatch({
        ...store.getState().CalendarOverlayReducers,
        type: Types.SET_IS_API_RESPONSE_EMPTY,
        isAPIResponseEmpty: value,
    });
    
    export const setOverviewButtonClicked = (value: boolean) =>
    store.dispatch({
        ...store.getState().CalendarOverlayReducers,
        type: Types.SET_IS_OVERVIEW_BUTTON_CLICKED,
        isOverViewButtonClicked: value,
    });

    export const setDepartureDate = (value: string | null) =>
    store.dispatch({
        ...store.getState().CalendarOverlayReducers,
        type: Types.SET_DEPARTURE_DATE,
        departureDate: value,
    });

    export const setReturnDate = (value: string | null) =>
    store.dispatch({
        ...store.getState().CalendarOverlayReducers,
        type: Types.SET_RETURN_DATE,
        returnDate: value,
    });

const CalendarOverlayActions = {
    setIsCalendarOverlayOpened,
    setIsFetchHotelsAPIFlowOPened,
    setIsMapTapped,
    setCoordinateLatitude,
    setCoordinateLongitude,
    setIsAPIResponseEmpty,
    setOverviewButtonClicked,
    setDepartureDate,
    setReturnDate
};

export default CalendarOverlayActions;
