import React, { createContext, FC, ReactNode, useContext, useRef } from 'react';
import { Marker } from 'maplibre-gl';

interface MarkerContextType {
  markers: React.MutableRefObject<Marker[]>;
  addMarker: (marker: Marker) => void;
  clearMarkers: () => void;
}

const MarkerContext = createContext<MarkerContextType | null>(null);

export const MarkerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const markers = useRef<Marker[]>([]);

  const addMarker = (marker: Marker) => {
    markers.current.push(marker);
  };

  const clearMarkers = () => {
    markers.current.forEach((marker) => marker.remove());
    markers.current = [];
  };

  return (
    <MarkerContext.Provider value={{ markers, addMarker, clearMarkers }}>
      {children}
    </MarkerContext.Provider>
  );
};

export const useMarkerContext = () => {
  const context = useContext(MarkerContext);
  if (!context)
    throw new Error('useMarkerContext must be used within a MarkerProvider');
  return context;
};
