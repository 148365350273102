import React, { useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  Typography,
  IconButton,
  Button,
  Stack,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import './hotelDrawerStyles.css';

interface HotelDrawerProps {
  open: boolean;
  onClose: (e: React.SyntheticEvent) => void;
  hotelName: string;
  rating: number;
  hotelPriceInclusive: number;
  imgSrc: string;
  checkInTime: string;
  hotelBookingURL: string;
  hotelDescription: string;
  hotelPricePerNight: number;
  noOfNights: number;
}

const HotelDrawer: React.FC<HotelDrawerProps> = ({
  open,
  onClose,
  hotelName,
  rating,
  imgSrc,
  checkInTime,
  hotelBookingURL,
  hotelDescription,
  hotelPricePerNight,
}) => {
  const [hotelDrawerWidth, setHotelDrawerWidth] = useState('100%');
  const [hotelDrawerHeight, setHotelDrawerHeight] = useState('90vh');
  checkInTime = dayjs(checkInTime, 'HH:mm').format('hh:mm A');
  const dispatch = useDispatch();

  useEffect(() => {
    const handleWindowResize = () => {
      const width = window.innerWidth;
      if (width < 770) {
        setHotelDrawerWidth('100%');
        setHotelDrawerHeight('90vh');
      } else if (width <= 1024) {
        setHotelDrawerWidth('50vw');
        setHotelDrawerHeight('70vh');
      } else {
        setHotelDrawerWidth('35vw');
        setHotelDrawerHeight('65vh');
      }
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <Drawer
      className="hotel-drawer-container"
      anchor="bottom"
      open={open}
      onClose={(e: React.SyntheticEvent) => {
        dispatch(ActionsCreator.setIsCalendarOverlayOpened(false));
        dispatch(ActionsCreator.setIsFetchHotelsAPIFlowOPened(true));
        onClose(e);
      }}
      sx={{
        '.MuiPaper-root': {
          height: hotelDrawerHeight,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundColor: 'white !important',
          width: hotelDrawerWidth,
          margin: 'auto',
        },
        '.MuiPaper-root::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box p={2} sx={{ mx: 'auto' }}>
        {/* Close Button */}
        <Box
          display="flex"
          justifyContent="flex-start"
          className="hotel-drawer-close-icon-container"
        >
          <IconButton
            className="hotel-drawer-close-icon"
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
              dispatch(ActionsCreator.setIsCalendarOverlayOpened(false));
              dispatch(ActionsCreator.setIsFetchHotelsAPIFlowOPened(true));
              onClose(e);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Hotel Info */}
        <Stack
          className="hotel-drawer-top-container"
          alignItems="center"
          spacing={2}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            {/* Hotel Icon */}
            <Box className="hotel-drawer-hotel-icon-container">
              <img
                className="hotel-drawer-hotel-icon-image"
                src="./icons/hotelIcon.svg"
                alt="hotel"
              />
            </Box>

            {/* Hotel Name */}
            <Typography className="hotel-drawer-hotel-name">
              {hotelName}
            </Typography>
          </Box>

          {/* Hotel Description Short */}
          <Typography className="hotel-drawer-hotel-description">
            {hotelDescription}
          </Typography>

          {/* Rating and Price */}
          <Stack
            className="hotel-drawer-rating-price-buttons"
            direction="row"
            spacing={1}
            sx={{
              mb: 1,
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Button className="hotel-drawer-rating-button">
              {rating} <span style={{ marginTop: '-3px' }}>⭐</span>
            </Button>
            <Button className="hotel-drawer-hotel-price-per-night">
              ~{'US$'}
              {Math.round(hotelPricePerNight)}
              <span
                style={{
                  textTransform: 'lowercase',
                }}
              >
                &nbsp;a night (+ taxes)
              </span>
            </Button>
          </Stack>

          {/* Booking Button */}
          <Button
            className="hotel-drawer-inclusive-price-button"
            variant="contained"
          >
            <a
              href={hotelBookingURL}
              style={{ textDecoration: 'none', color: 'white' }}
              target="_blank"
            >
              View on Booking.com
            </a>
          </Button>
        </Stack>

        {/* Hotel Image and Check-In Time */}
        <Stack
          className="hotel-drawer-image-checkin-buttons"
          direction="row"
          justifyContent="center"
          spacing={2}
          mt={2}
        >
          <Box className="hotel-drawer-hotel-image-container">
            <img
              className="hotel-drawer-hotel-image"
              src={imgSrc}
              alt="hotel"
            />
          </Box>
          <Button className="hotel-drawer-checkin-time" color="primary">
            Check-in at {checkInTime}
          </Button>
        </Stack>

        {/* Hotel Description Long */}
        <Typography
          className="hotel-drawer-hotel-description-main"
          variant="body2"
          color="text.secondary"
          mt={4}
          textAlign="center"
        >
          {hotelDescription}
        </Typography>
        <Divider
          className="hotel-drawer-divider"
          sx={{
            width: '50vw',
            height: '4px',
            mt: 5,
            mx: 'auto',
            backgroundColor: 'gray',
          }}
        ></Divider>
      </Box>
    </Drawer>
  );
};

export default HotelDrawer;
