import React, { useState, ChangeEvent, useEffect } from 'react';

import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  FormControl,
  InputAdornment,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  Slide,
  DialogContent,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import useStyles from '../styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from '~/redux/store';
import ActionsCreator from '~redux/actions';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';
import { useAuth } from '~/managers/AuthContext';
import Logo from '~/components/Logo';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import VerifyEmailOtpModal from './OtpModal/VerifyEmailOtpModal';

/**
 * Component for user login.
 * @component
 * @returns {JSX.Element} Rendered Login component.
 */
function LoginForm({
  open,
  onClose,
  returnHome = false,
}: {
  open: boolean;
  onClose: () => void;
  returnHome?: boolean;
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setErrorMessage] = useState('');
  const { signIn, signInWithGoogle } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);

  const location = useLocation();
  const isMobile = useIsMobile();

  /**
   * Handles email input change.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   * @function
   */
  const handleEmailChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setEmail(event.target.value);
  };

  /**
   * Handles password input change.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   * @function
   */
  const handlePasswordChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setPassword(event.target.value);
  };

  /**
   * @function
   * Handles user sign-in.
   */
  const handleSignIn = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Email must follow this regex pattern

    // Check if email and password are not empty
    if (email !== '' && password !== '') {
      // Validate email format
      if (!emailRegex.test(email)) {
        setErrorMessage('Invalid email address'); // Set error message for invalid email
        openSnackbar(); // Open snackbar to show error message
        return; // Exit the function
      }

      setLoading(true); // Set loading state to true

      // Attempt to sign in the user
      signIn({ email, password })
        .then((result) => {
          // Check if email is not confirmed
          if (result.message === 'Error signing in: Email not confirmed') {
            setOtpOpen(true);
            dispatch(ActionsCreator.setVerificationEmailState(email)); // Dispatch action to set verification email state
            setLoading(false); // Set loading state to false
          }

          // Check if sign-in was successful
          if (result.data) {
            setLoading(false); // Set loading state to false
            if (returnHome) {
              navigate('/home'); // Navigate to homepage
            } else {
              onClose();
            }
          }

          // Check if there was an error during sign-in
          if (result.error) {
            setLoading(false); // Set loading state to false
            setErrorMessage(result.message); // Set error message
            openSnackbar(); // Open snackbar to show error message
          }
        })
        .catch((error) => {
          console.error(error); // Log error
          setLoading(false); // Set loading state to false in case of catch
        });
    }
  };

  /**
   * @function
   * Handles user sign-in with Google.
   */
  const handleSignInWithGoogle = () => {
    signInWithGoogle()
      .then((result) => {
        console.log(result.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /**
   * @function
   * Handles user sign-in.
   */
  const handleForgotPassword = () => {
    navigate('/passwordreset');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  /**
   * @function
   * Navigates to the signup page.
   */
  const handleSignUpClick = () => {
    navigate('/signup');
  };

  /**
   * @function
   * Opens the snackbar.
   */
  const openSnackbar = () => {
    setSnackbarOpen(true);
  };

  /**
   * @function
   * Closes the snackbar.
   */
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  /**
   * useEffect for the dialog to appear after email confirmation.
   */
  useEffect(() => {
    const url = new URL(window.location.href); // Create a URL object
    const params = new URLSearchParams(url.search); // Extract query string params
    const hasErrorInQuery = params.has('error');
    const errorFragmentParams = new URLSearchParams(url.hash.slice(1));
    const hasErrorInFragment = errorFragmentParams.has('error');

    if (params.get('param1') === 'value1') {
      const hasError = hasErrorInQuery || hasErrorInFragment; // Check for error in either query or fragment
      if (hasError) {
        setErrorStatus(true);
      }
      setOpenDialog(true);
    }
  }, [location.search]);

  function getIsInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  function isWebView() {
    const htmlEl = document.getElementsByTagName('html')[0];
    const bodyEl = document.getElementsByTagName('body')[0];

    const oldHtmlHeight = htmlEl.style.height;
    const oldBodyHeight = bodyEl.style.height;

    htmlEl.style.height = '100vh';
    bodyEl.style.height = '100%';

    const webViewMode =
      document.documentElement.clientHeight ===
      document.documentElement.scrollHeight;

    // restore height
    htmlEl.style.height = oldHtmlHeight;
    bodyEl.style.height = oldBodyHeight;

    return webViewMode;
  }

  const props = {
    email: 'email',
    open: otpOpen,
    close: () => setOtpOpen((prev) => !prev),
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen sx={{ bgcolor: '#fff' }}>
      <DialogContent
        sx={{
          padding: 0,
          bgcolor: '#fff',
        }}
      >
        <form>
          <VerifyEmailOtpModal {...props} />

          <Box>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={4000}
              onClose={closeSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert severity={error ? 'warning' : 'success'}>
                {' '}
                {error || 'Account created successfully'}.
              </Alert>
            </Snackbar>
            <Grid container className={classes.outergrid}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={5}
                xl={5}
                className={classes.leftGrid}
              >
                <div className={classes.outerdiv}>
                  {!returnHome && (
                    <Box
                      onClick={onClose}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 12H21M10 19L3 12L10 19ZM3 12L10 5L3 12Z"
                          stroke="#1F5460"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Box>
                  )}
                  <Box className={classes.logoContainer}>
                    <Logo />
                  </Box>
                  <FormControl defaultValue="" required>
                    <Box>
                      <Typography className={classes.statement}>
                        Login to your account
                      </Typography>
                      <Typography className={classes.email}>Email</Typography>
                      <Box className={classes.textFieldBox}>
                        <TextField
                          className={classes.textField}
                          onChange={handleEmailChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <MailOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                          required
                          value={email}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault(); // Prevent the default form submission
                              handleSignIn(); // Call the signIn function
                            }
                          }}
                        />
                      </Box>
                      <Typography className={classes.password}>
                        Password
                      </Typography>
                      <Box className={classes.textFieldBox}>
                        <TextField
                          className={classes.textField}
                          onChange={handlePasswordChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                          type="password"
                          required
                          value={password}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault(); // Prevent the default form submission
                              handleSignIn(); // Call the signIn function
                            }
                          }}
                        />
                      </Box>
                      <Button
                        className={classes.forgot}
                        variant="text"
                        color="primary"
                        onClick={handleForgotPassword}
                      >
                        Forgot Password?
                      </Button>
                      <Box className={classes.buttonBox}>
                        <LoadingButton
                          loading={loading}
                          // loadingPosition="end"
                          className={classes.login}
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={handleSignIn}
                          disabled={email === '' || password === ''}
                        >
                          Login
                        </LoadingButton>

                        {(!isWebView() || !isMobile) && (
                          <>
                            <Typography className={classes.or}>OR</Typography>

                            <Button
                              className={classes.google}
                              variant="contained"
                              color="primary"
                              onClick={handleSignInWithGoogle}
                            >
                              {/* Use the Google logo as the icon */}
                              <img
                                src="/icons/google.svg"
                                style={{ margin: 10 }}
                                alt="Map 1"
                              />
                              Continue with Google
                            </Button>
                          </>
                        )}

                        <Box className={classes.signupButtonBox}>
                          <Typography className={classes.signup}>
                            Don't have an account?
                            <Button
                              className={classes.signupButton}
                              variant="text"
                              color="primary"
                              onClick={handleSignUpClick}
                            >
                              Sign Up
                            </Button>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </FormControl>
                </div>
              </Grid>

              <Grid item lg={7} xl={7}>
                <div className={classes.imageDiv}>
                  <img
                    className={classes.mapImg}
                    src="/icons/mapImageLogin.png"
                    alt="Map 1"
                  />
                </div>
              </Grid>
            </Grid>
            <Dialog
              open={openDialog}
              TransitionComponent={Slide}
              onClose={handleCloseDialog}
              PaperProps={{ style: { borderRadius: 14 } }}
            >
              <div
                style={{
                  padding: '20px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <Typography className={classes.thanks}>
                  {errorStatus ? 'Error!' : 'Welcome to the Early Access'}
                </Typography>
                <Typography className={classes.details}>
                  {errorStatus
                    ? 'Link is expired'
                    : 'Account has been created successfully'}
                </Typography>
                <DialogActions sx={{ width: '100%' }}>
                  <Button
                    className={classes.continue}
                    onClick={handleCloseDialog}
                    color="primary"
                    variant="contained"
                  >
                    {errorStatus ? 'Close' : 'Go to Login'}
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default LoginForm;
