import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { signal } from '@preact/signals-core';
import { useSignalEffect } from '@preact/signals-react/runtime';
import './style.css';
import { useAuth } from '~/managers/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import { RootState } from '~/redux/reducers';
import Navbar from '~/components/NavbarNew';
import { showMakeYourOwn } from '../ViewTravel/MakeYourOwnModal';
import useIsAuth from '../ViewTravel/useIsAuth';
import OverviewMenuContent from './OverviewMenuContent';
import {
  selectCurrentOverviewData,
  selectIsStoriesOpened,
} from '~/redux/selectors/tripInfoSelectors';
import OverviewContainer from '../ViewTravel/MobileFooter/OverviewDataComponent/OverviewContainer';
import TripStories from './TripOverview/TripStories';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';
import { shouldShowMakeOwnPopup } from '~/map/ViewTravel/MakeYourOwnPopup';
import { showStoriesSignal } from '../ViewTravel/StoriesOverlay';
import ROUTES from '~/routes';
import { useLocation } from 'react-router-dom';

export const showOverviewMenu = signal(false);

export enum ETabIndexes {
  TRIP = 'Trip',
  ITINERARY = 'Itinerary',
}

const OverviewMenu = () => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [tabIndex, setTabIndex] = useState<ETabIndexes>(ETabIndexes.TRIP);
  const [menuStyles, setMenuStyles] = useState({});
  const location = useLocation()

  const isMobile = useIsMobile();

  const { signOut } = useAuth();

  const currentOverviewData = useSelector(selectCurrentOverviewData);
  const isStoriesOpened = useSelector(selectIsStoriesOpened);

  useSignalEffect(() => {
    setIsVisible(showOverviewMenu.value);
  });

  useSignalEffect(() => {
    if (showStoriesSignal.value) {
      setMenuStyles({ position: 'relative', zIndex: -1 });
    } else {
      setMenuStyles({});
    }
  });

  const userName: string = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );
  const profilePictureURL: string = useSelector(
    (state: RootState) => state.MapReducers.profileImgURL,
  );
  const selectedProfilePicture = '/icons/croppedLogoVizualTravel.svg';

  const isAuthenticated = useIsAuth();

  const actionDelay = (action: () => void) => {
    setTimeout(() => {
      showOverviewMenu.value = false;
      action?.();
    }, 250);
  };

  const handleBackButtonClick = () => {
    if (currentOverviewData) {
      dispatch(ActionsCreator.setCurrentOverviewData(null));
    } else {
      showOverviewMenu.value = false;
    }
  };

  const initialAnimation = isMobile
    ? { y: -window.innerHeight }
    : { y: -window.innerHeight, x: '-50%' };
  const animation = isMobile ? { y: 0 } : { y: 0, x: '-50%' };
  const exit = isMobile
    ? { y: -window.innerHeight }
    : { y: -window.innerHeight, x: '-50%' };

  return (
    <div className="overview-modal-menu" style={menuStyles}>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            transition={{
              duration: 0.25,
              bounce: false,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="overview-menu-backdrop"
            style={{ cursor: 'pointer', zIndex: 9999 }}
            onClick={() => {
              if (!isMobile) {
                showOverviewMenu.value = false;
              }
            }}
          />
        )}
      </AnimatePresence>
      <TripStories
        isOpened={isStoriesOpened && location.pathname === ROUTES.VIEWTRAVEL.path}
        hanldeClose={() => dispatch(ActionsCreator.setIsStoriesOpened(false))}
      />
      <AnimatePresence>
        {isVisible && (
          <motion.div
            transition={{
              ease: 'easeInOut',
              duration: 0.4,
              type: 'spring',
              bounce: false,
            }}
            initial={initialAnimation}
            animate={animation}
            exit={exit}
            className={`overview-menu-container ${isMobile ? '' : 'desktop'}`}
          >
            <Navbar
              onBackButtonClick={handleBackButtonClick}
              style={{ margin: '0 auto' }}
            />
            {currentOverviewData ? (
              <OverviewContainer data={currentOverviewData} />
            ) : (
              <>
                <div className="tab-buttons">
                  <div
                    className={`custom-badge pointer ${
                      tabIndex === ETabIndexes.TRIP ? 'active' : ''
                    }`}
                    onClick={() => setTabIndex(ETabIndexes.TRIP)}
                  >
                    {ETabIndexes.TRIP}
                  </div>
                  <div
                    className={`custom-badge pointer ${
                      tabIndex === ETabIndexes.ITINERARY ? 'active' : ''
                    }`}
                    onClick={() => setTabIndex(ETabIndexes.ITINERARY)}
                  >
                    {ETabIndexes.ITINERARY}
                  </div>
                </div>
                <OverviewMenuContent tabIndex={tabIndex} />
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default OverviewMenu;
