import React, { useRef, useState } from 'react';
import { signal } from '@preact/signals-core';
import { isCalendarAnimating } from './DesktopFooter/CalendarLine';

import Marquee from 'react-fast-marquee';
import { DAYJS } from '~/map/ViewTravel';
import { TravelFormData } from '~/utility/models';
import moment, { Moment } from 'moment-timezone';
import { Story } from 'react-insta-stories/dist/interfaces';

const iconsWidth = window.innerWidth <= 1025 ? '5.5vw' : '32px';
const iconsHeight = window.innerWidth <= 1025 ? '5.5vw' : '32px';

export const durationSignal = signal<number>(0);
export const emptyAPIResponseSignal = signal<number>(0);
export const lastDepartureDateSignal = signal<Date | null>(null);
export const animationStateSignal = signal({ state: 'idle', calendarStep: -1 });
export const departureDatesSignal = signal<Date[]>([]);
export const activitiesSignal = signal<any[]>([]);
export const originStateSignal = signal<null | any>(null);
export const destinationStateSignal = signal<null | any>(null);
export const clickedIndexSignal = signal<number | undefined>(undefined);
export const clickedHotelButtonSignal = signal<boolean>(false);
export const touchedHotelButtonSignal = signal<boolean>(false);
export const openedCalendarOverlaySignal = signal<boolean>(false);
export const openedHotelsFetchAPIFlowSignal = signal<boolean>(false);
export const tappedOnMapSignal = signal<boolean>(false);
export const fetchedMedianPriceSignal = signal<number>(0);
export const clickedIndexDirection = signal<'fwd' | 'bwd'>('fwd');
export const tappedCoordinateLatitude = signal<number | null>(null);
export const tappedCoordinateLongitude = signal<number | null>(null);
export const categoryViewSignal = signal<{
  icon: string;
  label: string;
  place?: string;
}>({ icon: '', label: '' });
export const showPlayPauseButton = signal<boolean>(false);
export const showMainHeader = signal<boolean>(true);
export const loadStateProvokeSignal = signal<null | Date>(null);
export const endStateSignal = signal(false);
export const emptyStateSignal = signal(0);
export const imagesignal = signal<string[] | null>([]);
export const imagesInfoSignal = signal<{
  label?: string;
  category?: string;
} | null>(null);
export const openedImages = signal<string[]>([]);
export const clickedsetVizualizeTripButtonState = signal<boolean>(false);
export const openedList = signal<string[]>([]);
export const autoOpenImagesSignal = signal(0);
export const mobile4thImagePostionSignal = signal<string>('');
export const animationIsPlaying = signal<boolean>(false);
export const currentTravelTypeSignal = signal<string>('');
export const canMultiTransportDeclareArrival = signal<boolean>(false);
export const setOpenedList = (value: string) => {
  openedList.value = [...openedList.peek(), value];
};
export const setImagesBySignal = (
  images: string[],
  label?: string,
  category?: string,
) => {
  imagesignal.value = null;
  imagesInfoSignal.value = null;
  if (!images) return;
  imagesignal.value = images;
  imagesInfoSignal.value = { label, category };
};
export const provokeLoadState = async (date: Date) => {
  isCalendarAnimating.value = true;
  loadStateProvokeSignal.value = date;
};
export const provokeEndState = (bool: boolean) => {
  endStateSignal.value = bool;
};
export const provokeEmptyState = () => {
  emptyStateSignal.value = emptyStateSignal.peek() + 1;
};
export const provokeAutoOpenImages = () => {
  autoOpenImagesSignal.value = autoOpenImagesSignal.peek() + 1;
};

export const setAnimationStateSignal = (updater: any) => {
  const currentState = animationStateSignal.peek();
  const newState = updater(currentState);
  animationStateSignal.value = newState;
};

export const handleAnimationState = (state: any) => {
  if (state.hasOwnProperty('increment')) {
    setAnimationStateSignal((prev: any) => {
      if (prev.hasOwnProperty(state?.increment)) {
        return {
          ...prev,
          [state?.increment]:
            prev[state?.increment] !== undefined
              ? prev[state?.increment] + 1
              : 0,
        };
      } else {
        return {
          ...prev,
          [state?.increment]: 0,
        };
      }
    });

    return;
  }
  setAnimationStateSignal((prev: any) => ({
    ...prev,
    ...state,
  }));
};

export function setAnimationState(timeProgress: any) {
  let done = {
    depart: false,
    flyfor: false,
    arrival: false,
  };

  durationSignal.value = timeProgress;

  const animationState = animationStateSignal.peek() as any;

  const departRange = getDepartRangeByType(currentTravelTypeSignal?.peek()); // animationState?.type === 'plane' ? [0, 0.1] : [0, 0.025]
  const flyforRange = getFlyForRangeByType(currentTravelTypeSignal?.peek()); //animationState?.type === 'plane' ? [0.1, 0.2] : [0.2, 0.25]
  const arrivalRange = getArrivalRangeByType(currentTravelTypeSignal?.peek()); //animationState?.type === 'plane' ? [0.9, 0.95] : [0.8, 0.85]

  if (timeProgress > departRange[0] && timeProgress < departRange[1]) {
    if (!done.depart) {
      handleAnimationState({
        state: 'depart',
      });
      done.depart = true;
    }
  } else if (timeProgress > flyforRange[0] && timeProgress < flyforRange[1]) {
    if (!done.flyfor) {
      handleAnimationState({
        state: 'flyfor',
      });

      const currentIndex = animationStateSignal.peek()?.calendarStep;
      const travelDate = TravelDataSignal.peek();
      const images = travelDate?.[currentIndex]
        ?.selectedTransportImages as string[];
      setImagesBySignal(images, 'Transport', '');

      done.flyfor = true;
    }
  } else if (timeProgress > arrivalRange[0] && timeProgress < arrivalRange[1]) {
    if (!done.arrival) {
      if (canMultiTransportDeclareArrival.peek()) {
        handleAnimationState({
          state: 'arrival',
        });
        done.arrival = true;
      }
    }
  }
}

const getDepartRangeByType = (type: string) => {
  switch (type) {
    case 'plane':
      return [0, 0.1];
    case 'walk':
      return [0, 0.025];
    default:
      return [0, 0.025];
  }
};

const getFlyForRangeByType = (type: string) => {
  switch (type) {
    case 'plane':
      return [0.1, 0.2];
    case 'walk':
      return [0.2, 0.25];
    default:
      return [0.2, 0.25];
  }
};

const getArrivalRangeByType = (type: string) => {
  switch (type) {
    case 'plane':
      return [0.9, 0.95];
    case 'walk':
      return [0.8, 0.85];
    default:
      return [0.8, 0.85];
  }
};

const arrangeCategoryViewSignalData = (object: {
  icon: string;
  location: { label: string };
  category: string;
}): { icon: string; label: string; place: string } => {
  // split on the first space only
  const category = object?.category || '';
  const split = category.split(' ');
  const icon = split.shift();
  const label = formatCategory(category);
  if (!icon) return { icon: '', label: '', place: '' };
  return { icon, label, place: object?.location?.label };
};

export const getDestinationPointData = (clickedIndex?: number) => {
  const index = animationStateSignal.peek()?.calendarStep;
  const activities = activitiesSignal.peek() as any;

  if (clickedIndex || clickedIndex === 0) {
    const label =
      activities[clickedIndex]?.departure?.location?.label ||
      activities[index]?.departure?.location?.city;
    categoryViewSignal.value = arrangeCategoryViewSignalData(
      activities[clickedIndex]?.departure,
    );
    return { label };
  }

  if (activities[index]) {
    if (activities[index + 1]) {
      const thisArrivalAcitivty = activities[index]?.arrival;
      const nextDepartureActivity = activities[index + 1]?.departure;

      const duration = formatTimeDifference(
        thisArrivalAcitivty?.dateTime,
        nextDepartureActivity?.dateTime,
      );
      const activityType = thisArrivalAcitivty?.category;
      const label =
        thisArrivalAcitivty?.location?.label ||
        thisArrivalAcitivty?.location?.city;
      categoryViewSignal.value =
        arrangeCategoryViewSignalData(thisArrivalAcitivty);
      return {
        label,
        duration,
      };
    } else {
      categoryViewSignal.value = arrangeCategoryViewSignalData(
        activities[index]?.arrival,
      );
      return {
        label:
          activities[index]?.arrival?.location?.label ||
          activities[index]?.arrival?.location?.city,
      };
    }
  }
  return null;
};

export function useStateWithChangeCheck(initialValue: any) {
  const [value, setValue] = useState(initialValue);
  const prevValueRef = useRef(initialValue);

  const setCheckedValue = (newValue: any) => {
    if (prevValueRef.current !== newValue) {
      prevValueRef.current = newValue;
      setValue(newValue);
    }
  };

  return [value, setCheckedValue];
}

export const pauseAnimationSignal = signal(0);
export const PauseAnimation = () => {
  pauseAnimationSignal.value = pauseAnimationSignal.peek() + 1;
  animationIsPlaying.value = false;
  console.log('PauseAnimation called');
};

export const playAnimationSignal = signal(0);
export const PlayAnimation = () => {
  console.log('PlayAnimation called');
  playAnimationSignal.value = playAnimationSignal.peek() + 1;
  animationIsPlaying.value = true;
};

export const TravelDataSignal = signal<TravelFormData[] | null>(null);

export async function copyTextToClipboard(text: string) {
  // Check if the Clipboard API is supported
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  } else {
    // Fallback for browsers that do not support Clipboard API
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed'; // Avoid scrolling to the bottom
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Failed to copy text using fallback: ', err);
    }
    document.body.removeChild(textArea);
  }
}

export const PlaneIcon = () => {
  return (
    <svg
      width={iconsWidth}
      height={iconsHeight}
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1_583)">
        <path
          d="M16.9873 16.3655L16.1806 17.1118C16.0995 17.1868 16.001 17.2402 15.8939 17.2672C15.7868 17.2943 15.6747 17.294 15.5677 17.2665C15.4608 17.239 15.3624 17.1852 15.2817 17.1098C15.2009 17.0345 15.1403 16.9402 15.1055 16.8354L13.0709 10.7173L10.0774 13.3657L10.2067 15.6515C10.2399 16.0966 10.1829 16.3614 9.76771 16.7455L9.22464 17.2479C9.14122 17.3302 9.03918 17.3912 8.92718 17.4256C8.81518 17.4601 8.69651 17.4671 8.58123 17.446C8.41911 17.4155 8.19568 17.3162 8.04723 17.0171L6.65736 14.3098C6.64705 14.2895 6.63765 14.2684 6.62913 14.2471C6.62804 14.2453 6.62661 14.2437 6.62492 14.2425C6.6041 14.2326 6.58385 14.2214 6.56429 14.2092L3.95991 12.6001C3.6814 12.4298 3.60347 12.2027 3.58724 12.0416C3.57631 11.9335 3.59092 11.8243 3.62988 11.7228C3.66883 11.6213 3.73106 11.5304 3.81155 11.4573L4.38493 10.9269C4.69495 10.6401 5.12456 10.5008 5.50928 10.5616L7.73581 10.8668L10.6447 8.09474L4.71086 5.59801C4.60909 5.55518 4.51969 5.48752 4.45082 5.40122C4.38195 5.31493 4.33581 5.21274 4.31662 5.10401C4.29743 4.99529 4.3058 4.88348 4.34096 4.77882C4.37613 4.67416 4.43697 4.57998 4.51792 4.5049L5.33342 3.75047C5.44931 3.6475 5.58691 3.57197 5.73599 3.52947C5.88507 3.48697 6.04181 3.4786 6.19457 3.50498L14.2456 4.60553L15.9537 2.93986C16.0754 2.8143 16.4284 2.4833 16.5105 2.40733C18.186 0.859003 19.689 0.47751 20.5318 1.38848C20.797 1.67517 21.1742 2.31269 20.7206 3.35991C20.4532 3.97849 19.9432 4.63817 19.2049 5.32111C19.1237 5.39625 18.7672 5.72156 18.6308 5.83486L16.8372 7.40751L17.2995 15.5284C17.3135 15.6824 17.2927 15.8376 17.2387 15.9825C17.1847 16.1274 17.0988 16.2583 16.9873 16.3655Z"
          fill="url(#paint0_linear_1_583)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_583"
          x="0.117769"
          y="0.901276"
          width="24.2803"
          height="23.4886"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.46597" />
          <feGaussianBlur stdDeviation="1.73299" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_583"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_583"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1_583"
          x1="12.055"
          y1="11.1875"
          x2="13.2256"
          y2="-0.0266809"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED6934" />
          <stop offset="1" stopColor="#FFD268" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const VehicleIcon = () => {
  return (
    <svg
      width={iconsWidth}
      height={iconsHeight}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5_834)">
        <path
          d="M19.92 5.01C19.72 4.42 19.16 4 18.5 4H7.5C6.84 4 6.29 4.42 6.08 5.01L4.11 10.68C4.04 10.89 4 11.11 4 11.34V18.5C4 19.33 4.67 20 5.5 20C6.33 20 7 19.33 7 18.5V18H19V18.5C19 19.32 19.67 20 20.5 20C21.32 20 22 19.33 22 18.5V11.34C22 11.12 21.96 10.89 21.89 10.68L19.92 5.01ZM7.5 15C6.67 15 6 14.33 6 13.5C6 12.67 6.67 12 7.5 12C8.33 12 9 12.67 9 13.5C9 14.33 8.33 15 7.5 15ZM18.5 15C17.67 15 17 14.33 17 13.5C17 12.67 17.67 12 18.5 12C19.33 12 20 12.67 20 13.5C20 14.33 19.33 15 18.5 15ZM6 10L7.27 6.18C7.41 5.78 7.79 5.5 8.22 5.5H17.78C18.21 5.5 18.59 5.78 18.73 6.18L20 10H6Z"
          fill="url(#paint0_linear_5_834)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_5_834"
          x="0.534028"
          y="4"
          width="24.9319"
          height="22.9319"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.46597" />
          <feGaussianBlur stdDeviation="1.73299" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_834"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_834"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5_834"
          x1="10.3032"
          y1="13.3384"
          x2="18.1701"
          y2="6.60728"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED6934" />
          <stop offset="1" stopColor="#FFD268" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const HotelIcon = () => (
  <svg
    width={iconsWidth}
    height={iconsHeight}
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_48_354)">
      <path
        d="M18.4939 0.912476H8.74377C8.1404 0.912476 7.56174 1.15217 7.13509 1.57882C6.70844 2.00547 6.46875 2.58413 6.46875 3.1875V7.0863C6.46877 7.11149 6.47464 7.13632 6.4859 7.15884C6.49716 7.18137 6.5135 7.20097 6.53363 7.2161C6.55376 7.23122 6.57713 7.24147 6.6019 7.24601C6.62667 7.25056 6.65215 7.24929 6.67635 7.24231C7.03119 7.13852 7.39905 7.08599 7.76876 7.0863H7.94061C7.98072 7.08656 8.01951 7.07197 8.04951 7.04534C8.07951 7.01872 8.0986 6.98194 8.10311 6.94208C8.13857 6.62447 8.28977 5.35727 8.52785 5.14407C8.76593 4.93087 9.0742 4.81284 9.39378 4.81252H11.6688C11.9886 4.81254 12.2972 4.93044 12.5355 5.14367C12.7738 5.35689 12.9252 6.62426 12.9607 6.94208C12.9652 6.98194 12.9843 7.01872 13.0143 7.04534C13.0443 7.07197 13.0831 7.08656 13.1232 7.0863H14.1169C14.157 7.08656 14.1958 7.07197 14.2258 7.04534C14.2558 7.01872 14.2749 6.98194 14.2794 6.94208C14.3148 6.62468 14.4659 5.35765 14.7037 5.14448C14.9415 4.93131 15.2495 4.81314 15.5688 4.81252H17.8439C18.1637 4.81254 18.4722 4.93044 18.7106 5.14367C18.9489 5.35689 19.1003 6.62426 19.1358 6.94208C19.1403 6.98194 19.1594 7.01872 19.1894 7.04534C19.2194 7.07197 19.2582 7.08656 19.2983 7.0863H19.4689C19.8386 7.08613 20.2065 7.13879 20.5613 7.24271C20.5855 7.24971 20.6111 7.25097 20.6359 7.2464C20.6607 7.24183 20.684 7.23155 20.7042 7.21638C20.7243 7.20121 20.7407 7.18155 20.7519 7.15898C20.7631 7.1364 20.7689 7.11152 20.7689 7.0863V3.1875C20.7689 2.58413 20.5292 2.00547 20.1026 1.57882C19.6759 1.15217 19.0973 0.912476 18.4939 0.912476Z"
        fill="url(#paint0_linear_48_354)"
      />
      <path
        d="M20.7697 7.03452C20.3599 6.85464 19.9172 6.76194 19.4697 6.76233H7.76957C7.32208 6.7619 6.87939 6.85445 6.46955 7.03411C5.89032 7.28742 5.39742 7.70399 5.05111 8.2329C4.7048 8.76182 4.52008 9.38016 4.51953 10.0124V14.5624C4.51953 14.7348 4.58801 14.9001 4.70991 15.022C4.83181 15.1439 4.99715 15.2124 5.16954 15.2124C5.34193 15.2124 5.50726 15.1439 5.62916 15.022C5.75106 14.9001 5.81955 14.7348 5.81955 14.5624V14.2374C5.8206 14.1515 5.85518 14.0695 5.9159 14.0088C5.97662 13.948 6.05868 13.9135 6.14455 13.9124H21.0947C21.1806 13.9135 21.2626 13.948 21.3234 14.0088C21.3841 14.0695 21.4187 14.1515 21.4197 14.2374V14.5624C21.4197 14.7348 21.4882 14.9001 21.6101 15.022C21.732 15.1439 21.8973 15.2124 22.0697 15.2124C22.2421 15.2124 22.4074 15.1439 22.5293 15.022C22.6512 14.9001 22.7197 14.7348 22.7197 14.5624V10.0124C22.7191 9.38023 22.5343 8.76197 22.188 8.23314C21.8417 7.70431 21.3489 7.2878 20.7697 7.03452Z"
        fill="url(#paint1_linear_48_354)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_48_354"
        x="0.519531"
        y="0.912476"
        width="26.2002"
        height="22.2999"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_48_354"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_48_354"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_48_354"
        x1="11.4764"
        y1="4.61085"
        x2="14.2198"
        y2="-0.0978749"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ED6934" />
        <stop offset="1" stopColor="#FFD268" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_48_354"
        x1="10.8929"
        y1="11.6942"
        x2="14.6333"
        y2="5.56691"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ED6934" />
        <stop offset="1" stopColor="#FFD268" />
      </linearGradient>
    </defs>
  </svg>
);

export const ActivityIcon = () => (
  <svg
    width={iconsWidth}
    height={iconsHeight}
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_48_361)">
      <path
        d="M5.6312 15.0069C6.31505 14.7553 7.04237 14.6434 7.7702 14.6777C8.49803 14.7121 9.21159 14.8919 9.86872 15.2067C9.90799 15.2258 9.95088 15.2362 9.99451 15.2373C10.0381 15.2384 10.0815 15.2301 10.1217 15.213C10.1625 15.1969 10.1993 15.1721 10.2295 15.1402C10.2597 15.1084 10.2826 15.0703 10.2965 15.0287C10.676 13.9696 10.9389 12.8723 11.0804 11.7561C11.4164 8.91407 10.9963 6.03366 9.86248 3.40599C9.83328 3.34184 9.78335 3.28938 9.72073 3.25705C9.6581 3.22471 9.58642 3.21438 9.51721 3.22772C9.448 3.24106 9.38529 3.27729 9.33917 3.33059C9.29305 3.38388 9.2662 3.45114 9.26293 3.52155C9.18167 7.52576 7.78225 11.3914 5.28146 14.5197C5.24076 14.5731 5.21828 14.6382 5.21732 14.7053C5.21636 14.7725 5.23698 14.8381 5.27614 14.8927C5.31529 14.9472 5.37093 14.9877 5.43484 15.0083C5.49876 15.0289 5.56759 15.0284 5.6312 15.0069Z"
        fill="url(#paint0_linear_48_361)"
      />
      <path
        d="M20.9262 15.8126C20.8956 15.7765 20.8572 15.748 20.814 15.729C20.7707 15.7101 20.7236 15.7013 20.6764 15.7033L4.53822 16.3403C4.48625 16.3428 4.4357 16.3581 4.39103 16.3848C4.34637 16.4114 4.30897 16.4487 4.28216 16.4933C4.25494 16.5382 4.23951 16.5892 4.23733 16.6416C4.23515 16.694 4.24627 16.7462 4.26966 16.7931L5.24707 18.7479C5.27334 18.7998 5.31355 18.8434 5.36319 18.8737C5.41283 18.9041 5.46993 18.92 5.52811 18.9197H17.6536C18.3565 18.895 19.0364 18.6637 19.6084 18.2545C20.3221 17.7238 20.8156 16.9491 20.9949 16.078C21.0041 16.0314 21.0027 15.9834 20.9908 15.9375C20.9789 15.8915 20.9569 15.8488 20.9262 15.8126Z"
        fill="url(#paint1_linear_48_361)"
      />
      <path
        d="M11.7236 6.51615C12.3899 9.29923 12.2304 12.2162 11.2646 14.91C11.2454 14.9688 11.244 15.0319 11.2605 15.0915C11.277 15.1511 11.3106 15.2045 11.3572 15.2451C11.4038 15.2857 11.4613 15.3117 11.5226 15.3199C11.5839 15.328 11.6462 15.318 11.7018 15.291C13.2236 14.5173 14.898 14.0907 16.6045 14.0419C17.6928 14.0099 18.7802 14.134 19.8333 14.4104C19.8821 14.4225 19.9331 14.4228 19.982 14.4112C20.031 14.3997 20.0764 14.3766 20.1146 14.3439C20.1528 14.3112 20.1826 14.2699 20.2016 14.2233C20.2205 14.1767 20.2281 14.1263 20.2236 14.0763C19.6867 8.01106 15.0572 3.00652 10.0279 1.44808C9.96622 1.42912 9.9002 1.4296 9.83881 1.44947C9.77742 1.46933 9.72364 1.50761 9.68476 1.55911C9.64589 1.61061 9.62381 1.67283 9.62153 1.73731C9.61925 1.8018 9.63688 1.86542 9.67202 1.91954C10.6224 3.31834 11.317 4.87467 11.7236 6.51615Z"
        fill="url(#paint2_linear_48_361)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_48_361"
        x="-1.375"
        y="0.181274"
        width="27.9883"
        height="27.9883"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_48_361"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_48_361"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_48_361"
        x1="7.30981"
        y1="10.2348"
        x2="11.2672"
        y2="8.73793"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ED6934" />
        <stop offset="1" stopColor="#FFD268" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_48_361"
        x1="10.1074"
        y1="17.5804"
        x2="10.8669"
        y2="14.5703"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ED6934" />
        <stop offset="1" stopColor="#FFD268" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_48_361"
        x1="13.3345"
        y1="9.54019"
        x2="19.3374"
        y2="6.05444"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ED6934" />
        <stop offset="1" stopColor="#FFD268" />
      </linearGradient>
    </defs>
  </svg>
);

export const icons = {
  Plane: <PlaneIcon />,
  Car: <VehicleIcon />,
  hotel: <HotelIcon />,
  activity: <ActivityIcon />,
} as any;

export const generateDates = (
  baseDate: string | Date | null,
  endDate: string | Date | null,
) => {
  if (!baseDate || !endDate) return [];
  const result = [];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  // Convert input strings to moment objects and adjust for local timezone
  let startDate = DAYJS(baseDate).subtract(5, 'days'); // Ensure startDate is 3 days before the baseDate
  const finalEndDate = DAYJS(endDate).add(5, 'days'); // Adjust to include endDate and 3 days after

  while (startDate <= finalEndDate) {
    result.push({
      date: startDate.toDate(), // Format date as YYYY-MM-DD
      day: days[startDate.day()],
    });
    startDate = startDate.add(1, 'days');
  }

  return result;
};

interface TextTickerProps {
  children: React.ReactNode;
}

export const TextTicker: React.FC<TextTickerProps> = ({ children }) => {
  // get parent width
  const parentWidth = document.getElementById('text-ticker')?.clientWidth;

  if ((children ?? '').toLocaleString().length < 25) {
    return (
      <div
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </div>
    );
  } else {
    // limit the text to 25 characters and add ellipsis
    children = ((children ?? '').toString().slice(0, 25) + '...').replaceAll(
      /,/g,
      ', ',
    );
    return <div style={{ whiteSpace: 'nowrap' }}>{children}</div>;
  }

  return (
    <Marquee speed={25} pauseOnHover autoFill>
      <div className="d-flex">
        {children}
        <span style={{ paddingRight: '10px', paddingLeft: '10px' }}>-</span>
      </div>
    </Marquee>
  );
};

export const getMonthName = (date: any) => {
  const month = DAYJS(date, 'MM-DD-YYYY').month();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[month];
};

export const getDayName = (date: any) => {
  const day = DAYJS(date, 'MM-DD-YYYY').day();
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return days[day];
};

export const getDaySuffix = (date: any) => {
  const day = DAYJS(date, 'MM-DD-YYYY').date();
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getShortDayName = (date: any) => {
  const day = DAYJS(date, 'MM-DD-YYYY').day();
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return days[day];
};

export const calendarResolution = async () => {
  return new Promise(async (resolve) => {
    const step = animationStateSignal.peek() as any;
    const departureDates = departureDatesSignal.peek();

    console.log('Calendar Resolver : ', step?.calendarStep);

    if (!lastDepartureDateSignal.peek()) {
      lastDepartureDateSignal.value = departureDates?.[step?.calendarStep];
      return resolve(1);
    }

    if (!departureDates) return resolve(1);

    const nextDepartureDate = departureDates?.[step?.calendarStep];
    const currentDepartureDate = lastDepartureDateSignal.peek();
    lastDepartureDateSignal.value = nextDepartureDate;
    if (
      currentDepartureDate &&
      DAYJS(currentDepartureDate).get('date') !==
        DAYJS(nextDepartureDate).get('date')
    ) {
      provokeLoadState(nextDepartureDate);
    } else {
      emptyStateSignal.value = 0;
      setAnimationStateSignal((prev: any) => ({
        ...prev,
        state: 'depart',
      }));
      return resolve(1);
    }
    await new Promise((res) => setTimeout(res, 500));
    await isCalendarAnimatingPromise();
    setAnimationStateSignal((prev: any) => ({
      ...prev,
      state: 'depart',
    }));
    return resolve(1);
  });
};

function formatTimeDifference(startDate: string, endDate: string) {
  const diffInSeconds = DAYJS(endDate).diff(DAYJS(startDate), 'second');
  const diffDuration = DAYJS.duration(diffInSeconds, 'seconds');

  const weeks = Math.floor(diffDuration.asWeeks());
  const days = Math.floor(diffDuration.asDays()) % 7;
  const hours = diffDuration.hours();
  const minutes = diffDuration.minutes();

  let result = [];

  if (weeks > 0) result.push(`${weeks} week${weeks > 1 ? 's' : ''}`);
  if (days > 0) result.push(`${days} day${days > 1 ? 's' : ''}`);
  if (hours > 0) result.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  if (minutes > 0) result.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);

  return result.slice(0, 2).join(' ');
}

export const computeTitle = (type?: string) => {
  switch (type) {
    case 'Plane':
      return 'Flight';
    case 'Car':
      return 'Drive';
    case 'Ferry':
      return 'Ferry';
    case 'Walk':
      return 'Walk';
    case 'Transit':
      return 'Transit';
    default:
      return 'Stay';
  }
};

export const computeHeading = (
  type: string,
  title: string,
  isOpen?: boolean,
) => {
  if (isOpen) {
    return `${title} - ${computeTitle(type)}`;
  }
  return `${title}`;
};

export const formatCategory = (category: string) => {
  // skip first two characters
  const char = category.slice(2);

  // if its Point of Attraction
  if (char.toLowerCase().includes('point of attraction')) {
    return 'Attraction';
  }
  return char;
};

export const isCalendarAnimatingPromise = () => {
  return new Promise((resolve) => {
    const checkAnimation = () => {
      if (isCalendarAnimating.peek()) {
        setTimeout(checkAnimation, 100); // Check every 100 milliseconds
      } else {
        resolve(true);
      }
    };
    checkAnimation();
  });
};

export const resetAllSignals = (withActivities = true) => {
  durationSignal.value = 0;
  lastDepartureDateSignal.value = null;
  animationStateSignal.value = { state: 'idle', calendarStep: -1 };
  // departureDatesSignal.value = [];
  if (withActivities) activitiesSignal.value = [];
  originStateSignal.value = null;
  destinationStateSignal.value = null;
  clickedIndexSignal.value = undefined;
  clickedIndexDirection.value = 'fwd';
  categoryViewSignal.value = { icon: '', label: '', place: '' };
  loadStateProvokeSignal.value = null;
  endStateSignal.value = false;
  emptyStateSignal.value = 0;
  imagesignal.value = null;
  imagesInfoSignal.value = null;
  openedImages.value = [];
  autoOpenImagesSignal.value = 0;
  openedList.value = [];
  showPlayPauseButton.value = false;
  animationIsPlaying.value = false;
};

export const getActionBySelectedTransport = (selectedTransport: string) => {
  switch (selectedTransport?.toLowerCase()) {
    case 'plane':
      return 'Fly';
    case 'car':
      return 'Drive';
    case 'ferry':
      return 'Sail';
    case 'walk':
      return 'Walk';
    case 'metro':
      return 'Take Metro';
    case 'bus':
      return 'Take Bus';
    case 'train':
      return 'Take Train';
    case 'tram':
      return 'Take Tram';
    case 'walk':
      return 'Walk';
    case 'subway':
      return 'Take Subway';
    case 'transit':
      return 'Take Transit';
    default:
      return 'Travel';
  }
};

export const getIconByCategory = (category: string) => {
  // category first character is icon
  const icon = category.charAt(0).toUpperCase();
  return <div className="icon">{icon}</div>;
};

export const getIconBySelectedTransport = (selectedTransport: string) => {
  switch (selectedTransport) {
    case 'Plane':
      return (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.8399 22.0511L17.7879 14.1183L21.2288 10.6774C21.7481 10.1582 21.7481 9.31985 21.2288 8.80059C20.7095 8.28133 19.8712 8.28133 19.352 8.80059L15.9111 12.2415L7.97828 10.1895C7.66547 10.1019 7.32138 10.1957 7.0899 10.4272C6.65197 10.8651 6.75833 11.5971 7.29636 11.8974L13.0896 15.063L9.65493 18.4976L7.39645 17.9283C7.24631 17.8908 7.08365 17.9408 6.97729 18.0472L6.61443 18.41C6.40798 18.6165 6.45177 18.9606 6.69576 19.1045L8.97926 20.4746L9.34212 20.6873L9.52355 20.9813L9.71749 21.3129L10.6059 22.8019L10.9187 23.3274C11.0688 23.5776 11.4066 23.6152 11.6131 23.4087L11.976 23.0459C12.0886 22.9332 12.1324 22.7768 12.0948 22.6267L11.5318 20.3745L14.9727 16.9336L18.1383 22.7268C18.4323 23.2711 19.1643 23.3774 19.6022 22.9395C19.8337 22.708 19.9275 22.3639 19.8399 22.0511Z"
            fill="url(#paint0_linear_90_436)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_90_436"
              x1="12.1005"
              y1="14.1797"
              x2="22.6437"
              y2="15.5787"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#ED6934" />
              <stop offset="1" stopColor="#FFD268" />
            </linearGradient>
          </defs>
        </svg>
      );
    case 'Car':
      return (
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.7667 1.17492C13.6 0.683252 13.1333 0.333252 12.5833 0.333252H3.41667C2.86667 0.333252 2.40833 0.683252 2.23333 1.17492L0.591667 5.89992C0.533333 6.07492 0.5 6.25825 0.5 6.44992V12.4166C0.5 13.1083 1.05833 13.6666 1.75 13.6666C2.44167 13.6666 3 13.1083 3 12.4166V11.9999H13V12.4166C13 13.0999 13.5583 13.6666 14.25 13.6666C14.9333 13.6666 15.5 13.1083 15.5 12.4166V6.44992C15.5 6.26659 15.4667 6.07492 15.4083 5.89992L13.7667 1.17492ZM3.41667 9.49992C2.725 9.49992 2.16667 8.94159 2.16667 8.24992C2.16667 7.55825 2.725 6.99992 3.41667 6.99992C4.10833 6.99992 4.66667 7.55825 4.66667 8.24992C4.66667 8.94159 4.10833 9.49992 3.41667 9.49992ZM12.5833 9.49992C11.8917 9.49992 11.3333 8.94159 11.3333 8.24992C11.3333 7.55825 11.8917 6.99992 12.5833 6.99992C13.275 6.99992 13.8333 7.55825 13.8333 8.24992C13.8333 8.94159 13.275 9.49992 12.5833 9.49992ZM2.16667 5.33325L3.225 2.14992C3.34167 1.81659 3.65833 1.58325 4.01667 1.58325H11.9833C12.3417 1.58325 12.6583 1.81659 12.775 2.14992L13.8333 5.33325H2.16667Z"
            fill="url(#paint0_linear_395_112)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_395_112"
              x1="5.7527"
              y1="8.11526"
              x2="12.3084"
              y2="2.50598"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#ED6934" />
              <stop offset="1" stopColor="#FFD268" />
            </linearGradient>
          </defs>
        </svg>
      );
    case 'Ferry':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.875 3.54375C11.255 3.43938 10.6281 3.37125 10 3.37125C9.37187 3.37125 8.745 3.43875 8.125 3.54375V3.125C8.125 2.4375 8.6875 1.875 9.375 1.875H10.625C11.3125 1.875 11.875 2.4375 11.875 3.125V3.54375ZM9.99986 6.82627C9.49736 6.82627 9.00236 6.9119 8.52924 7.08065L5.62549 8.1169V6.34127C5.62549 5.75127 6.03736 5.2419 6.61361 5.11877L7.90674 4.8419C9.28674 4.54627 10.7136 4.54627 12.0936 4.8419L13.3867 5.11877C13.963 5.24252 14.3749 5.7519 14.3749 6.34127V8.1169L11.4705 7.08065C10.9974 6.9119 10.5024 6.82627 9.99986 6.82627ZM4.30305 15.265C5.0243 14.8044 5.87492 14.5413 6.75055 14.5413C7.75555 14.5413 8.73867 14.8713 9.51805 15.4707C9.64992 15.5719 9.82055 15.6275 9.99992 15.6275C10.1793 15.6275 10.3505 15.5719 10.4818 15.4707C11.2605 14.8719 12.2437 14.5413 13.2499 14.5413C14.1249 14.5413 14.9749 14.8044 15.6968 15.265C16.233 14.0682 16.6337 12.7994 16.8562 11.4688C16.9624 10.85 16.6062 10.2438 16.0187 10.0313L11.0499 8.25878C10.3705 8.01628 9.62867 8.01628 8.94992 8.25878L3.98117 10.0313C3.39367 10.2438 3.03742 10.85 3.14367 11.4688C3.36617 12.7988 3.7668 14.0675 4.30305 15.265ZM1.49424 17.4544C2.07549 17.9019 2.78736 18.125 3.50049 18.125H3.49924C4.21174 18.125 4.92361 17.9019 5.50486 17.4544C6.22611 16.9 7.27174 16.9 7.99299 17.4544C9.15486 18.3488 10.8424 18.3488 12.0042 17.4544C12.7255 16.9 13.7711 16.9 14.4924 17.4544C15.653 18.3481 17.3411 18.3494 18.5042 17.4544C18.7786 17.2438 18.8292 16.8519 18.6192 16.5781C18.408 16.305 18.0167 16.2531 17.743 16.4637C17.0211 17.0188 15.9755 17.0175 15.2549 16.4637C14.093 15.5694 12.4049 15.5694 11.243 16.4637C10.5217 17.0181 9.47674 17.0188 8.75549 16.4637C7.59361 15.57 5.90549 15.5694 4.74361 16.4637C4.02174 17.0188 2.97611 17.0181 2.25549 16.4637C1.98236 16.2525 1.58986 16.3044 1.37924 16.5781C1.16924 16.8513 1.22049 17.2438 1.49424 17.4544Z"
            fill="url(#paint0_linear_90_473)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_90_473"
              x1="7.37769"
              y1="11.3594"
              x2="15.3063"
              y2="4.86594"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#ED6934" />
              <stop offset="1" stopColor="#FFD268" />
            </linearGradient>
          </defs>
        </svg>
      );
    case 'Walk':
      return (
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.75 4.9375C11.575 4.9375 12.25 4.2625 12.25 3.4375C12.25 2.6125 11.575 1.9375 10.75 1.9375C9.925 1.9375 9.25 2.6125 9.25 3.4375C9.25 4.2625 9.925 4.9375 10.75 4.9375ZM7.975 7.4875L6.055 17.17C5.9575 17.6275 6.3175 18.0625 6.79 18.0625H6.85C7.2025 18.0625 7.5025 17.8225 7.585 17.4775L8.8 12.0625L10.375 13.5625V17.3125C10.375 17.725 10.7125 18.0625 11.125 18.0625C11.5375 18.0625 11.875 17.725 11.875 17.3125V13.0825C11.875 12.67 11.71 12.28 11.41 11.995L10.3 10.9375L10.75 8.6875C11.5525 9.6175 12.715 10.285 14.02 10.495C14.47 10.5625 14.875 10.2025 14.875 9.745C14.875 9.3775 14.605 9.07 14.2375 9.01C13.0975 8.8225 12.1525 8.1475 11.65 7.2625L10.9 6.0625C10.48 5.395 9.64 5.125 8.9125 5.4325L6.04 6.6475C5.485 6.8875 5.125 7.4275 5.125 8.035V9.8125C5.125 10.225 5.4625 10.5625 5.875 10.5625C6.2875 10.5625 6.625 10.225 6.625 9.8125V8.0125L7.975 7.4875Z"
            fill="url(#paint0_linear_90_443)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_90_443"
              x1="8.53926"
              y1="11.3489"
              x2="14.6317"
              y2="8.54712"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#ED6934" />
              <stop offset="1" stop-color="#FFD268" />
            </linearGradient>
          </defs>
        </svg>
      );
    case 'Transit':
      return (
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9997 2.08325C7.66634 2.08325 4.33301 2.49992 4.33301 5.41659V13.3333C4.33301 14.9416 5.64134 16.2499 7.24967 16.2499L6.29134 17.2083C6.03301 17.4666 6.21634 17.9166 6.59134 17.9166H7.49967C7.60801 17.9166 7.71634 17.8749 7.79134 17.7916L9.33301 16.2499H12.6663L14.208 17.7916C14.283 17.8666 14.3913 17.9166 14.4997 17.9166H15.408C15.783 17.9166 15.9663 17.4666 15.6997 17.2083L14.7497 16.2499C16.358 16.2499 17.6663 14.9416 17.6663 13.3333V5.41659C17.6663 2.49992 14.333 2.08325 10.9997 2.08325ZM7.24967 14.5833C6.55801 14.5833 5.99967 14.0249 5.99967 13.3333C5.99967 12.6416 6.55801 12.0833 7.24967 12.0833C7.94134 12.0833 8.49967 12.6416 8.49967 13.3333C8.49967 14.0249 7.94134 14.5833 7.24967 14.5833ZM10.1663 8.74992H5.99967V5.41659H10.1663V8.74992ZM14.7497 14.5833C14.058 14.5833 13.4997 14.0249 13.4997 13.3333C13.4997 12.6416 14.058 12.0833 14.7497 12.0833C15.4413 12.0833 15.9997 12.6416 15.9997 13.3333C15.9997 14.0249 15.4413 14.5833 14.7497 14.5833ZM15.9997 8.74992H11.833V5.41659H15.9997V8.74992Z"
            fill="url(#paint0_linear_90_447)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_90_447"
              x1="9.00208"
              y1="11.3244"
              x2="16.1596"
              y2="6.74022"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#ED6934" />
              <stop offset="1" stop-color="#FFD268" />
            </linearGradient>
          </defs>
        </svg>
      );
    default:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.37188 11.6031C6.0914 11.5558 5.80781 11.525 5.52366 11.525C5.23951 11.525 4.95592 11.5556 4.67545 11.6031V11.4136C4.67545 11.1026 4.92991 10.8481 5.24092 10.8481H5.8064C6.11741 10.8481 6.37188 11.1026 6.37188 11.4136V11.6031ZM5.52376 13.0879C5.29644 13.0879 5.07251 13.1267 4.85848 13.203L3.54488 13.6718V12.8685C3.54488 12.6016 3.7312 12.3712 3.99189 12.3155L4.57687 12.1902C5.20116 12.0565 5.84665 12.0565 6.47093 12.1902L7.05592 12.3155C7.3166 12.3715 7.50293 12.6019 7.50293 12.8685V13.6718L6.18904 13.203C5.97501 13.1267 5.75108 13.0879 5.52376 13.0879ZM2.94681 16.9055C3.27309 16.6971 3.6579 16.5781 4.05401 16.5781C4.50866 16.5781 4.9534 16.7273 5.30598 16.9985C5.36564 17.0443 5.44282 17.0695 5.52397 17.0695C5.60511 17.0695 5.68259 17.0443 5.74196 16.9985C6.09425 16.7276 6.539 16.5781 6.99421 16.5781C7.39004 16.5781 7.77456 16.6971 8.10113 16.9055C8.34372 16.364 8.52495 15.7901 8.62561 15.1881C8.67367 14.9082 8.51251 14.634 8.24674 14.5378L5.99897 13.736C5.69163 13.6263 5.35602 13.6263 5.04897 13.736L2.8012 14.5378C2.53543 14.634 2.37427 14.9082 2.42233 15.1881C2.52299 15.7898 2.70422 16.3637 2.94681 16.9055ZM1.67592 17.8959C1.93886 18.0983 2.2609 18.1993 2.58351 18.1993H2.58294C2.90526 18.1993 3.2273 18.0983 3.49025 17.8959C3.81653 17.6451 4.28955 17.6451 4.61583 17.8959C5.14144 18.3005 5.90483 18.3005 6.43044 17.8959C6.75672 17.6451 7.22974 17.6451 7.55602 17.8959C8.08107 18.3002 8.84474 18.3008 9.37092 17.8959C9.49504 17.8006 9.51794 17.6233 9.42294 17.4995C9.32738 17.3759 9.15038 17.3525 9.02654 17.4477C8.69998 17.6988 8.22696 17.6983 7.90096 17.4477C7.37535 17.0431 6.61168 17.0431 6.08607 17.4477C5.75979 17.6985 5.28705 17.6988 4.96077 17.4477C4.43516 17.0434 3.67148 17.0431 3.14587 17.4477C2.81931 17.6988 2.34629 17.6985 2.02029 17.4477C1.89674 17.3522 1.71918 17.3756 1.62389 17.4995C1.52889 17.623 1.55208 17.8006 1.67592 17.8959Z"
            fill="url(#paint0_linear_90_456)"
          />
          <path
            d="M10.0471 1.94238C8.53919 1.94238 7.03125 2.13087 7.03125 3.45032V7.03167C7.03125 7.75925 7.62312 8.35111 8.35069 8.35111L7.91716 8.78464C7.8003 8.90151 7.88323 9.10508 8.05288 9.10508H8.46379C8.5128 9.10508 8.56181 9.08623 8.59573 9.04853L9.29315 8.35111H10.8011L11.4985 9.04853C11.5324 9.08246 11.5814 9.10508 11.6305 9.10508H12.0414C12.211 9.10508 12.2939 8.90151 12.1733 8.78464L11.7436 8.35111C12.4711 8.35111 13.063 7.75925 13.063 7.03167V3.45032C13.063 2.13087 11.5551 1.94238 10.0471 1.94238ZM8.35069 7.59714C8.0378 7.59714 7.78522 7.34457 7.78522 7.03167C7.78522 6.71877 8.0378 6.46619 8.35069 6.46619C8.66359 6.46619 8.91617 6.71877 8.91617 7.03167C8.91617 7.34457 8.66359 7.59714 8.35069 7.59714ZM9.67014 4.95826H7.78522V3.45032H9.67014V4.95826ZM11.7436 7.59714C11.4307 7.59714 11.1781 7.34457 11.1781 7.03167C11.1781 6.71877 11.4307 6.46619 11.7436 6.46619C12.0564 6.46619 12.309 6.71877 12.309 7.03167C12.309 7.34457 12.0564 7.59714 11.7436 7.59714ZM12.309 4.95826H10.4241V3.45032H12.309V4.95826Z"
            fill="url(#paint1_linear_90_456)"
          />
          <path
            d="M10.8292 15.8861H16.4634C16.9158 15.8861 17.3271 15.6805 17.5738 15.3104C17.8206 14.9402 17.9028 14.529 17.7383 14.1177C17.1626 12.4727 15.5998 11.3623 13.7903 11.3623H10.8292C10.5825 11.3623 10.418 11.5268 10.418 11.7736V15.4749C10.418 15.7216 10.5825 15.8861 10.8292 15.8861ZM16.4223 13.4186H13.2968V12.1848H13.8314C14.8595 12.1848 15.8054 12.6372 16.4223 13.4186ZM12.8268 12.1848V13.4186H11.1817V12.1848H12.8268Z"
            fill="url(#paint2_linear_90_456)"
          />
          <path
            d="M17.41 16.7087H10.9474C10.7006 16.7087 10.5361 16.8732 10.5361 17.12C10.5361 17.3667 10.7006 17.5313 10.9474 17.5313H17.41C17.6567 17.5313 17.8212 17.3667 17.8212 17.12C17.8212 16.8732 17.6567 16.7087 17.41 16.7087Z"
            fill="url(#paint3_linear_90_456)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_90_456"
              x1="4.33748"
              y1="15.1387"
              x2="7.92422"
              y2="12.2012"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#ED6934" />
              <stop offset="1" stop-color="#FFD268" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_90_456"
              x1="9.14345"
              y1="6.1229"
              x2="12.3814"
              y2="4.04911"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#ED6934" />
              <stop offset="1" stop-color="#FFD268" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_90_456"
              x1="13.0114"
              y1="14.0026"
              x2="15.2097"
              y2="11.2655"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#ED6934" />
              <stop offset="1" stop-color="#FFD268" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_90_456"
              x1="13.0872"
              y1="17.1888"
              x2="13.2061"
              y2="16.3878"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#ED6934" />
              <stop offset="1" stop-color="#FFD268" />
            </linearGradient>
          </defs>
        </svg>
      );
  }
};

const checkEnding = (v: number) => {
  return v === 1 ? '' : 's';
};

export const durationString = (end: Moment, start: Moment, short = false) => {
  if (!end || !start) return '';
  const duration = moment.duration(end.diff(start));
  if (duration.asMinutes() <= 0) return '';
  return `${
    duration.asDays() > 1
      ? Math.floor(duration.asDays()) +
        ` ${short ? 'd' : `day${checkEnding(duration.asDays())}`}`
      : ''
  } ${
    duration.hours() > 0
      ? duration.hours() +
        ` ${short ? 'h' : `hour${checkEnding(duration.hours())}`}`
      : ''
  } ${
    duration.minutes() > 0
      ? duration.minutes() +
        ` ${short ? 'min' : `minute${checkEnding(duration.minutes())}`}`
      : `0 ${short ? 'min' : 'minutes'}`
  }`;
};
