import * as React from 'react';
import { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Dialog, DialogContent, Button, Stack } from '@mui/material';
import {
  openedCalendarOverlaySignal,
  openedHotelsFetchAPIFlowSignal,
} from '../common';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers/PickersCalendarHeader';
import { useStyles } from './styles'; // Import the styles
import { RootState, useSelector } from '~/redux/reducers';
import { useDispatch } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { mapSignal } from '~/map/ViewTravel';
import { useMarkerContext } from '~/context/HotelMarkerContext';

// Custom Calendar Header
const CustomCalendarHeader = (props: PickersCalendarHeaderProps<Dayjs>) => {
  const classes = useStyles();
  const { currentMonth, onMonthChange, minDate } = props;

  const selectNextMonth = () =>
    onMonthChange(currentMonth.add(1, 'month').endOf('month'), 'left');

  const selectPreviousMonth = () => {
    onMonthChange(currentMonth.subtract(1, 'month').endOf('month'), 'right');
  };

  return (
    <div className={classes.calendarHeaderRoot}>
      <Stack spacing={1} direction="row">
        <IconButton
          onClick={selectPreviousMonth}
          title="Previous month"
          className={classes.selectionIcon}
        >
          <ChevronLeft />
        </IconButton>
      </Stack>
      <Typography variant="body2" className={classes.currentMonthText}>
        {currentMonth.format('MMMM YYYY')}
      </Typography>
      <Stack spacing={1} direction="row">
        <IconButton
          onClick={selectNextMonth}
          title="Next month"
          className={classes.selectionIcon}
        >
          <ChevronRight />
        </IconButton>
      </Stack>
    </div>
  );
};

// Custom PickersDay component to handle day rendering logic
interface CustomPickersDayProps extends PickersDayProps<Dayjs> {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

const CustomPickersDay: React.FC<CustomPickersDayProps> = (props) => {
  const { day, selected, startDate, endDate, ...other } = props;
  const classes = useStyles();

  const isInRange =
    (startDate && endDate && day.isSame(startDate, 'day')) ||
    day.isSame(endDate, 'day') ||
    (day.isAfter(startDate, 'day') && day.isBefore(endDate, 'day'));

  const isFirstDay = startDate && day.isSame(startDate, 'day');
  const isLastDay = endDate && day.isSame(endDate, 'day');

  //applying styling classes based on day value
  let dayClassName = '';
  if (isInRange) {
    if (isFirstDay) {
      dayClassName = `${classes.highlightedDay} ${classes.firstDay}`;
    } else if (isLastDay) {
      dayClassName = `${classes.highlightedDay} ${classes.lastDay}`;
    } else {
      dayClassName = `${classes.highlightedDay}`;
    }
  } else if (selected) {
    dayClassName = `${classes.highlightedDay} ${classes.singleDay} `;
  }

  return (
    <PickersDay
      {...other}
      day={day}
      selected={isFirstDay || isLastDay || selected}
      className={`${classes.day} ${dayClassName}`}
    />
  );
};

// Main Calendar Component
export const CalendarOverlay: React.FC = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [onChangeStartDate, setOnChangeStartDate] = useState<boolean>(false);
  const [onChangeEndDate, setOnChangeEndDate] = useState<boolean>(false);
  const [map, setMap] = useState<any>(null);
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);
  const dispatch = useDispatch();
  const today = dayjs();
  const { clearMarkers } = useMarkerContext();

  useSignalEffect(() => {
    setMap(mapSignal.value);
  });

  //logic to make Monday as the first day of week
  dayjs.extend(localeData);
  dayjs.extend(updateLocale);

  dayjs.updateLocale('en', {
    weekStart: 1, // 0 = Sunday, 1 = Monday
  });

  const travelData = useSelector(
    (state: RootState) => state.TripInfoReducers.travelData,
  );
  const isFetchHotelsAPIFlowOPened = useSelector(
    (state: RootState) =>
      state.CalendarOverlayReducers.isFetchHotelsAPIFlowOPened,
  );

  const isCalendarOverlayOpened = useSelector(
    (state: RootState) => state.CalendarOverlayReducers.isCalendarOverlayOpened,
  );

  const isOverViewButtonClicked = useSelector(
    (state: RootState) => state.CalendarOverlayReducers.isOverViewButtonClicked,
  );

  const departureDate = useSelector(
    (state: RootState) => state.CalendarOverlayReducers.departureDate,
  );

  const returnDate = useSelector(
    (state: RootState) => state.CalendarOverlayReducers.returnDate,
  );

  //depart and return dates of travel itinerary
  let depart_date: Dayjs | null = travelData[0]?.arrival?.dateTime
    ? dayjs(travelData[0].arrival.dateTime)
    : null;

  let return_date: Dayjs | null = travelData[travelData.length - 1]?.arrival
    ?.dateTime
    ? dayjs(travelData[travelData.length - 1].arrival.dateTime)
    : null;

  let depart_ = depart_date ? depart_date.format('YYYY-MM-DD') : '';
  let return_ = return_date ? return_date.format('YYYY-MM-DD') : '';

  //duration to check if more than one day is selected
  let duration = dayjs(return_).diff(dayjs(depart_), 'day');

  //logic to increase year by one if itinerary dates are from past, increases duration by one if same days are selected
  if (depart_date) {
    if (depart_date >= today) {
      if (duration === 0) {
        return_date = return_date ? return_date.add(1, 'day') : return_date;
      }
    } else {
      depart_date = depart_date ? depart_date.add(1, 'year') : depart_date;
      return_date = return_date ? return_date.add(1, 'year') : return_date;
      if (duration === 0) {
        return_date = return_date ? return_date.add(1, 'day') : return_date;
      }
    }
  }

  depart_ = depart_date ? depart_date.format('YYYY-MM-DD') : '';
  return_ = return_date ? return_date.format('YYYY-MM-DD') : '';

  //handle calendar overlay closing
  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === 'backdropClick') {
      // Prevent closing
      return;
    }

    //using escape key
    if (reason === 'escapeKeyDown') {
      if (!isOverViewButtonClicked) {
        map.flyTo({
          zoom: 9,
        });

        openedHotelsFetchAPIFlowSignal.value = !isFetchHotelsAPIFlowOPened;
        openedCalendarOverlaySignal.value = !isCalendarOverlayOpened;
        dispatch(
          ActionsCreator.setIsFetchHotelsAPIFlowOPened(
            openedHotelsFetchAPIFlowSignal.value,
          ),
        );
        dispatch(
          ActionsCreator.setIsCalendarOverlayOpened(
            openedCalendarOverlaySignal.value,
          ),
        );
        dispatch(ActionsCreator.setIsAPIResponseEmpty(0));
      } else {
        openedCalendarOverlaySignal.value = !isCalendarOverlayOpened;
        dispatch(
          ActionsCreator.setIsCalendarOverlayOpened(
            openedCalendarOverlaySignal.value,
          ),
        );
      }
    }
  };

  //back arrow click
  function handleCalendarBackArrowClick() {
    if (!isOverViewButtonClicked) {
      console.log('inside if-statement');
      openedHotelsFetchAPIFlowSignal.value = false;
      openedCalendarOverlaySignal.value = false;
      dispatch(
        ActionsCreator.setIsFetchHotelsAPIFlowOPened(
          openedHotelsFetchAPIFlowSignal.value,
        ),
      );
      dispatch(
        ActionsCreator.setIsCalendarOverlayOpened(
          openedCalendarOverlaySignal.value,
        ),
      );
    } else {
      console.log('inside else-statement');
      openedCalendarOverlaySignal.value = false;
      dispatch(
        ActionsCreator.setIsCalendarOverlayOpened(
          openedCalendarOverlaySignal.value,
        ),
      );
      dispatch(ActionsCreator.setOverviewButtonClicked(false));
    }
  }

  //next button touch
  // function handleCalendarNextButtonTouch(e: React.SyntheticEvent) {
  //   if (isNextButtonClicked) {
  //     if (endDate) {
  //       clearMarkers();
  //       openedCalendarOverlaySignal.value = !isCalendarOverlayOpened;
  //       dispatch(
  //         ActionsCreator.setIsCalendarOverlayOpened(
  //           openedCalendarOverlaySignal.value,
  //         ),
  //       );
  //       dispatch(ActionsCreator.setIsAPIResponseEmpty(0));
  //       dispatch(ActionsCreator.setIsMapTapped(false));
  //       dispatch(ActionsCreator.setOverviewButtonClicked(false));
  //       dispatch(
  //         ActionsCreator.setDepartureDate(
  //           dayjs(startDate).format('YYYY-MM-DD'),
  //         ),
  //       );
  //       dispatch(
  //         ActionsCreator.setReturnDate(dayjs(endDate).format('YYYY-MM-DD')),
  //       );
  //       setIsNextButtonClicked(false);
  //     }
  //   }
  // }

  //next button click
  function handleCalendarNextButtonClick(
    e: React.SyntheticEvent,
    isNextButtonClicked: boolean,
  ) {
    console.log('i am here', isNextButtonClicked);
    if (isNextButtonClicked) {
      console.log('i am here');
      if (endDate) {
        console.log('i am here');
        clearMarkers();
        openedCalendarOverlaySignal.value = false;
        dispatch(
          ActionsCreator.setIsCalendarOverlayOpened(
            openedCalendarOverlaySignal.value,
          ),
        );
        dispatch(ActionsCreator.setIsAPIResponseEmpty(0));
        dispatch(ActionsCreator.setIsMapTapped(false));
        dispatch(ActionsCreator.setOverviewButtonClicked(false));
        dispatch(
          ActionsCreator.setDepartureDate(
            dayjs(startDate).format('YYYY-MM-DD'),
          ),
        );
        dispatch(
          ActionsCreator.setReturnDate(dayjs(endDate).format('YYYY-MM-DD')),
        );
        setIsNextButtonClicked(false);
      }
    }
  }

  //initialising dates when component is first mounted
  useEffect(() => {
    if (!isOverViewButtonClicked) {
      setStartDate(dayjs(depart_date));
      setEndDate(dayjs(return_date));
    } else {
      setStartDate(dayjs(departureDate));
      setEndDate(dayjs(returnDate));
    }
  }, []);

  //handling changes of dates in calendar by the user
  const handleDateChange = (date: Dayjs | null) => {
    if (!onChangeStartDate) {
      setOnChangeStartDate(true);
      setOnChangeEndDate(false);

      depart_date = date;
      return_date = null;
      setStartDate(date);
      setEndDate(null);
    } else if (onChangeStartDate && !onChangeEndDate) {
      if (date?.isAfter(startDate, 'day')) {
        setOnChangeEndDate(true);
        setOnChangeStartDate(false);
        setEndDate(date);
        return_date = date;
      } else {
        setOnChangeStartDate(true);
        setStartDate(date);
        setEndDate(null);
        depart_date = date;
        return_date = null;
      }
    }
  };

  return (
    <Dialog
      open={openedCalendarOverlaySignal.value}
      onClose={handleClose}
      fullWidth={true}
    >
      <DialogContent>
        <Stack direction="column" alignItems="center">
          <Typography
            sx={{
              fontSize: '30px',
              fontWeight: 700,
              paddingTop: '15px',
              fontFamily: 'Poppins',
              position: 'relative',
            }}
          >
            When ?
          </Typography>
          <Button
            style={{
              border: 'none',
              backgroundColor: 'white',
              position: 'absolute',
              left: 12,
              top: 17,
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleCalendarBackArrowClick();
            }}
          >
            <img
              src="icons/go-back-arrow.png"
              alt="back arrow"
              style={{
                width: '80%', // 10% of the viewport width
                height: 'auto', // Maintain aspect ratio
              }}
            />
          </Button>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              slots={{
                calendarHeader: CustomCalendarHeader,
                day: (dayProps) => (
                  <CustomPickersDay
                    {...dayProps}
                    startDate={startDate}
                    endDate={endDate}
                    disableMargin={true}
                  />
                ),
              }}
              minDate={today}
              disablePast
              referenceDate={depart_date ? depart_date : today}
              className={`${classes.calendar} ${classes.weekdayLabel}`}
              onChange={handleDateChange}
            />
          </LocalizationProvider>

          {/* if user click next without selecting the end date(return date) */}
          {!endDate && isNextButtonClicked && (
            <Typography
              sx={{
                color: '#fe7138',
                fontWeight: 600,
                fontSize: '11px',
                marginTop: '-15px',
              }}
            >
              Warning: Please select more than one day
            </Typography>
          )}
          <Button
            style={{
              border: 'none',
              padding: '2% 10%',
              backgroundColor: '#fe7138',
              color: 'white',
              borderRadius: '15px',
              fontSize: '20px',
              fontWeight: 600,
              marginBottom: '14px',
              marginTop: '20px',
            }}
            // onTouchStart={(e) => {
            //   e.stopPropagation();
            //   setIsNextButtonClicked(true);
            //   handleCalendarNextButtonTouch(e);
            // }}
            onClick={(e) => {
              e.stopPropagation();
              setIsNextButtonClicked(true);
              handleCalendarNextButtonClick(e, true);
            }}
          >
            Next
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
