import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Checkbox,
  InputAdornment,
  FormControl,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import useStyles from '../styles';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Logo from '../../Logo';
import { useAuth } from '~/managers/AuthContext';
import { AuthResponse } from '@supabase/supabase-js';
import { isWebView } from '../getIsWebView';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import OtpModal from './OtpModal';
import { InitialFormValuesSubmission } from '~/types/signup';

/**
 * Component for user signup.
 * @component
 * @returns {JSX.Element} Signup Component
 */
function SignupForm({
  open,
  onClose,
  isSignupPage = false,
}: {
  open: boolean;
  onClose: () => void;
  isSignupPage?: boolean;
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setErrorMessage] = useState('');
  const [isLowercase, setIsLowercase] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [isDigit, setIsDigit] = useState(false);
  const [isMinimum8Character, setIsMinimum8Character] = useState(false);
  const [isSpecialCharacter, setIsSpecialCharacter] = useState(false);
  const [isPasswordInput, setIsPasswordInput] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isFullNameValid, setIsFullNameValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);

  const isMobile = useIsMobile();

  /**
   * Handles name input change.
   * @param {React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>} event - The change event.
   * @function
   */
  const handleNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFullName(event.target.value);
  };

  const { signUp, signInWithGoogle } = useAuth();

  /**
   * Handles email input change.
   * @param {React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>} event - The change event.
   * @function
   */
  const handleEmailChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setEmail(event.target.value);
  };

  /**
   * Handles confirm password input change.
   * @param {React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>} event - The change event.
   * @function
   */
  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setConfirmPassword(event.target.value);
  };

  const handleSignInClick = () => {
    navigate('/login');
  };

  const handleSignUpWithGoogle = () => {
    signInWithGoogle()
      .then((result) => {
        console.log(result.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    validateEmail(email);
    validateFullName(fullName);
    validatePassword(password);
    validateConfirmPassword(confirmPassword);
  }, [email, fullName, password, confirmPassword]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsEmailValid(emailRegex.test(email));
  };

  const validateFullName = (name: string) => {
    setIsFullNameValid(name.length > 3);
  };

  const validateConfirmPassword = (confirmPassword: string) => {
    setIsConfirmPasswordValid(confirmPassword === password);
  };

  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

    setIsPasswordInput(password.length > 0);
    setIsMinimum8Character(password.length >= 8);
    setIsLowercase(/[a-z]/.test(password));
    setIsUppercase(/[A-Z]/.test(password));
    setIsDigit(/\d/.test(password));
    setIsSpecialCharacter(/[!@#$%^&*()_+]/.test(password));

    setIsPasswordValid(passwordRegex.test(password));
  };

  /**
   * Handles password input change.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   * @function
   */
  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    // Regular expression for a strong password with at least 8 characters,
    // including at least one uppercase letter, one lowercase letter,
    // one digit, and one special character
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

    // Get the new password entered by the user
    const newPassword = event.target.value;

    // Check if the password input field is not empty
    setIsPasswordInput(newPassword.length > 0);

    // Check if the new password meets the minimum length requirement of 8 characters
    setIsMinimum8Character(newPassword.length >= 8);

    // Check if the new password meets each condition of the password regex
    setIsLowercase(/[a-z]/.test(newPassword));
    setIsUppercase(/[A-Z]/.test(newPassword));
    setIsDigit(/\d/.test(newPassword));
    setIsSpecialCharacter(/[!@#$%^&*()_+]/.test(newPassword));

    // Check if the new password matches the regex for a strong password
    const isValid = passwordRegex.test(newPassword);

    // If the new password is valid, update the state with the new password value
    if (isValid) {
      setPassword(event.target.value);
    }
  };

  /**
   * @function
   * Handles user signup.
   */
  const handleSignUp = () => {
    // Regular expression for a valid email address
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if the entered email matches the email regex
    if (!emailRegex.test(email)) {
      setErrorMessage('Invalid email address');
      openSnackbar();
      return;
    }

    // Regular expression for a strong password with at least 8 characters and one uppercase letter
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[A-Z]).{8,}$/;

    // Check if the password meets the requirements of a strong password
    const isStrongPassword = passwordRegex.test(password);

    // If the password is not strong enough, display an error message
    if (!isStrongPassword) {
      setLoading(false);
      setErrorMessage('Password is weak.');
      openSnackbar();
      return;
    }

    setLoading(true);

    // Check if all password criteria are met: lowercase, uppercase, digit, special character, and minimum 8 characters
    if (
      isLowercase &&
      isDigit &&
      isSpecialCharacter &&
      isMinimum8Character &&
      isUppercase
    ) {
      // Check if the password and confirm password fields match
      if (password === confirmPassword) {
        onSubmit({ email, fullName, password });
      } else {
        setLoading(false);
        setErrorMessage('Passwords do not match');
        openSnackbar();
      }
    } else {
      setLoading(false);
      setErrorMessage('Passwords is not strong enough');
      openSnackbar();
    }
  };

  const onSubmit = async (data: InitialFormValuesSubmission) => {
    // Sign up the user with the provided credentials
    signUp(data)
      .then((result: AuthResponse) => {
        console.log({ result });
        // If sign up is successful and no error,  open the otp modal
        if (result.data && result.error === null) {
          console.log({ result });

          setLoading(false);
          setOtpOpen(true);
        } else if (result.error) {
          setLoading(false);
          if (result.error.message === 'Email rate limit exceeded') {
            setErrorMessage('User already exists');
          } else {
            setErrorMessage(result.error.message);
          }
          openSnackbar();
        }
      })
      .catch((error: { message: string }) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  /**
   * @function
   * Opens the snackbar.
   */
  const openSnackbar = () => {
    setSnackbarOpen(true);
  };
  /**
   * @function
   * Closes the snackbar.
   */
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const props = {
    email,
    confirmPassword,
    open: otpOpen,
    close: () => setOtpOpen((prev) => !prev),
    onParentClose: onClose,
    isSignupPage,
  };
  return (
    <Dialog open={open} onClose={onClose} fullScreen sx={{ bgcolor: '#fff' }}>
      <DialogContent
        sx={{
          padding: 0,
          bgcolor: '#fff',
        }}
      >
        <OtpModal {...props} />
        <>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={closeSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity="warning">{error}.</Alert>
          </Snackbar>
          <Grid container className={classes.outergrid}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={5}
              xl={5}
              className={classes.leftGrid}
            >
              <div className={classes.outerdiv}>
                {!isSignupPage && (
                  <Box
                    onClick={onClose}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 12H21M10 19L3 12L10 19ZM3 12L10 5L3 12Z"
                        stroke="#1F5460"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                )}
                <Box className={classes.logoContainer}>
                  <Logo />
                </Box>
                <FormControl>
                  <Box>
                    <Typography className={classes.statement}>
                      Sign up to your account
                    </Typography>
                    {(!isWebView() || !isMobile) && (
                      <>
                        <Button
                          className={classes.google}
                          variant="contained"
                          color="primary"
                          onClick={handleSignUpWithGoogle}
                        >
                          {/* Use the Google logo as the icon */}
                          <img
                            src="/icons/google.svg"
                            style={{ margin: 10 }}
                            alt="Map 1"
                          />
                          Continue with Google
                        </Button>
                        <Typography className={classes.or}>OR</Typography>
                      </>
                    )}

                    <Typography className={classes.name}>Full Name</Typography>
                    <Box className={classes.textFieldBox}>
                      <TextField
                        autoComplete="off"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          style: {
                            fontFamily: 'Futura Bk BT', // Specify your desired font family for the label
                            fontSize: '16px', // Specify your desired font size for the label
                          },
                        }}
                        className={classes.textField}
                        onChange={handleNameChange}
                      />
                    </Box>
                    <Typography className={classes.email}>Email</Typography>
                    <Box className={classes.textFieldBox}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineIcon />
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          style: {
                            fontFamily: 'Futura Bk BT', // Specify your desired font family for the label
                            fontSize: '16px', // Specify your desired font size for the label
                          },
                        }}
                        type="email"
                        required
                        className={classes.textField}
                        onChange={handleEmailChange}
                      />
                    </Box>
                    <Typography className={classes.password}>
                      Password
                    </Typography>
                    <Box className={classes.textFieldBox}>
                      <TextField
                        autoComplete="new-password"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="password"
                        required
                        className={classes.textField}
                        onChange={handlePasswordChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault(); // Prevent the default form submission
                            handleSignUp(); // Call the signIn function
                          }
                        }}
                      />
                    </Box>
                    {isPasswordInput && (
                      <Box className={classes.passwordChecklist}>
                        <Box className={classes.checklistItem}>
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={isLowercase}
                            className={
                              isLowercase ? classes.check : classes.uncheck
                            }
                            disableRipple
                          />
                          <Typography className={classes.checklistItemText}>
                            One lowercase letter
                          </Typography>
                        </Box>
                        <Box className={classes.checklistItem}>
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={isUppercase}
                            className={
                              isUppercase ? classes.check : classes.uncheck
                            }
                            disableRipple
                          />
                          <Typography className={classes.checklistItemText}>
                            One uppercase letter
                          </Typography>
                        </Box>
                        <Box className={classes.checklistItem}>
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={isDigit}
                            className={
                              isDigit ? classes.check : classes.uncheck
                            }
                            disableRipple
                          />
                          <Typography className={classes.checklistItemText}>
                            One digit
                          </Typography>
                        </Box>
                        <Box className={classes.checklistItem}>
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={isSpecialCharacter}
                            className={
                              isSpecialCharacter
                                ? classes.check
                                : classes.uncheck
                            }
                            disableRipple
                          />
                          <Typography className={classes.checklistItemText}>
                            One special character
                          </Typography>
                        </Box>

                        <Box className={classes.checklistItem}>
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={isMinimum8Character}
                            className={
                              isMinimum8Character
                                ? classes.check
                                : classes.uncheck
                            }
                            disableRipple
                          />
                          <Typography className={classes.checklistItemText}>
                            Minimum 8 characters
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    <Typography className={classes.password}>
                      Confirm Password
                    </Typography>
                    <Box className={classes.textFieldBox}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="password"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                        className={classes.textField}
                        onChange={handleConfirmPasswordChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault(); // Prevent the default form submission
                            handleSignUp(); // Call the signIn function
                          }
                        }}
                      />
                    </Box>

                    <Box className={classes.buttonBox}>
                      <LoadingButton
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        color="primary"
                        className={classes.signup}
                        type="submit"
                        onClick={handleSignUp}
                        disabled={
                          !isEmailValid ||
                          !isFullNameValid ||
                          !isPasswordValid ||
                          !isConfirmPasswordValid
                        }
                      >
                        Sign Up
                      </LoadingButton>

                      <Box className={classes.boxBottom}>
                        <Typography className={classes.login}>
                          Already have an account?
                          <Button
                            className={classes.loginButton}
                            variant="text"
                            color="primary"
                            onClick={handleSignInClick}
                          >
                            Sign In
                          </Button>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </FormControl>
              </div>
            </Grid>
            <Grid item lg={7} xl={7}>
              {/* <Mapstatic /> */}
              <div className={classes.imageDiv}>
                <img
                  className={classes.mapImg}
                  src="/icons/mapImageLogin.png"
                  alt="Map"
                />
              </div>
            </Grid>
          </Grid>
        </>
      </DialogContent>
    </Dialog>
  );
}

export default SignupForm;
