import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { OTPType } from '~/types/signup';
import OtpInput from '../keypad/OtpInput';
import useStyles from './styles';
import { useAuth } from '~/managers/AuthContext';
import { supabase } from '~/supabase/supabaseClient';
import { useNavigate } from 'react-router-dom';
import ROUTES from '~/routes';

export default function OtpModal({
  open,
  close,
  email,
  isSignupPage,
  onParentClose,
}: {
  open: boolean;
  close: () => void;
  email: string;
  onParentClose: () => void;
  isSignupPage: boolean;
}) {
  const [otpError, setOtpError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();

  const { verifyOtp } = useAuth();

  const classes = useStyles();

  const handleResend = () => {
    setLoading(true);
    supabase.auth
      .resend({ email, type: 'signup' })
      .then(() => {
        console.log('Verification email has been sent again');
        setSuccessMessage('Verification email has been sent again');
      })
      .catch((error) => {
        setOtpError('Error resending verification email');
        console.error('Error resending verification email:', error);
      })
      .finally(() => setLoading(false));
  };
  const { control: otpControl } = useForm<OTPType>({
    defaultValues: {
      otp: Array(6).fill('') as string[],
    },
  });

  const onOtpSubmit = async (otp: string) => {
    setOtpError('');
    setSuccessMessage('');

    if (otp.length !== 6) {
      setOtpError('Please enter a 6-digit code.');
      return;
    }

    setLoading(true);

    try {
      const result = await verifyOtp({
        email,
        otp,
      });
      console.log({ result });
      if (result?.error) {
        setOtpError(result?.error?.message);
        return;
      }

      if (isSignupPage) {
        navigate(ROUTES.HOME.path.toLowerCase());
      } else {
        close();
        onParentClose();
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (otpError) {
      const timer = setTimeout(() => {
        setOtpError(''); // Clears otpError after 5 seconds
      }, 5000);

      // Clean up the timer if the component unmounts or otpError changes
      return () => clearTimeout(timer);
    }
  }, [otpError, setOtpError]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <Dialog open={open} onClose={close} fullScreen>
      <DialogContent
        sx={{
          padding: '.7rem',
          paddingTop: '1.4rem',
          bgcolor: '#ffffff',
        }}
      >
        <Box
          onClick={!loading ? close : undefined} // Prevent clicks when loading
          sx={{
            cursor: loading ? 'not-allowed' : 'pointer', // Change cursor style when loading
            pointerEvents: loading ? 'none' : 'auto', // Disable pointer events when loading
            opacity: loading ? 0.6 : 1, // Dim the box to indicate loading
          }}
        >
          <svg
            width="24"
            height="28"
            viewBox="0 0 24 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.6232 4.21184C16.9869 4.62748 16.9447 5.25924 16.5291 5.62293L9.38918 11.8703L16.5291 18.1178C16.9447 18.4815 16.9869 19.1132 16.6232 19.5289C16.2595 19.9445 15.6277 19.9866 15.2121 19.6229L7.64213 12.9992C6.95914 12.4016 6.95914 11.3391 7.64213 10.7415L15.2121 4.11777C15.6277 3.75409 16.2595 3.79621 16.6232 4.21184Z"
              fill="#191B23"
            />
          </svg>
        </Box>

        <Typography
          variant="h5"
          sx={{
            color: '#1F5460',
            lineHeight: '36px',
            fontWeight: 500,
            fontSize: 24,
            textAlign: 'center',
          }}
        >
          We sent you a code
        </Typography>
        <Typography
          sx={{
            marginTop: '3px',
            marginBottom: '4px',
            fontSize: 16,
            fontWeight: 400,
            color: '#1F5460',
            lineHeight: '24px',
            textAlign: 'center',
          }}
        >
          Enter it to verify {email}
        </Typography>
        <Box
          sx={{
            marginTop: '1rem',
          }}
        >
          <OtpInput
            control={otpControl}
            boxLength={6}
            submit={onOtpSubmit}
            loading={loading}
          />
        </Box>

        {/* Conditional Success Message */}
        {successMessage && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#e6f7e6', // Light green for success background
              borderRadius: '8px',
              padding: '0.5rem 1rem',
              color: '#388e3c', // Dark green for the text
              textAlign: 'center',
              fontSize: 14,
              maxWidth: '300px',
              margin: '2rem auto',
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: '0.5rem', color: '#388e3c' }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zM10 15.6L6.8 12.4 8.2 11l1.8 1.8L15.8 7.2l1.4 1.4L10 15.6z"
                fill="#388e3c"
              />
            </svg>

            <Typography
              sx={{
                color: 'inherit',
                fontSize: '14px',
              }}
            >
              {successMessage}
            </Typography>
          </Box>
        )}

        {/* Conditional Error Message */}
        {otpError && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#ffe6e6',
              borderRadius: '8px',
              padding: '0.5rem 1rem',
              color: '#d32f2f', // Darker red for the text
              textAlign: 'center',
              fontSize: 14,
              maxWidth: '300px', // Limits the width
              margin: '2rem auto', // Centers the box horizontally
            }}
          >
            {/* Error Icon */}
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: '0.5rem', color: '#d32f2f' }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2c-.87 0-1.64.44-2.08 1.14l-9 15c-.92 1.5.08 3.36 1.9 3.36h18c1.82 0 2.82-1.86 1.9-3.36l-9-15A2.501 2.501 0 0 0 12 2zm0 5c.55 0 1 .45 1 1v5c0 .55-.45 1-1 1s-1-.45-1-1V8c0-.55.45-1 1-1zm0 10c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z"
                fill="#d32f2f"
              />
            </svg>

            {/* Error Text */}
            <Typography
              sx={{
                color: 'inherit', // Uses the color set in Box
                fontSize: '14px',
              }}
            >
              {otpError}
            </Typography>
          </Box>
        )}

        <Box
          className={classes.boxBottom}
          sx={{
            marginTop: '6rem !important',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography className={classes.resend}>
            Didn’t receive mail?
            <Button
              className={classes.resendButton}
              variant="text"
              color="primary"
              onClick={handleResend}
              disabled={loading}
            >
              <span className={classes.resendButtonText}>Resend</span>
            </Button>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
