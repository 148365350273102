import React, { useState, useEffect, useRef } from 'react';
import HotelCard from './HotelCard';
import HotelDrawer from './hotelDrawer';
import { Swiper as SwiperType } from 'swiper/types';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css/autoplay'; // Import autoplay styles
import { RootState, useSelector } from '~/redux/reducers';
import dayjs, { Dayjs } from 'dayjs';
import { mapSignal } from '~/map/ViewTravel';
import { Map, Marker } from 'maplibre-gl';
import { useDispatch } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import { useMarkerContext } from '~/context/HotelMarkerContext';
import { MapTapButton } from '../MobileFooterButtons/MapTapButton';

type Hotel = {
  HotelName: string;
  ReviewScore: number;
  HotelPriceInclusive: number;
  HotelPricePerNight: number;
  DistanceToEvent: number;
  ImgSrc: string;
  Longitude: number;
  Latitude: number;
  HotelID: number;
  CheckInStart: string;
  noOfNights: number;

  hotelDesc: string;
  ImgSrc_popup: string;
  hotelBookingURL: string;
};

const HotelSwiper = () => {
  const swiperRef = useRef<SwiperType>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [spaceBetween, setSpaceBetween] = useState(-45);
  const [index, setIndex] = useState(0);
  const [hotels, setHotels] = useState<Hotel[]>([]);
  const [isLoading, setisLoading] = useState(false);
  const [fetchDataTriggered, setFetchDataTriggered] = useState(false);
  const dispatch = useDispatch();
  const { markers, addMarker } = useMarkerContext();
  const { clearMarkers } = useMarkerContext();

  const coordinateLatitude = useSelector(
    (state: RootState) => state.CalendarOverlayReducers.coordinateLatitude,
  );

  const coordinateLongitude = useSelector(
    (state: RootState) => state.CalendarOverlayReducers.coordinateLongitude,
  );

  const isMapTapped = useSelector(
    (state: RootState) => state.CalendarOverlayReducers.isMapTapped,
  );

  const departureDate = useSelector(
    (state: RootState) => state.CalendarOverlayReducers.departureDate,
  );

  const returnDate = useSelector(
    (state: RootState) => state.CalendarOverlayReducers.returnDate,
  );

  const locationCoordinates = [coordinateLongitude, coordinateLatitude];
  let noOfNights = dayjs(returnDate).diff(dayjs(departureDate), 'day');

  //Marker for map tap pin
  const createCustomMarker = () => {
    const tapPin = './icons/tapPin.png';

    const customIcon = `
    <div style=" position: relative; display: flex; justify-content: space-between; align-items: center; background-color: white; padding: 0px; border-radius: 15px; gap: 4px;" >
      <img style="height: 15px; width: 15px; border-radius: 100px;" src='${tapPin}' />
    </div>
        `;

    const customMarker = document.createElement('div');
    customMarker.style.zIndex = '9999';
    customMarker.innerHTML = customIcon;

    const anchor = 'bottom';
    const marker = new Marker({
      element: customMarker,
      anchor: anchor,
    });

    if (coordinateLatitude !== null && coordinateLongitude !== null) {
      marker
        .setLngLat([coordinateLongitude, coordinateLatitude])
        .addTo(mapSignal.value as Map);
      addMarker(marker);
    }
  };

  //hook to place map tap pin on different taps on map
  useEffect(() => {
    if (mapSignal.value) {
      clearMarkers();
      createCustomMarker();
    }
  }, [isMapTapped]);

  //API to get top 5 hotels
  useEffect(() => {
    async function getHotelsFromAPI() {
      const requestData = {
        location: locationCoordinates,
        currency_code: 'USD',
        language_code: 'en-us',
        depart_date: departureDate,
        return_date: returnDate,
      };

      try {
        const response = await fetch(
          // 'http://127.0.0.1:54321/functions/v1/search-nearby-hotels',
          'https://gtnxtwzrmigzmvwhsnjp.supabase.co/functions/v1/search-nearby-hotels',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imd0bnh0d3pybWlnem12d2hzbmpwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTU2Njk1NzYsImV4cCI6MjAxMTI0NTU3Nn0.e_6N9qlMn33OzsT-LfrHBTfUn-n-HGPwHDjqS1geRE4`,
            },
            body: JSON.stringify(requestData),
            mode: 'cors',
          },
        );

        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }

        //setting hotels and median price
        const data = await response.json();
        console.log(data.hotelsDataFromAPI);
        const medianPriceCorrected = Math.trunc(data.medianPrice);
        dispatch(ActionsCreator.setMedianPrice(medianPriceCorrected));
        setHotels(data.top5Hotels || []);

        //checking for empty response
        if (data.top5Hotels.length === 0) {
          dispatch(ActionsCreator.setIsAPIResponseEmpty(-1));
          dispatch(ActionsCreator.setIsMapTapped(false));
        } else {
          dispatch(ActionsCreator.setIsAPIResponseEmpty(1));
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    }

    //calling function to fetch data from API
    if (isMapTapped) {
      setisLoading(true);
      dispatch(ActionsCreator.setIsAPIResponseEmpty(-1));
      getHotelsFromAPI().then(() => setisLoading(false));
    }
  }, [isMapTapped]);

  //Markers for hotels on map
  useEffect(() => {
    if (hotels.length > 0 && mapSignal.value) {
      hotels.map((hotel, ind) => {
        const hotelPinCard = hotel.ImgSrc;
        const hotelPin = './icons/hotelPin.png';

        const customIcon = `
        <div style="display: flex; flex-direction: column; align-items: center;" >
        
        <div style=" position: relative; display: flex; justify-content: space-between; align-items: center; background-color: white;  padding: 8px; border-radius: 15px; gap: 4px;" >
        <img style="height: 43px; width: 43px; border-radius: 5px;" src='${hotelPinCard}' />

        <div style=" position: relative; display: flex; flex-direction: column; justify-content: space-between;  gap: 8px;">
            <span style="
             display: inline-block;
             font-size: 13px; 
             font-weight: 500; 
             border-radius: 12px; 
             line-height: 15px;
             max-width: 15ch; 
             text-align: center;   
             text-overflow: ellipsis;
             overflow: hidden;
             white-space: nowrap;      
             font-family: 'poppins';">
             ${hotel.HotelName}
             </span>

             <span style="
             display: inline-block;
             font-size: 13px; 
             font-weight: 500; 
             border-radius: 12px; 
             line-height: 15px;
             max-width: 15ch;   
             text-overflow: ellipsis;
             overflow: hidden;
             white-space: nowrap;      
             font-family: 'poppins';">
             ${Math.round(hotel.HotelPricePerNight)}$/night
             </span>
        </div>
          </div>

          <div>
          <img style="height: 60px; width: 15px;" src='${hotelPin}'/>
          </div>
        </div>
          `;

        // Create a custom marker using an HTML element (e.g., your SVG icon)
        const customMarker = document.createElement('div');
        customMarker.addEventListener('click', () => setIndex(ind));
        customMarker.style.zIndex = ind === index ? '100' : '10';
        customMarker.innerHTML = customIcon;

        const anchor = 'bottom';
        const marker: Marker = new Marker({
          element: customMarker,
          anchor: anchor,
        })
          .setLngLat([hotel.Longitude, hotel.Latitude])
          .addTo(mapSignal.value as Map);

        addMarker(marker);
      });

      // Fly Map to First Hotel Location
      mapSignal.value.flyTo({
        zoom: 11.5,
        center: [coordinateLongitude!, coordinateLatitude!],
      });
    }
  }, [hotels]);

  //focusing hotel based on hotel currently centered in swiper slide
  useEffect(() => {
    if (hotels.length > 0) {
      mapSignal.value!.flyTo({
        zoom: 15,
        center: [hotels[index].Longitude, hotels[index].Latitude],
      });

      if (swiperRef.current) {
        swiperRef.current.slideToLoop(index); // Swiper's loop mode requires slideToLoop
      }
    }
  }, [index]);

  function handleDrawer(e: React.SyntheticEvent) {
    setDrawerOpen(!drawerOpen);
  }

  //handling index of current hotel focused in swiper
  const handleTransitionEnd = (swiper: SwiperClass) => {
    setIndex(swiper.realIndex);
  };

  //logic to handle space between slides based on scrren width
  useEffect(() => {
    const handleWindowResize = () => {
      const width = window.innerWidth;
      if (width <= 320) {
        setSpaceBetween(-35);
      } else if (width < 400) {
        setSpaceBetween(-42);
      } else if (width >= 400) {
        setSpaceBetween(-48);
      } else if (width >= 500) {
        setSpaceBetween(-60);
      }
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <>
      {isLoading && (
        <div>
          <MapTapButton text="Searching..." />
        </div>
      )}
      {hotels.length && (
        <>
          <Swiper
            className="hotel-cards-swiper"
            onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
            onSlideChange={(swiper: SwiperClass) => {
              handleTransitionEnd(swiper);
            }}
            slidesPerView={1}
            spaceBetween={spaceBetween}
            longSwipesRatio={0.5}
            centeredSlides={true}
            loop={hotels.length >= 4}
          >
            {hotels.map((hotel, index) => (
              <SwiperSlide className="hotel-cards-swiper-slide" key={index}>
                <HotelCard
                  hotelName={hotel.HotelName}
                  rating={hotel.ReviewScore}
                  imgSrc={hotel.ImgSrc}
                  pricePerNight={hotel.HotelPricePerNight}
                  distanceToEvent={hotel.DistanceToEvent}
                  hotelDescription={hotel.hotelDesc}
                  noOfNights={noOfNights}
                  HotelPriceInclusive={hotel.HotelPriceInclusive}
                  handleDrawer={handleDrawer}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <HotelDrawer
            open={drawerOpen}
            onClose={handleDrawer}
            hotelName={hotels[index]?.HotelName}
            imgSrc={hotels[index]?.ImgSrc_popup}
            rating={hotels[index]?.ReviewScore}
            hotelPriceInclusive={hotels[index]?.HotelPriceInclusive}
            hotelPricePerNight={hotels[index]?.HotelPricePerNight}
            checkInTime={hotels[index]?.CheckInStart}
            hotelBookingURL={hotels[index]?.hotelBookingURL}
            noOfNights={noOfNights}
            hotelDescription={hotels[index]?.hotelDesc}
          />
        </>
      )}
    </>
  );
};

export default HotelSwiper;
