import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import useStyles from './styles';
import { supabase } from '~/supabase/supabaseClient';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';

/**
 * Component for email verification.
 * Displays a message to the user prompting them to verify their email.
 * @component
 * @returns {JSX.Element} The rendered emailVerification component.
 */
const Verify = () => {
  const classes = useStyles();

  // Get the verification email from the Redux state
  const email: string | undefined = useSelector(
    (state: RootState) => state.MapReducers.verificationEmailState,
  );

  /**
   * Handles resending the verification email.
   * Uses Supabase auth to resend the email to the user.
   */
  const handleResendEmail = () => {
    if (email) {
      supabase.auth
        .resend({ email, type: 'signup' })
        .then(() => {
          console.log('Verification email has been sent again');
        })
        .catch((error) => {
          console.error('Error resending verification email:', error);
        });
    }
  };

  return (
    <Box className={classes.mainBox}>
      <Typography className={classes.verify}>
        Please verify your email
      </Typography>
      <Typography className={classes.line2}>
        You're almost there! We sent an email to
      </Typography>
      <Typography className={classes.email}>{email}</Typography>
      <Box className={classes.p1Box}>
        <Typography className={classes.p1}>
          Just click on the link in that email to complete your signup. If you
          don't see it, you may need to check your spam folder.
        </Typography>
      </Box>
      <Typography className={classes.find}>
        Still can't find the email? No problem
      </Typography>
      <Button
        className={classes.resendButton}
        color="primary"
        variant="contained"
        onClick={handleResendEmail}
      >
        Resend Verification Email
      </Button>
    </Box>
  );
};

export default Verify;
