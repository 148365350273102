import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActionsCreator from '~/redux/actions';
import { RootState } from '~/redux/reducers';
import { getTravelHistoryWithOffset } from '~/supabase/travelHistoryTracking';
import { TravelFormData, TravelFormHistoryData } from '~/utility/models';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import HistoryItem from './HistoryItem';
import { Skeleton, Typography } from '@mui/material';
import ViewOrEditModal from './ViewOrEditModal';
import { AnimatePresence } from 'framer-motion';
import { supabase } from '~/supabase/supabaseClient';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';

const aspectRatio = 269 / 214;

const History = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [travelHistory, setTravelHistory] = useState<TravelFormHistoryData[]>(
    [],
  );
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>();
  const [travelId, setTravelId] = useState('');

  const userID: string = useSelector(
    (state: RootState) => state.MapReducers.userID,
  );
  const travelHistoryState: TravelFormHistoryData[] = useSelector(
    (state: RootState) => state.MapReducers.travelHistoryState,
  );

  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const getPublishedId = async (tourID: number) => {
    const { data } = await supabase
      .from('Publish Travel')
      .select('id')
      .eq('published_id', tourID);

    return data;
  };

  /**
   * This useEffect is called when a component is rendered to fetch Travel History from DB
   *
   * @param {string} userID - The ID of the user for whom to fetch travel history.
   * @param {function} dispatch - The Redux dispatch function to update global state.
   */
  useEffect(() => {
    // Fetch Travel History Data from DB

    if (travelHistoryState.length === 0) {
      getTravelHistoryWithOffset(userID, 0).then((result) => {
        if (result.error) {
          // Handle the error if needed
          console.error('Error adding to travel history:', result.error);
        } else {
          // Store Travel History in Local State in this component
          setTravelHistory(result.data as TravelFormHistoryData[]);

          // Disable Loader on after Data fetched from DB
          setLoading(false);

          // Store Travel History in Redux as Global State
          dispatch(
            ActionsCreator.saveTravelToHistory(
              result.data as TravelFormHistoryData[],
            ),
          );
        }
      });
    } else {
      setTravelHistory(travelHistoryState);
      setLoading(false);
    }
  }, [dispatch, userID]);

  const handleOpenTravel = (
    travelHistoryItem: TravelFormHistoryData,
    index: number,
  ) => {
    // Extract travelPoints to show on Map (StaticTravelVisualizer)
    const travelPoints = travelHistoryItem.travelPoints.map(
      (travelPoint: TravelFormData) => {
        return {
          arrival: {
            ...travelPoint.arrival,
            dateTime: travelPoint.arrival.dateTime,
          },
          departure: {
            ...travelPoint.departure,
            dateTime: travelPoint.departure.dateTime,
          },
          selectedTransport: travelPoint.selectedTransport,
          encodedPath: travelPoint.encodedPath,
          selectedTransportImages: travelPoint?.selectedTransportImages || [],
        };
      },
    );

    // Set State with Travel Points to show on Static Travel Visualizer
    dispatch(ActionsCreator.addTravelPoint(travelPoints));
    dispatch(ActionsCreator.setTravelHistoryIndex(index));
    dispatch(ActionsCreator.setTravelHistoryCurrentID(travelHistoryItem.id));
    dispatch(ActionsCreator.setPublishedTravelLink(''));
    dispatch(ActionsCreator.setTravelFormSaveState(true));

    ActionsCreator.getPulishedTravelId(travelHistoryItem.id);

    navigate('/homepage');
  };

  return (
    <div style={{ paddingBottom: 'min(22px,5vw)' }}>
      <AnimatePresence>
        {selectedItemIndex !== undefined && travelId && (
          <ViewOrEditModal
            item={travelHistory[selectedItemIndex]}
            index={selectedItemIndex}
            onClose={() => {
              setSelectedItemIndex(undefined);
            }}
            travelId={travelId}
            editTravel={() =>
              handleOpenTravel(
                travelHistory[selectedItemIndex],
                selectedItemIndex,
              )
            }
          />
        )}
      </AnimatePresence>
      <Swiper
        slidesPerView="auto"
        spaceBetween={isMobile ? '15%' : '3%'}
        style={{ width: '100vw', paddingLeft: 20 }}
      >
        {loading ? (
          new Array(6).fill(1).map((i, idx) => (
            <SwiperSlide
              key={idx.toString()}
              style={{
                width: window.innerHeight * 0.2 * aspectRatio,
                cursor: 'pointer',
              }}
            >
              <div>
                <Skeleton
                  variant="rounded"
                  style={{ aspectRatio }}
                  height={'20vh'}
                />
                <Skeleton
                  variant="rounded"
                  width={window.innerHeight * 0.2 * aspectRatio}
                  className="home-history-item-title"
                />
                <Skeleton
                  variant="text"
                  width={window.innerHeight * 0.2 * aspectRatio}
                  className="home-history-item-description"
                />
                <Skeleton
                  variant="text"
                  width={window.innerHeight * 0.2 * aspectRatio}
                  className="home-history-item-description"
                />
              </div>
            </SwiperSlide>
          ))
        ) : travelHistory.length > 0 ? (
          travelHistory.map((i, idx) => (
            <SwiperSlide
              key={idx.toString()}
              style={{ width: window.innerHeight * 0.2 * aspectRatio }}
            >
              <HistoryItem
                item={i}
                onClick={async () => {
                  const res = await getPublishedId(i.id);
                  if (res?.[0]?.id) {
                    setTravelId(res?.[0].id);
                    setSelectedItemIndex(idx);
                  } else {
                    setTravelId('');
                    handleOpenTravel(i, idx);
                  }
                }}
              />
            </SwiperSlide>
          ))
        ) : (
          <Typography
            variant="body1"
            style={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              textAlign: isMobile ? 'left' : 'center',
              padding: '0 0 0 25px',
            }}
          >
            You don't have any trips yet.
            <br />
            Generate one now.
          </Typography>
        )}
      </Swiper>
    </div>
  );
};

export default History;
