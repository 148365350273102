import React from 'react';
import './hotelCardStyles.css';

interface hotelCardInterface {
  hotelName: string;
  rating: number;
  imgSrc: string;
  pricePerNight: number;
  distanceToEvent: number;
  hotelDescription: string;
  handleDrawer: (e: React.SyntheticEvent) => void;
  noOfNights: number;
  HotelPriceInclusive: number;
}

const HotelCard: React.FC<hotelCardInterface> = ({
  hotelName,
  rating,
  imgSrc,
  pricePerNight,
  distanceToEvent,
  hotelDescription,
  handleDrawer,
  noOfNights,
  HotelPriceInclusive,
}) => {
  return (
    <div className="hotel-container" onClick={handleDrawer}>
      <div className="hotel-top-container">
        <div className="hotel-left-container">
          <img className="hotel-image" src={imgSrc} alt="hotel" height={75} />

          <div className="hotel-title-container">
            <p className="hotel-distance">
              {Math.round(distanceToEvent * 10) / 10}km from center
            </p>
            <h3 className="hotel-name">{hotelName}</h3>
          </div>
        </div>

        <div className="hotel-right-container">
          <div className="hotel-rating-container">
            <span className="hotel-rating">{rating}</span>
            <span
              className="hotel-rating-symbol"
              style={{
                fontSize: '18px',
                marginTop: '-3.5px',
                marginLeft: '4px',
              }}
            >
              ⭐
            </span>
          </div>
          <p className="hotel-price">
            ~US$
            {Math.round(pricePerNight)}
            <br /> per night
          </p>
        </div>
      </div>

      <p className="hotel-description">{hotelDescription}</p>

      <p className="hotel-button">
        <p className="hotel-button-text">{`USD$ ${Math.round(HotelPriceInclusive * 10) / 10
          } for ${noOfNights} Nights - View Deal`}</p>
      </p>
    </div>
  );
};

export default HotelCard;
