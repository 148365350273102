import { makeStyles } from '@mui/styles';
import type MuiTheme from '~/styles/theme/MuiTheme';

export const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  login: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Futura Bk BT',
    color: '#1F5460',
    // marginTop: '1rem',
  },
  loginButton: {
    textTransform: 'none',
    // display: 'flex',
    // justifyContent: 'center',
    color: '#1F5460',
    fontFamily: 'Futura Bk BT',
    position: 'relative',
  },

  loginButtonText: {
    textDecoration: 'underline',
    textDecorationColor: '#FE7138',
    textUnderlineOffset: '3px',
  },
}));
