import MapActions from "~redux/actions/MapActions";
import TravelActions from "~redux/actions/TravelActions";
import AnimationActions from "~redux/actions/AnimationActions";
import TripInfoActions from "~redux/actions/TripInfoActions";
import { SET_INITIAL_STATE } from "../actionTypes";
import { store } from "../store";
import HotelSwiperActions from "./HotelSwiperActions";
import CalendarOverlayActions from "./CalendarOverlayActions";

const ActionsCreator = {
  ...MapActions,
  ...TravelActions,
  ...AnimationActions,
  ...TripInfoActions,
  ...HotelSwiperActions,
  ...CalendarOverlayActions,

  // Define the root action creator for resetting the state
  resetStateToInitial: () =>
    store.dispatch({
      type: SET_INITIAL_STATE,
    }),
};

export default ActionsCreator;
