import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Box, Button, LinearProgress } from '@mui/material';
import { dataReadySignal } from '~/components/signals/itinerary/dataReadySignal';
import { IProcessedPlace } from '~/types/websocket';
import { addNewLine, linesSignal, previewMapSignal, removeLastLine } from '.';
import maplibregl from 'maplibre-gl';
import { LngLatLike } from 'maplibre-gl';
import dayjs from 'dayjs';
import DetailCardP2PAI from './DetailCard';

import { signal } from '@preact/signals-core';
import { addPauseSignal } from '~/components/signals/itinerary/pauseSignal';
import DayReady from './DayReadyp2p';
import DayPaused from './DayPausedp2p';
import { dayCounterSignal, lastGeneratePlaceSignal, showWSError } from '../index';
import {
  generationStateSignal,
} from '../index';
import { getCustomIcon } from '~/animationEngine/StaticTravelVisualizer/getCustomIcon';
import { showStopButton, stopLoadingSignal } from './MapHeader';

import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import { useSignals } from '@preact/signals-react/runtime';
import { messagesSignal } from '~/components/signals/itinerary/messagesSignal';
import ActionsCreator from '~/redux/actions';
import moment from 'moment';

import './style.css';
import { refreshAllPreviousMarkersSignal } from '~/components/Home/PreviousGenerationDialog';

export const markersSignal = signal<
  { id: string; marker: maplibregl.Marker }[]
>([]);
export const isFirstScreenSignal = signal<boolean>(true);

// markersSignal.subscribe((markers) => {
//   const lastMarker = markers[markers.length - 2];

//   if (lastMarker) {
//     const thismarker = markers[markers.length - 1]
//     const lastmarkerlnglat = lastMarker.marker.getLngLat();
//     const thismarkerlnglat = thismarker.marker.getLngLat();
//     addNewLine([lastmarkerlnglat.lng, lastmarkerlnglat.lat], [thismarkerlnglat.lng, thismarkerlnglat.lat])
//   }
// })

export const commonButtonsSx = {
  marginTop: 2,
  background:
    'radial-gradient(100% 100% at 0% 0%, #FF9345 0.01%, #FE7138 50%, #F25D21 100%) ',
  padding: '8px 16px',
  borderRadius: '18px',
  fontSize: 'min(18px,5vw)',
  fontWeight: 700,
  textTransform: 'none',
  boxShadow:
    '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
  height: 'min(64px, 18vw)',
  color: 'white',
};

let count = 0;

export const bottomSheetp2pStateSignal = signal<
  'provoke-loading' | 'loading' | 'doyoulike' | 'dayready' | 'paused'
>('loading');



const BottomSheetp2p = ({
  handleYes,
  handleNo,
  generateNextDay,
  handleCancel,
  messages,
  handleResume,
  createVisualization,
  deleteDayX,
}: {
  handleYes: (transportationIndex: number) => void;
  handleNo: () => void;
  generateNextDay: (str?: string) => void;
  handleCancel: () => void;
  messages: IProcessedPlace[];
  handleResume: () => void;
  createVisualization: () => void;
  deleteDayX: (reason: string) => void;
}) => {
  useSignals();
  const map = previewMapSignal.peek();
  const [state, setState] = useState<
    'provoke-loading' | 'loading' | 'doyoulike' | 'dayready' | 'paused'
  >('loading');
  const isMobile = useIsMobile();
  const [historySplice, setHistorySplice] = useState(0);
  const [isStopLoading, setIsStopLoading] = useState(false);

  useEffect(() => {
    const unsub = refreshAllPreviousMarkersSignal.subscribe((val) => {
      const map = previewMapSignal.peek();
      console.log('refreshing markers messages', messages.length, val, map)
      if (val && map) {
        refreshAllPreviousMarkersSignal.value = 0
        messages?.forEach((message, index) => {
          console.log('refreshing markers', message)
          const marker = getMapMarker(
            lastGeneratePlaceSignal.peek() ? index + 1 : index,
            message.place?.location?.label || '',
            message.place?.category || '',
            true,
            false,
            message
          )
            .setLngLat(
              message?.place?.location?.coordinates as LngLatLike,
            )
            .addTo(map);

          const id = message?.place?.location?.value + '-' + message?.transportationIndex;
          if (id) {
            const existingMarker = markersSignal
              .peek()
              .find((item) => item.id === id);
            if (existingMarker) {
              existingMarker.marker.remove();
              markersSignal.value = markersSignal
                .peek()
                .filter((item) => item.id !== id);
            }

            markersSignal.value = [...markersSignal.peek(), { id, marker }];
          } else {
            marker.remove();
          }
        })
      }
    });
    return () => {
      unsub();
    };
  }, [messages])

  const handleGoBack = () => {
    const lastPlaceGenerated = messages[messages.length - 1];
    if (lastPlaceGenerated) {
      bottomSheetp2pStateSignal.value = 'doyoulike';
      setHistorySplice(1);
    }
  }
  const index = messages.length - 1

  useEffect(() => {
    const unsub = bottomSheetp2pStateSignal.subscribe((value) => {
      setState(value);
    });
    const stopLoadingUnsub = stopLoadingSignal.subscribe((value) => {
      setIsStopLoading(value);
    })
    return () => {
      unsub();
      stopLoadingUnsub();
    };
  }, []);

  const paddingTop = state === 'doyoulike' ? '8px' : '30px';
  const paddingBottom = ['dayready', 'paused', 'doyoulike'].includes(state)
    ? '20px'
    : '6px';

  useEffect(() => {
    console.log('messages' + count++, messages);
    if (messages[index]?.place && historySplice === 0) {
      // handlePause(messages[index].transportationIndex, messages[index].placeIndex);

      if (messages[index]?.place) {
        // resetAutoYesProgress()
        if (index > (lastGeneratePlaceSignal.peek() ? -1 : 0)) {
          showStopButton();
        }
        if (bottomSheetp2pStateSignal.peek() !== 'dayready') {

          bottomSheetp2pStateSignal.value =
            bottomSheetp2pStateSignal.peek() == 'provoke-loading'
              ? 'loading'
              : 'doyoulike';
        }
      }
      if (messages?.[index]?.place?.location?.coordinates) {
        map?.flyTo({
          center: messages?.[index]?.place?.location?.coordinates as LngLatLike,
          zoom: 13,
          padding: { top: 10, bottom: 150, left: 10, right: 10 },
        });

        if (index > 0) {
          const thisCoords = messages?.[index]?.place?.location?.coordinates;
          const lastCoords =
            messages?.[index - 1]?.place?.location?.coordinates;
          if (thisCoords && lastCoords) {
            addNewLine(
              lastCoords as [number, number],
              thisCoords as [number, number],
            );
          }
        } else if (lastGeneratePlaceSignal.peek()) {
          const thisCoords =
            messages?.[messages.length - 1]?.place?.location?.coordinates;
          const lastCoords =
            lastGeneratePlaceSignal.peek()?.place?.location?.coordinates;
          if (thisCoords && lastCoords) {
            addNewLine(
              lastCoords as [number, number],
              thisCoords as [number, number],
            );
          }
        }

        if (messages?.[index]?.place?.location?.coordinates && map) {

          const marker = getMapMarker(
            lastGeneratePlaceSignal.peek() ? index + 1 : index,
            messages?.[index]?.place?.location?.label || '',
            messages?.[index]?.place?.category || '',
            true,
            false,
            messages?.[index]
          )
            .setLngLat(
              messages?.[index]?.place?.location?.coordinates as LngLatLike,
            )
            .addTo(map);

          const id = messages?.[index]?.place?.location?.value + '-' + messages?.[index]?.transportationIndex;
          if (id) {
            const existingMarker = markersSignal
              .peek()
              .find((item) => item.id === id);
            if (existingMarker) {
              existingMarker.marker.remove();
              markersSignal.value = markersSignal
                .peek()
                .filter((item) => item.id !== id);
            }

            markersSignal.value = [...markersSignal.peek(), { id, marker }];
          } else {
            marker.remove();
          }
        }
      }
    }
  }, [messages, historySplice]);

  useEffect(() => {
    const unsub = dataReadySignal.subscribe((data) => {
      if (data) {
        bottomSheetp2pStateSignal.value = 'dayready';
      }
    });
    const pauseSignalunsub = addPauseSignal.subscribe((value) => {
      if (value) {
        bottomSheetp2pStateSignal.value = 'paused';
      }
    });

    return () => {
      unsub();
      pauseSignalunsub();
    };
  }, []);

  useEffect(() => {
    console.log({ messagesSignal: messagesSignal.value });
    const messages = messagesSignal.value;
    if (messages && messages.length > 0) {
      setState('dayready');
      bottomSheetp2pStateSignal.value = 'dayready';
    }
  }, []);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{ y: 1000 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          overflow: 'visible',
          zIndex: 1000,
          ...(!isMobile && { width: '40%' }),
          ...(!isMobile && { margin: '0 auto' }),
        }}
      >
        {state === 'doyoulike' && (
          <DetailCardP2PAI data={messages[index]} index={index} />
        )}
        <div
          style={{
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
            paddingBottom: paddingBottom,
            paddingTop: paddingTop,
            ...(state !== 'doyoulike' && {
              backgroundColor: 'white',
              boxShadow: '0px 0px 10px 0px #00000040',
            })
          }}
        >
          {state === 'dayready' && (
            <DayReady
              handleResume={() => {
                bottomSheetp2pStateSignal.value = 'loading';
                handleResume();
              }}
              handleCancel={handleCancel}
              createVisualization={createVisualization}
              generateNextDay={(str?: string) => {
                generateNextDay(str);
                bottomSheetp2pStateSignal.value = 'loading';
                dataReadySignal.value = false;
                addPauseSignal.value = false;
                ActionsCreator.setIsP2PButtonVisible(false);
              }}
              deleteDayX={deleteDayX}
              messages={messages}
            />
          )}

          {state === 'paused' && (
            <DayPaused
              handleResume={() => {
                bottomSheetp2pStateSignal.value = 'loading';
                handleResume();
              }}
              handleMarkAsCompleted={() => {
                setState('dayready');
                dataReadySignal.value = true;
              }}
              deleteDayX={deleteDayX}
              handleCancel={handleCancel}
              messages={messages}
            />
          )}

          {(state === 'loading' || state === 'provoke-loading') && (
            <motion.div
              className="text-center"
              initial={{ opacity: 0, y: 200 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.25,
                type: 'spring',
                stiffness: 260,
                damping: 20,
              }}
              exit={{ opacity: 0, y: 200 }}
              key="loading"
            >
              <Check20SecondsProgress />

              <motion.h3 className="my-3 px-2">
                {lastGeneratePlaceSignal.peek() ? (
                  <Fragment>
                    {index > -1 ? (
                      <div
                        style={{ fontSize: 'min(18px, 6.5vw)', fontWeight: 700 }}
                        className="d-flex flex-column align-items-center">
                        <b>Generating Point #{index + 3} ...</b>
                        {/* <Button sx={{ ...commonButtonsSx, background: 'white', color: '#FE7138', marginTop: '20px' }}
                          onClick={handleGoBack}>Go Back</Button> */}
                      </div>
                    ) : (
                      <b
                        style={{ fontSize: 'min(18px, 6.5vw)', fontWeight: 700 }}
                      >
                        Generating Day {dayCounterSignal.peek()}
                      </b>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {index > -1 ? (
                      <div
                        style={{ fontSize: 'min(18px, 6.5vw)', fontWeight: 700 }}
                        className="d-flex flex-column align-items-center">
                        <b>Generating Point #{index + 2} ...</b>
                      </div>
                    ) : (
                      <b
                        style={{ fontSize: 'min(18px, 6.5vw)', fontWeight: 700 }}
                      >
                        Generating {generationStateSignal.peek()?.startArea} Trip...
                      </b>
                    )}
                  </Fragment>
                )}
              </motion.h3>
            </motion.div>
          )}
          {state === 'doyoulike' && (
            <motion.div
              className="text-center"
              initial={{ opacity: 0, y: 200 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.25,
                type: 'spring',
                stiffness: 260,
                damping: 20,
              }}
              exit={{ opacity: 0, y: 200 }}
              key="doyoulike"
            >
              <motion.div
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  duration: 0.25,
                  delay: 0.25,
                  type: 'spring',
                  stiffness: 260,
                  damping: 20,
                }}
                className="d-flex justify-content-center gap-3"
              >
                {!isStopLoading && <Fragment>
                  <Button
                    onClick={() => {
                      resetAutoYesProgress();
                      pauseAutoYesProgress();
                      handleNo();
                    }}
                    style={{
                      borderRadius: '18px',
                      padding: '8px 16px',
                      fontSize: 'min(12px, 3vw)',
                      fontWeight: 700,
                      textTransform: 'none',
                      backgroundColor: 'white',
                      color: '#FE7138',
                      height: 'min(64px, 18vw)',
                      width: 'min(70px, 20vw)',
                      boxShadow:
                        '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
                    }}
                  >
                    No
                  </Button>

                  <AutoProgressButton
                    onClick={() => {
                      if (historySplice === 1) {
                        resetAutoYesProgress();
                        bottomSheetp2pStateSignal.value = 'provoke-loading';
                        setHistorySplice(0)
                        return
                      }

                      bottomSheetp2pStateSignal.value = 'loading';
                      handleYes(
                        messages[messages.length - 1]?.transportationIndex,
                      );
                    }}
                  />
                </Fragment>
                }
              </motion.div>
            </motion.div>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

const Check20SecondsProgress = () => {


  useEffect(() => {
    const timer = setInterval(() => {
      showWSError('timeout');
    }, 20000);
    return () => clearInterval(timer);
  }, []);

  return <LinearProgress sx={{ borderRadius: '50px', height: '6px' }} />;
};

export const autoProgressSignal = signal({
  value: 0,
  paused: false,
  disabled: false,
});

export const resetAutoYesProgress = () => {
  autoProgressSignal.value = { value: 0, paused: false, disabled: false };
};

export const pauseAutoYesProgress = () => {
  autoProgressSignal.value = {
    value: autoProgressSignal.peek().value,
    paused: true,
    disabled: false,
  };
};

export const disableAutoYesProgress = () => {
  autoProgressSignal.value = { value: 0, paused: true, disabled: true };
};

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);
  const [paused, setPaused] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    resetAutoYesProgress();
    const unsub = autoProgressSignal.subscribe((data) => {
      setProgress(data.value);
      setPaused(data.paused);
      setDisabled(data.disabled);
    });
    const eventsub = document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        pauseAutoYesProgress();
      } else {
        resetAutoYesProgress();
      }
    });
    return () => {
      unsub();
      document.removeEventListener('visibilitychange', eventsub as any);
    };
  }, []);

  // useEffect(() => {
  //   if (progress < 100) {
  //     const timer = setInterval(() => {
  //       if (!paused) {
  //         setProgress((prev) => {
  //           const newValue = Math.min(prev + 1, 100);
  //           return newValue;
  //         });
  //       } else {
  //         clearInterval(timer);
  //       }
  //     }, 300);
  //     return () => clearInterval(timer);
  //   }
  // }, [progress, paused]);

  return (
    <div className={`meter ${disabled ? 'disabled' : ''}`}>
      {/* <span style={{ width: `${progress}%` }}></span> */}
    </div>
  );
};

const AutoProgressButton = ({ onClick }: { onClick: () => void }) => {
  const [progress, setProgress] = useState(0);
  const [paused, setPaused] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    resetAutoYesProgress();
    const unsub = autoProgressSignal.subscribe((data) => {
      setProgress(data.value);
      setPaused(data.paused);
      setDisabled(data.disabled);
    });
    const eventsub = document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        pauseAutoYesProgress();
      } else {
        resetAutoYesProgress();
      }
    });
    return () => {
      unsub();
      document.removeEventListener('visibilitychange', eventsub as any);
    };
  }, []);

  // useEffect(() => {
  //   if (progress < 100) {
  //     const timer = setInterval(() => {
  //       if (!paused) {
  //         setProgress((prev) => Math.min(prev + 1, 100));
  //       } else {
  //         clearInterval(timer);
  //       }
  //     }, 300);
  //     return () => clearInterval(timer);
  //   } else {
  //     handleClick();
  //   }
  // }, [progress, paused]);

  const handleClick = () => {
    setProgress(0);
    onClick();
  };

  return (
    <Box position="relative" display="inline-block">
      <Button
        variant="contained"
        onClick={handleClick}
        disabled={disabled}
        style={{
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '8px',
          padding: '8px 16px',
          fontSize: 'min(12px, 3vw)',
          fontWeight: 700,
          height: 'min(64px, 18vw)',
          width: 'min(144px, 40vw)',
          backgroundColor: '#FE7138', // initial button color
          color: 'white',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: `${progress}%`, // dynamic width for progress
            backgroundColor: '#FF9C6B', // fill color with opacity
            transition: 'width 0.1s linear', // smooth transition
            zIndex: 0,
          }}
        />
        <span style={{ zIndex: 1 }}>
          <svg
            width="25"
            height="22"
            viewBox="0 0 25 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.125 0.5C16.8227 0.5 15.6287 0.909035 14.5762 1.71577C13.5673 2.48919 12.8955 3.47429 12.5 4.19061C12.1045 3.47424 11.4327 2.48919 10.4238 1.71577C9.37133 0.909035 8.17733 0.5 6.875 0.5C3.24069 0.5 0.5 3.44637 0.5 7.35353C0.5 11.5746 3.9192 14.4626 9.09542 18.8347C9.97442 19.5772 10.9707 20.4187 12.0063 21.3163C12.1428 21.4348 12.3181 21.5 12.5 21.5C12.6819 21.5 12.8572 21.4348 12.9937 21.3163C14.0293 20.4186 15.0256 19.5771 15.9051 18.8342C21.0808 14.4626 24.5 11.5746 24.5 7.35353C24.5 3.44637 21.7593 0.5 18.125 0.5Z"
              fill="white"
            />
          </svg>
          <b className="ms-1">Yes</b>
        </span>
      </Button>
    </Box>
  );
};

export default BottomSheetp2p;

export function getMapMarker(
  index: number,
  label: string,
  category: string,
  isMarkerNameVisible: boolean = true,
  shouldRenderDefaultIcon: boolean = false,
  markerDetails: any,
) {
  let markerType = 'landmark'; // Default markerType
  if (category === '✈️ Airport') {
    markerType = 'airport';
  } else if (category === '🏨 Hotel') {
    markerType = 'hotel';
  } else if (category === '🍽️ Restaurant') {
    markerType = 'restaurant';
  } else if (category === '🏠 Home') {
    markerType = 'home';
  } else if (category === '🎡 Point of Attraction') {
    markerType = 'attraction';
  } else if (category === 'Other') {
    markerType = 'attraction';
  }

  const iconToRender = shouldRenderDefaultIcon
    ? `./icons/${markerType}Marker-new.png`
    : getCustomIcon({ index: index + 1, category });

  const customIcon = `
    <div style="display: flex; flex-direction: column; align-items: center; position: relative">
    <img style="height: 43px; width: 38px" src='${iconToRender}'></img>
      ${isMarkerNameVisible
      ? `<span style="
          background-color: white; 
          font-size: 12px; 
          font-weight: 600; 
          height: 19px;
          padding: 2px 4px; 
          border-radius: 12px; 
          line-height: 15px;
          max-width: 150px; 
          text-align: center;   
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          position: absolute;
          bottom: -22px;
          font-family: 'poppins';
    ">${label.length > 20 ? `${label.slice(0, 20)}...` : label}</span>`
      : ''
    }
      </div>
    `;

  // Create a custom marker using an HTML element (e.g., your SVG icon)
  const customMarker = document.createElement('div');
  customMarker.addEventListener('click', () => {
    ActionsCreator.setCurrentOverviewData({
      ...markerDetails.place,
      dayIndex: markerDetails.generatedPlace.dayIndex,
    });

    pauseAutoYesProgress();
  });
  customMarker.innerHTML = customIcon;
  // customMarker.classList.add('custom-popup');

  const anchor = 'bottom'; // Replace with actual values

  const marker = new maplibregl.Marker({
    element: customMarker,
    anchor: anchor,
  });

  return marker;
}
