import React, { useEffect, useRef, useState } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import { IFrameItem } from './IframeItem/IframeItem';
import { InfoFooter } from './InfoFooter/InfoFooter';
import './TiktokItem.css';
import { DetailsItem } from './DetailsItem/DetailsItem';

export const TiktokItem = ({
  content,
  handleClose,
  goNext,
  goPrev,
}: {
  content?: any;
  handleClose?: () => void;
  goNext?: () => void;
  goPrev?: () => void;
  currentStoryIndex?: number;
}) => {
  const [isMuted, setIsMuted] = useState(true);
  const [isMutedVisible, setIsMutedVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);

  const tiktokUrlArr = content?.media[0].url.split('/') || [];
  const videoId = tiktokUrlArr[tiktokUrlArr.length - 1];

  const isMobile = useIsMobile();

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const animatedY = useMotionValue(0);
  const opacity = useTransform(animatedY, [-100, 0, 100], [0, 1, 0]);
  const rotate = useTransform(animatedY, [-100, 100], [4, -4]);

  const handleDragEnd = () => {
    if (animatedY.get() > 30) {
      setIsMuted(true);
      goPrev?.();
    } else if (animatedY.get() < -30) {
      setIsMuted(true);
      goNext?.();
    }
  };

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.type === 'onPlayerReady') {
        iframeRef.current!.contentWindow?.postMessage(
          { type: 'play', 'x-tiktok-player': true },
          '*',
        );
        setIsMutedVisible(true);
        setIsPlaying(true);
      }

      // Hide for now as we want loop video
      // const currentTime = event.data?.value?.currentTime ?? null;
      // const duration = event.data?.value?.duration ?? null;

      // if (!!currentTime && !!duration && currentTime === duration) {
      //   setIsMuted(true);
      //   goNext?.();
      // }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const handleShowDetails = () => {
    iframeRef.current!.contentWindow?.postMessage(
      { type: 'pause', 'x-tiktok-player': true },
      '*',
    );
    setIsDetailsVisible(true);
    setIsPlaying(false);
  };

  const handleHideDetails = () => {
    iframeRef.current!.contentWindow?.postMessage(
      { type: 'play', 'x-tiktok-player': true },
      '*',
    );
    setIsDetailsVisible(false);
    setIsPlaying(true);
  };

  const topLinkSrc = content.media[0].url.split('/').slice(0, 4).join('/');
  const [dynamicHeight, setDynamicHeight] = useState('210px');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 700) {
        setDynamicHeight('210px');
      } else {
        setDynamicHeight('260px');
      }
    };
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!content) {
    return null;
  }

  return (
    <motion.div
      drag="y"
      dragConstraints={{
        top: 0,
        bottom: 0,
      }}
      style={{
        y: animatedY,
        opacity,
        rotate,
        position: 'relative',
      }}
      className="tiktok_iframe_container"
      onDragEnd={handleDragEnd}
    >
      {!isMobile && (
        <a
          style={{
            width: '100px',
            height: '100px',
            background: 'white',
            position: 'absolute',
            zIndex: 99999999,
            top: 0,
            right: 0,
            opacity: 0,
          }}
          target="_blank"
          rel="noreferrer"
          href={topLinkSrc}
        ></a>
      )}
      <a
        style={{
          width: isMobile ? '100%' : '300px',
          height: isMobile ? '50px' : '100px',
          background: 'white',
          position: 'absolute',
          zIndex: 99999999,
          top: 0,
          left: 0,
          opacity: 0,
        }}
        target="_blank"
        rel="noreferrer"
        href={topLinkSrc}
      ></a>
      <a
        style={{
          width: isMobile ? '50px' : '100px',
          background: 'white',
          height: dynamicHeight,
          position: 'absolute',
          zIndex: 99999999,
          bottom: 0,
          right: 0,
          opacity: 0,
        }}
        target="_blank"
        rel="noreferrer"
        href={content.media[0].url}
      ></a>
      {content && handleClose && (
        <div
          style={{
            position: 'absolute',
            left: '3%',
            top: '120px',
            color: '#fff',
            zIndex: 9999999,
            cursor: 'pointer',
          }}
        >
          {' '}
          <ArrowBackIosNewIcon
            fontSize="large"
            onClick={() => handleClose?.()}
          />
        </div>
      )}

      {isMutedVisible && (
        <div
          style={{
            position: 'absolute',
            top: '120px',
            right: isMobile ? '10%' : '40%',
            zIndex: 99999999,
            color: '#fff',
          }}
          onClick={() => {
            setIsMuted((prev) => !prev);
            iframeRef.current!.contentWindow?.postMessage(
              {
                type: isMuted ? 'unMute' : 'mute',
                'x-tiktok-player': true,
              },
              '*',
            );
          }}
        >
          {isMuted ? (
            <VolumeOffIcon fontSize="large" />
          ) : (
            <VolumeMuteIcon fontSize="large" />
          )}
        </div>
      )}
      {isMutedVisible && (
        <div
          style={{
            position: 'absolute',
            top: '120px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 99999999,
            color: '#fff',
          }}
          onClick={() => {
            setIsPlaying((prev) => !prev);
            iframeRef.current!.contentWindow?.postMessage(
              { type: isPlaying ? 'pause' : 'play', 'x-tiktok-player': true },
              '*',
            );
          }}
        >
          {isPlaying ? (
            <PauseCircleIcon fontSize="large" />
          ) : (
            <PlayCircleIcon fontSize="large" />
          )}
        </div>
      )}

      <IFrameItem videoId={videoId} ref={iframeRef} />
      {/* <div
        className={`info-footer-hide-show-wrapper ${!isMobile && 'desktop'}`}
        style={{}}
        onClick={() => {
          setIsInfoFooterVisible(!isInfoFooterVisible);
        }}
      >
        <span className="custom-badge info-footer-hide-show">
          {isInfoFooterVisible ? 'Hide' : 'Show'}
        </span>
      </div> */}
      {/* {isInfoFooterVisible && ( */}
      <InfoFooter content={content} showDetails={handleShowDetails} />
      {/* )} */}
      {isDetailsVisible && (
        <DetailsItem content={content} close={handleHideDetails} />
      )}
    </motion.div>
  );
};
