import { Box, Button } from '@mui/material';
import useStyles from './styles';
import { Ref, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import YouTube, { YouTubeEvent } from 'react-youtube';
import { mobileSize } from '../constants';

interface HomeSectionProps {
  homeRef: Ref<HTMLElement>;
  mainContainerRef: React.MutableRefObject<HTMLDivElement>;
}

/**
 * HomeSection component renders the 'Home' section of the webpage.
 * It dynamically adjusts layout based on the screen size and includes a YouTube video.
 *
 * @param {HomeSectionProps} props - The props for the component, including refs for the section and main container.
 * @returns {JSX.Element} The HomeSection component
 */
const HomeSection = ({ homeRef, mainContainerRef }: HomeSectionProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileSize);
  const videoRef = useRef<HTMLDivElement | null>(null);
  const [player, setPlayer] = useState<any | null>(null);

  /**
   * Updates the isMobile state based on the current window width.
   */
  const handleResize = () => {
    setIsMobile(window.innerWidth <= mobileSize);
  };

  useEffect(() => {
    // Add a listener for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
   * Sets the YouTube player when the video is ready.
   *
   * @param {object} e - The event object from YouTube player.
   */
  const handleVideoReady = (e: YouTubeEvent) => {
    setPlayer(e.target);
  };

  /**
   * Scrolls to the video section and plays the video when the "Watch a Demo" button is clicked.
   */
  const handleClickWatchDemo = () => {
    const videoElement = videoRef.current;
    const mainContainerElement = mainContainerRef.current;

    if (videoElement && mainContainerElement) {
      mainContainerElement.scrollTo({
        top: videoElement.offsetTop - 90, // Adjust 'yourNavbarHeight' accordingly
        behavior: 'smooth',
      });

      if (player) {
        player.playVideo(); // Ensure player.current is correctly initialized
      }
    }
  };

  useEffect(() => {
    return () => {
      videoRef.current = null;
    };
  }, []);

  return (
    <div className={classes.sectionWrapper}>
      <section id="home" ref={homeRef} className={classes.section}>
        <img
          className={classes.planePathImage}
          src={`${isMobile
            ? '/ThreePlanesCurvePathMobile.svg'
            : '/ThreePlanesCurvePath.svg'
            }`}
          alt="Curling Arrow"
        />
        <div className={classes.title}>
          Any <span style={{ color: '#FE7138' }}>Travel Itinerary,</span>
          <br />
          Brought to <span style={{ color: '#FE7138' }}>Life</span>
        </div>
        <p className={classes.text}>
          Travel Agents, Travel Planners, Travelers... Elevate any Travel Plan
          in 2 min
        </p>
        <Box className={classes.buttonContainer}>
          <Button
            className={classes.homeButton}
            style={{
              backgroundColor: '#FE7138',
              borderColor: '#FE7138',
              zIndex: 1000,
            }}
            onClick={() => navigate('/home')}
          >
            Get Started
          </Button>
          <Button
            className={classes.homeButton}
            style={{
              backgroundColor: 'white',
              color: '#0E131F',
              zIndex: 1000,
            }}
            onClick={handleClickWatchDemo}
          >
            Watch a Demo
          </Button>
          <img
            src="/arrow.svg"
            alt="Curling Arrow"
            className={classes.arrowIcon}
          />
        </Box>
        <img className={classes.dotMapImage} src="./dotMap.svg" alt="Dot Map" />
        <img
          className={classes.circleRing}
          src="./circleRingHome.svg"
          alt="Circle Rings"
        />
        <div className={classes.imageContainer} ref={videoRef}>
          <YouTube
            videoId="i2txsMJ2jts"
            title="Demo Video of Vizual Travel"
            className={classes.videoIframe}
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                autoplay: 0,
              },
            }}
            onReady={handleVideoReady}
          />
        </div>
      </section>
    </div>
  );
};

export default HomeSection;
