import React, { forwardRef, LegacyRef } from 'react';

export const IFrameItem = forwardRef(
  ({ videoId }: { videoId: string }, ref: LegacyRef<HTMLIFrameElement>) => {
    return (
      <iframe
        ref={ref}
        src={`https://www.tiktok.com/player/v1/${videoId}?controls=0&autoplay=1&native_context_menu=0&rel=0&description=0&closed_caption=0&loop=1`}
        className="tiktok_iframe"
        allow="encrypted-media; autoplay"
        title="tiktok"
      ></iframe>
    );
  },
);
