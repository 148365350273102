import { makeStyles } from "@mui/styles";
import MuiTheme from "~/styles/theme/MuiTheme";

export const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  calendarHeaderRoot: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 0px",
    alignItems: "center",
  },

  calendar: {
    "& .MuiPickersDay-root": {
      flex: '1 1 auto', //TODO
      color: "#000",
      width: 40,
      height: 40,
      margin: '-1px',
      padding: "5px",
      justifyContent: "space-evenly", 
    },
    "& .MuiIconButton-root": {
      padding: "0px",
    },
    "& .MuiPickersDay-root:hover": {
      backgroundColor: '#d0d0d0', /* Lighter shade for hover */
    },

    "& .MuiDayCalendar-header": {
       display: 'flex',
      justifyContent: 'flex-start', /* Align items to the start */
      gap: '0px', /* Ensure no gaps between day elements */
      margin: '0px',
    },
    "& .MuiDayCalendar-weekContainer": { //TODO
       display: 'flex',
      justifyContent: 'flex-start', /* Align items to the start */
      gap: '0px', /* Ensure no gaps between day elements */
      margin: '0px',
    },
    "& .MuiPickersDay-root.Mui-disabled": {
      color: "#6B7791",
    },
    "& .MuiPickersDay-today": {
      outline: "none",
      border: "none",
    },
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px rgba(255, 255,255, 0.8)",
    width: "100%",
  },
  currentMonthText: {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: "700",
  },
  day: {
    fontFamily: "Poppins",
    fontSize: 19,
    fontWeight: "400",
  },
  highlightedDay: {
    backgroundColor: "#FE7138 !important",
    color: "#fff !important",
    borderRadius: "0%",
  },
  singleDay: {
    borderRadius: "40%",
  },
  firstDay: {
    borderTopLeftRadius: "40%",
    borderBottomLeftRadius: "40%",
  },
  lastDay: {
    borderTopRightRadius: "40%",
    borderBottomRightRadius: "40%",
  },
  weekdayLabel: {
    "& .MuiDayCalendar-weekDayLabel": {
      flex: '1 1 auto',
      justifyContent: 'space-evenly',
      color: "#6B7791", // Set the color for the week day labels
      fontSize: "0.9rem",
    },
  },
  selectionIcon: {
    "& .MuiSvgIcon-root": {
      width: "1.5em",
      height: "1.5em",
      padding: '0px',
    },
    
    color: "#fe7138",
  },

  
  // Breakpoints for smaller screens
  [theme.breakpoints.down("xs")]: {
    
      currentMonthText: {
        color: "#000",
        fontFamily: "Poppins",
        fontSize: 18,
        fontWeight: "700",
      },
    
  },
}));