import { Button, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { dataReadySignal } from '~/components/signals/itinerary/dataReadySignal';
import { addPauseSignal } from '~/components/signals/itinerary/pauseSignal';
import { IProcessedPlace } from '~/types/websocket';
import {
  bottomSheetp2pStateSignal,
  commonButtonsSx,
  disableAutoYesProgress,
  pauseAutoYesProgress,
  resetAutoYesProgress,
} from './BottomSheetp2p';
import { signal } from '@preact/signals-core';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import OverviewMenu from '~/components/OverviewMenu';
import Navbar from '~/components/NavbarNew';
import ActionsCreator from '~/redux/actions';
import { useSelector } from 'react-redux';
import { selectIsP2POverviewBtnVisible } from '~/redux/selectors/tripInfoSelectors';

export const showStopButtonSignal = signal<number>(0);

export const showStopButton = () => {
  showStopButtonSignal.value = showStopButtonSignal.peek() + 1;
};

export const stopLoadingSignal = signal<boolean>(false);

const MapHeader = ({
  messages,
  label,
  onStop,
  handleResume
}: {
  messages: IProcessedPlace[];
  label: string;
  onStop: () => void;
  handleResume: () => void;
}) => {
  const [stopVisible, setStopVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDayReady, setIsDayReady] = useState(false);
  const isOverviewBtnVisible = useSelector(selectIsP2POverviewBtnVisible);
  const isMobile = useIsMobile();

  useEffect(() => {
    const unsub = dataReadySignal.subscribe((data) => {
      if (data) {
        setStopVisible(false);
        setLoading(false);
        ActionsCreator.setIsP2PButtonVisible(true);
        setIsDayReady(true);
      }
    });
    const pauseSignalunsub = addPauseSignal.subscribe((value) => {
      if (value) {
        setStopVisible(false);
        setLoading(false);
        setIsDayReady(false);
        ActionsCreator.setIsP2PButtonVisible(true);
      }
    });
    const showStopButtonUnsub = showStopButtonSignal.subscribe((value) => {
      if (value) {
        setStopVisible(true);
        ActionsCreator.setIsP2PButtonVisible(false);
      } else {
        setStopVisible(false);
      }
    });

    return () => {
      showStopButtonUnsub();
      unsub();
      pauseSignalunsub();
    };
  }, []);

  useEffect(() => {
    stopLoadingSignal.value = loading;
  }, [loading]);

  return (
    <div
      className={`d-flex justify-content-between align-items-center `}
      style={{
        padding: '16px 16px',
        gap: '8px',
        ...(!isMobile && { width: '40%', margin: '0 auto' }),
      }}
    >
      <Navbar onlyBurger shoudShowMap />

      <div

        style={{
          boxShadow:
            '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
          whiteSpace: 'nowrap',
          padding: isOverviewBtnVisible ? '8px 30px' : '8px 22px',
          borderRadius: '18px',
          height: '64px',
          color: isOverviewBtnVisible ? '#000' : '#FE7138',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 'min(18px, 4vw)',
          fontWeight: 700,
          backgroundColor: 'white',
          cursor: isOverviewBtnVisible ? 'pointer' : 'default',
        }}
        onClick={() => {
          if (isOverviewBtnVisible) {
            ActionsCreator.setIsP2POverviewVisible(true);
          }
        }}
      >
        {isOverviewBtnVisible ? 'Overview' : label}
      </div>
      <div style={{ minWidth: '75px', maxWidth: '118px' }}>
        {stopVisible && messages.length > 0 && (
          <div
            onClick={() => {
              setLoading(true);
              onStop();
              disableAutoYesProgress();
            }}
            style={{
              boxShadow:
                '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
              backgroundColor: '#FF352E',
              padding: '8px 16px',
              color: 'white',
              borderRadius: '18px',
              width: '75px',
              height: '43px',
              fontSize: '18px',
              fontWeight: 700,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            {loading ? <CircularProgress size={20} color="inherit" /> : 'Stop'}
          </div>
        )}
        {isOverviewBtnVisible && !isDayReady && (
          <div
            onClick={() => {
              bottomSheetp2pStateSignal.value = 'loading';
              handleResume();
            }}
            className='custom-badge active'
            style={{
              maxWidth: '118px',
              fontSize: '16px',
              fontWeight: 900,
              cursor: 'pointer'
            }}
          >
            Continue
          </div>)}
      </div>
    </div>
  );
};

export default MapHeader;
